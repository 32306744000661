<template>
  <template v-if="allRoutes.includes(route.name)">
    <section class="team-body" style="background: #fff">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 col-sm-4 col-xs-12 mb-4">
            <UserSideBarRefLink
              :baseURL="baseURL"
              @logout-clicked="logOut"
            ></UserSideBarRefLink>
          </div>

          <router-view :baseURL="baseURL"></router-view>
        </div>
      </div>
    </section>
  </template>
  <template v-else>
    <router-view :baseURL="baseURL"></router-view>
  </template>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, watch } from "vue";
import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";
import { mapState } from "pinia";
import { useLoginStore } from "../stores/login";
//import { useRouter } from "vue-router";
import PasswordMeter from "vue-simple-password-meter";
import * as AllRules from "@vee-validate/rules";
import { useRoute, useRouter } from "vue-router";
import UserSideBarRefLink from "../components/UserSideBarRefLink";

import { localize } from "@vee-validate/i18n";
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});
const route = useRoute();

const allRoutes = [
  "DashboardUser",
  "DownlineGenealogyUser",
  "OrdersUser",
  "OrdersDetailsUser",
  "CommissionUser",
  "OrderHistoryUser",
  "TeamOrdersUser",
  "UserCustomersUser",
  "UserCustomerOrderListingUser",
  "UserScholarUser",
  "UserAmbassadorUser",
  "MessageListUser",
  "MessageListDetailsUser",
  "MessageComposeUser",
  "ProfileUser",
  "ManageAddressUser",
  "ReOrderUser",
];

//console.log("user info slugCms: ", slugCms);

console.log("user info route Name : ", route.name);

const router = useRouter();
const userLogin = useLoginStore();
console.log("userLogin : ", userLogin);
let loading = ref(0);
const password = ref("");
const email = ref("");
const props = defineProps(["baseURL"]);
const allCountries = ref([]);
const country = ref();
const state = ref();
const allStates = ref([]);
const phoneNumber = ref("");
const countryForm = ref("");
const allCountriesForm = ref([]);
const showPassword = ref(false);
const baseURL = ref(process.env.VUE_APP_APP_API_URL);
//console.log("slug router userinfo : ", route.to.params);
const getUserInfo = async () => {
  let slugCms = "";
  if (route.params.sluguser) {
    slugCms = route.params.sluguser;
  } else if (route.params.slug) {
    slugCms = route.params.slug;
  } else {
    //slugCms = route.params.slug;
  }

  let apiUrl = "";
  if (slugCms == "") {
    apiUrl = props.baseURL + "get-user-info";
  } else {
    apiUrl = props.baseURL + "get-user-info/" + slugCms;
  }

  await axios
    .get(apiUrl)
    //.get(apiUrl)
    .then((res) => {
      console.log("response get-user-info : ", res.data);
      if (slugCms == "") {
        userLogin.addUserUrlName(null);
      } else {
        userLogin.addUserUrlName(slugCms);
      }
      userLogin.addUserUrlInfoData(res.data);

      // if (res.data.page_details.length > 0) {
      //   pageData.value = res.data.page_details[0];
      // }

      //siteLanguage.value = res.data.languages
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {});
};

onMounted(() => {
  getUserInfo();
  //   if (!userLogin.checkIsLoggedIn) {
  //     router.push({ name: "Signin" });
  //   }
  //getCountry();
});
</script>

<!-- <script>
export default {
  mounted() {
    if (!localStorage.getItem('token')) {
      this.$router.push({name : 'Signin'});
    }
  }
}
</script> -->

<style>
.adminPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.adminPanel button {
  font-size: 30px;
  width: fit-content;
  margin: 55px 0;
}
</style>
