<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>My Back Office</li>
    </ul>
    <div class="single-deta-box" data-aos="fade-up">
      <h3 class="mb-3">
        Welcome back {{ userLogin.getUserUserData.firstname }}
        {{ userLogin.getUserUserData.lastname }}
      </h3>
      <h3 class="mb-3">
        {{
          userLogin.getUserUserData.user_type.charAt(0).toUpperCase() +
          userLogin.getUserUserData.user_type.slice(1)
        }}
      </h3>

      <p>
        This is where the company can update information, announcements, events,
        new products, meetings, etc.  Welcome to your Independent Sales
        Representative back office of Cymcorp. Your Independent Sales
        Representative ID is {{ userLogin.getUserUserData.id }} and your
        replicated marketing website is http://cymcorp.us/{{
          userLogin.getUserUserData.urlname
        }}.
      </p>
    </div>
    <div class="single-deta-box" data-aos="fade-up">
      <!-- <h3 class="mb-4">Display Notifications</h3>
      <div class="notification-heads mb-5">
        <h6>Dashboard Notifications</h6>
        <div class="right-notifications">
          <div>Notifications :</div>
          <div>Email Updates</div>
          <div>Sponsor Messages</div>
        </div>
      </div> -->
      <div class="notification-bodys">
        <!-- <div class="single-notification">
          <img src="/assets/images/notification-icon1.png" />
          <h5>PLACE ORDER</h5>
        </div> -->
        <!-- <div class="single-notification">
          <img src="/assets/images/notification-icon2.png" />
          <h5>MANAGE AUTOSHIP</h5>
        </div> -->
        <div class="single-notification">
          <img src="/assets/images/notification-icon3.png" />
          <h5>
            <router-link :to="{ name: 'DownlineGenealogy' }"
              >VIEW TEAM</router-link
            >
          </h5>
        </div>
        <div class="single-notification">
          <img src="/assets/images/notification-icon4.png" />
          <h5>
            <router-link :to="{ name: 'Commission' }">COMMISSIONS</router-link>
          </h5>
        </div>
        <!-- <div class="single-notification">
          <img src="/assets/images/notification-icon5.png" />
          <h5>EVENT CALENDAR</h5>
        </div> -->
      </div>
    </div>
    <!-- <div class="single-deta-box" data-aos="fade-up">
      <div class="commission-heads">
        <div class="row">
          <div class="col-md-6">
            <h3 class="mb-3">Commission Summary</h3>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <select class="field-input form-control" id="">
                <option value="">Monthly</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted } from "vue";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";

import AbmassadorSideBar from "../../components/AbmassadorSideBar";
import ScholarSideBar from "../../components/ScholarSideBar";
import CustomerSideBar from "../../components/CustomerSideBar";

const router = useRouter();
const userLanguages = userLanguage();
const userLogin = useLoginStore();
const logOut = () => {
  //console.log("Logout");
  userLogin.resetUserLoggedIn();
  router.push({ name: "Home" });
};

onMounted(() => {});
</script>
