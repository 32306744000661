<template>
  <ProductListBanner :translationText="translationText"></ProductListBanner>

  <section class="products-area">
    <div class="container-fluid">
      <!-- <ProductListFilter :products="productCount"></ProductListFilter> -->
      <div class="row">
        <ProductListBox
          :baseURL="baseURL"
          @updateCount="productGetCount"
          :translationText="translationText"
        ></ProductListBox>
      </div>
    </div>
  </section>

  <ProductListQuiz />
</template>

<script setup>
import axios from "axios";
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import ProductBox from "../../components/Product/ProductBox";
import ProductListBanner from "../../components/Product/ProductListBanner";
import ProductListBox from "../../components/Product/ProductListBox";
import ProductListQuiz from "../../components/Product/ProductListQuiz";
import ProductListFilter from "../../components/Product/ProductListFilter";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import { userLanguage } from "../../stores/language";

const userLogin = useLoginStore();
const cartData = useCartStore();
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const props = defineProps(["baseURL"]);

const translationTextEn = ref({
  text: {
    productBannerLabel: "Explore Our Natural Life Products",
    productBannerLabelSUbText:
      "Our products are developed by an experienced team at Cymcorp and backed by 26+ years of scientific and medical knowledge.",
    quickview: "QUICK VIEW",
  },

  language: "",
});

const translationText = ref({
  text: {
    productBannerLabel: "Explore Our Natural Life Products",
    productBannerLabelSUbText:
      "Our products are developed by an experienced team at Cymcorp and backed by 26+ years of scientific and medical knowledge.",
    quickview: "QUICK VIEW",
  },

  language: "",
});

const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if(userLanguages.getLanguageCode != 'en'){

    const apiUrl = props.baseURL + "translateany";
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        console.log("response trnasate :", response.data);
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }
  else{
     translationText.value = translationTextEn.value;
  }
  
};
watch(languageCode, () => {
  console.log("isLoggedIn ref changed, do something!");
  translatText();
});
onMounted(() => {
  translatText();
});
</script>

<style scoped>
h4 {
  font-family: "Roboto", sans-serif;
  color: #484848;
  font-weight: 700;
}

#add-product {
  float: right;
  font-weight: 500;
}
</style>
