<template>
  <div class="form-content">
    <div class="label-heading label-heading-active">
      <h4>{{ translationText.text.payment }}</h4>
      <!-- <a href="javascript: ;" class="link">Edit</a> -->
    </div>

    <div class="form-infomation" v-if="paymentParentNextBtn">
      <div class="options-highlited">
        {{ translationText.text.paymentSsl }}
        <i class="ri-shield-keyhole-line"></i>
      </div>
    </div>
    <div class="form-input" v-if="paymentParentNextBtn">
      <div class="payment-information" id="sector_descriptions">
        <div class="payment-options">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="sector"
              id="cards_sector"
              value="0" checked
            />
            <label class="form-check-label" for="cards_sector">{{
              translationText.text.creditCard
            }}</label>
            <img src="/assets/images/crdit-debit.jpg" alt="" class="cards" />
          </div>
        </div>
        <div class="payment-method">
          <div class="row row-flex">
            <div id="payment-element"></div>

            <StripeElements
              v-if="stripeLoaded"
              v-slot="{ elements, instance }"
              ref="elms"
              :stripe-key="stripeKey"
              :instance-options="instanceOptions"
              :elements-options="elementsOptions"
            >
              <div class="col-12 form-group">
                <label>{{ translationText.text.cardNumber }}</label>
                <div class="input-icon">
                  <StripeElement
                    ref="card"
                    type="cardNumber"
                    :elements="elements"
                    :options="cardNumberOptions"
                  />
                  <img src="/assets/images/cards.png" alt="" class="icon" />
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group">
                  <label for="">{{ translationText.text.expiration }}</label>
                  <StripeElement
                    ref="card"
                    type="cardExpiry"
                    :elements="elements"
                    :options="cardNumberOptions"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for=""
                    >{{ translationText.text.cvv }}
                    {{ paymentParentNextBtn }}</label
                  >
                  <div class="input-icon">
                    <StripeElement
                      ref="card"
                      type="cardCvc"
                      :elements="elements"
                      :options="cardNumberOptions"
                    />
                  </div>
                </div>
              </div>
            </StripeElements>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    v-if="paymentParentNextBtn"
    @click="pay"
    type="button"
    class="btn rc btn-dark"
    value="Place Order"
  >
    {{ translationText.text.placeOrder }}
    <i class="ri-arrow-right-line"></i>
  </button>
</template>
<script>
import { StripeElements, StripeElement, createElement } from "vue-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  computed,
  defineProps,
} from "vue";
import { useLoginStore } from "../../stores/login";
import { userLanguage } from "../../stores/language";
import { useRouter } from "vue-router";
import { useCartStore } from "../../stores/cart";

export default defineComponent({
  name: "CardOnly",
  //props: ["paymentParentNextBtn", "translationText"],
  components: {
    StripeElements,
    StripeElement,
    createElement,
  },
  //const router = useRouter();

  props: [
    "paymentParentNextBtn",
    "translationText",
    "email",
    "password",
    "country",
    "first_name",
    "last_name",
    "address1",
    "addres2",
    "city",
    "state",
    "zip_code",
    "mobile",
    "billSame",
    "stayup",
    "terms",
    "shippingType",
    "baseURL",
    "shippingTypeName",
  ],
  data() {
    return {
      paymentBtn: false,
      //bar: { name: "hi" }
    };
  },

  setup(props) {
    console.log(props);
    const paymentBtn = ref(false);
    const stripeKey = ref(`${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`); // test key
    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    });
    const elementsOptions = ref({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    });
    const cardOptions = ref({
      // https://stripe.com/docs/stripe.js#element-options
      value: {
        postalCode: "12345",
      },
    });
    const userLogin = useLoginStore();
    const router = useRouter();
    const stripeLoaded = ref(false);
    const card = ref();
    const elms = ref();
    const cardNumber = ref();
    const cardExpiry = ref();
    const cardCvc = ref();
    const cartData = useCartStore();
    const userLanguages = userLanguage();
    onBeforeMount(() => {
      const stripePromise = loadStripe(stripeKey.value);
      stripePromise.then(() => {
        stripeLoaded.value = true;
      });
    });

    onMounted(() => {
      const appearance = {
        /* appearance */
      };
      const options = {
        /* options */
      };
      //elements.value = stripe.value.elements({clientSecret, appearance})
      //   const paymentElement = elements.value.create('payment', options)

      //   paymentElement.mount("#payment-element")
    });
    computed(() => {});

    const pay = () => {
      console.log(props);
      // Get stripe element
      const cardElement = card.value.stripeElement;
      userLanguages.setLoading(true);
      // console.log("cardElement ",cardElement);

      // Access instance methods, e.g. createToken()
      elms.value.instance
        .createPaymentMethod("card", cardElement)
        .then((result) => {
          //alert(result);
          // Handle result.error or result.token
          console.log("result createPaymentMethod : ", result);
          if (result.error) {
            userLanguages.setLoading(false);
            swal({
              position: "top-end",
              icon: "error",
              title: result.error.message,
              button: false,
              timer: 1500,
            });
          } else {
            const toketData = {
              token: result.paymentMethod.id,
            };

            let tokenData = result.paymentMethod.id;
            // Create Order Id

            const UserData = userLogin.getUserUserData;
            const currentDate = new Date();
            let cartItems = [];
            let orderPayLoad = ref();
            if (cartData.getCartItems.length > 0) {
              cartData.getCartItems.forEach((items) => {
                cartItems.push({
                  product_id: items.id,
                  quantity: items.quantity,
                  price: items.price,
                  autoship: items.purchaseType == 2 ? "yes" : "no",
                });
              });

              console.log("cartData.getCartItems.length", cartItems);
            }

            if (userLogin.checkIsLoggedIn) {
              orderPayLoad.value = {
                referal_id: userLogin.getReferalId, //userLogin.getReferalId,
                user_id: UserData.id,
                first_name: props.first_name,
                last_name: props.last_name,
                mobile: props.mobile,
                order_date: currentDate,
                completion_date: null,
                shipping_status: "Not Shipped",
                order_status: "Pending",
                total: cartData.getCartTotalPrice,
                netTotal: cartData.getCartTotalNetPrice,
                currency: userLogin.getUserCountry == "Canada" ? "CAD" : "USD",
                ship_address: props.address1,
                ship_address2: props.addres2,
                ship_city: props.city,
                country: props.country,
                state: props.state,
                ship_zipcode: props.zip_code,
                shipping_method: props.shippingTypeName,
                shipping_amount: props.shippingType,
                shipping_tax_amount:cartData.getShippingTax,
                discount_amount: cartData.getDiscountTotal,
                tax_amount: cartData.getTaxTotal,
                notes: "",
                products: cartItems,
                shopping_country_code:
                  userLogin.getUserCountry == "Canada" ? "ca" : "usa",
                discount_coupan_id: cartData.getCartCouponCodeId
                  ? cartData.getCartCouponCodeId
                  : "",
              };

              // if (response.data.payment_status == "success") {
              //   orderPayLoad.value.pay_status = "Paid";
              // } else {
              //   orderPayLoad.value.pay_status = "Not Paid";
              // }
            } else {
              orderPayLoad.value = {
                referal_id: userLogin.getReferalId,
                email: props.email,
                first_name: props.first_name,
                last_name: props.last_name,
                mobile: props.mobile,
                order_date: currentDate,
                total: cartData.getCartTotalPrice,
                shopping_country_code:
                  userLogin.getUserCountry == "Canada" ? "ca" : "usa",
                netTotal: cartData.getCartTotalNetPrice,
                currency: userLogin.getUserCountry == "Canada" ? "CAD" : "USD",
                ship_address: props.address1,
                ship_address2: props.addres2,
                ship_city: props.city,
                country: props.country,
                state: props.state,
                ship_zipcode: props.zip_code,
                shipping_method: props.shippingTypeName,
                shipping_amount: props.shippingType,
                shipping_tax_amount:cartData.getShippingTax,
                discount_amount: cartData.getDiscountTotal,
                tax_amount: cartData.getTaxTotal,
                notes: "",
                products: cartItems,
                discount_coupan_id: cartData.getCartCouponCodeId
                  ? cartData.getCartCouponCodeId
                  : "",
              };
            }
            let apiUrl = "";
            if (userLogin.checkIsLoggedIn) {
              apiUrl = props.baseURL + "orders";
              console.log("payment checkIsLoggedIn ", apiUrl);
            } else {
              apiUrl = props.baseURL + "guest/orders";
              console.log("payment checkIsLoggedIn ", apiUrl);
            }
            console.log("apiUrl login ", apiUrl);
            axios
              .post(apiUrl, orderPayLoad.value)
              .then((response) => {
                console.log("response trnasate :", response.data);

                if (response.data.error) {
                  userLanguages.setLoading(false);
                  swal({
                    position: "top-end",
                    icon: "error",
                    title: response.data.error,
                    button: false,
                    timer: 2500,
                  });
                } else {
                  let orderPayment = {
                    order_id: response.data.data.order.id,
                    authorization_key: tokenData,
                  };
                  axios
                    .post(props.baseURL + "process-payment", orderPayment)
                    .then((response) => {
                      console.log("response trnasate :", response.data);

                      if (response.data.error) {
                        userLanguages.setLoading(false);
                        // swal({
                        //   position: "top-end",
                        //   icon: "error",
                        //   title: response.data.error,
                        //   button: false,
                        //   timer: 2500,
                        // });
                      } else {
                        swal({
                          position: "top-end",
                          icon: "success",
                          title: response.data.message,
                          button: false,
                          timer: 2500,
                        });
                        userLanguages.setLoading(false);
                        cartData.$reset();
                        router.push({ name: "PaymentSuccess" });
                      }

                      //translationText.value = response.data;
                    })
                    .catch((error) => {
                      userLanguages.setLoading(false);
                      console.error("Error fetching data:", error);
                    });

                  // swal({
                  //   position: "top-end",
                  //   icon: "success",
                  //   title: response.data.message,
                  //   button: false,
                  //   timer: 2500,
                  // });
                  //cartData.$reset();
                  /// router.push({ name: "PaymentSuccess" });
                }

                //translationText.value = response.data;
              })
              .catch((error) => {
                userLanguages.setLoading(false);
                console.error("Error fetching data:", error);
              });

            //router.push({ name: "Dashboard" });
            //userLogin.addUserUrlName(userName.value);
            //router.push({ name: "ThankYouSignUp" });

            //Create Order Id ends Here
            //cartData.$reset();
            //router.push({ name: "ThankYou" });
          }

          //   axios
          //     .post("https://devenv.marketingplatform.ca/stripe-cymcrop/charge.php", user)
          //     .then((res) => {

          //       console.log("response ",res);
          //       //userLogin.userLoggedIn(res.data.token);
          //       //localStorage.setItem("token", res.data.token);
          //       //this.$emit("fetchData");
          //       //router.push({ name: "Product" });
          //     })
          //     .catch((err) => {
          //     //   swal({
          //     //     text: "Unable to Log you in!",
          //     //     icon: "error",
          //     //     closeOnClickOutside: false,
          //     //   });
          //       console.log(err);
          //     })
          //     .finally(() => {
          //       //loading = false;
          //     });

          // axios.post(apiUrl)
          //   .then((response) => {
          //     this.products = response.data;
          //     console.log("product data",this.products.products.length);
          //   })
          //   .catch((error) => {
          //     console.error('Error fetching data:', error);
          //   });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    return {
      stripeKey,
      stripeLoaded,
      instanceOptions,
      elementsOptions,
      cardOptions,
      card,
      elms,
      pay,
    };
  },
});
</script>
