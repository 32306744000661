<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.downlineGenealogy }}</li>
    </ul>
    <div class="single-deta-box" data-aos="fade-up">
      <vue-tree
        ref="scaleTree"
        style="width: 800px; height: 600px; border: 1px solid gray"
        :dataset="vehicules"
        :config="treeConfig"
        linkStyle="straight"
        :collapse-enabled="false"
      >
        <template v-slot:node="{ node, collapsed }">
          <div
            class="rich-media-node"
            :style="{ border: collapsed ? '2px solid grey' : '' }"
          >
            <span
              @click="clickNode(node)"
              style="padding: 4px 0; font-weight: bold"
              >{{ node.name }}</span
            >
          </div>
        </template>
      </vue-tree>

      <div class="genealogyBtn">
        <button
          class="btn btn-primary btn-block mb-4"
          @click="controlScale('bigger')"
        >
          +
        </button>
        <button
          class="btn btn-primary btn-block mb-4"
          @click="controlScale('smaller')"
        >
          -
        </button>
        <button
          class="btn btn-primary btn-block mb-4"
          @click="controlScale('restore')"
        >
          {{ translationText.text.reset }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  defineProps,
  onUpdated,
  onMounted,
  defineComponent,
  watch,
} from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

export default defineComponent({
  name: "Genealogy",

  components: {
    VueTree,
  },
  props: ["baseURL"],
  methods: {
    controlScale(command) {
      switch (command) {
        case "bigger":
          this.$refs.scaleTree.zoomIn();
          break;
        case "smaller":
          this.$refs.scaleTree.zoomOut();
          break;
        case "restore":
          this.$refs.scaleTree.restoreScale();
          break;
      }
    },
  },
  setup(props) {
    const router = useRouter();
    const userLanguages = userLanguage();
    const userLogin = useLoginStore();
    const genealogy = ref(null);
    const { languageCode } = storeToRefs(userLanguages);
    const vehicules = ref({
      //   links: [
      //     { parent: 1, child: 2 },
      //     { parent: 3, child: 2 },
      //     { parent: 4, child: 2 },
      //   ],
      identifier: "customID",
    });

    const treeConfig = ref({
      nodeWidth: 120,
      nodeHeight: 80,
      levelHeight: 200,
    });
    const headers = {
      Authorization: "Bearer " + userLogin.loggedToken,
    };

    const addZoom = () => {
      genealogy.value.tree.zoomIn();
    };
    const clickNode = (nodeData) => {
      console.log("nodeData", nodeData);
    };
    const getGenealogy = () => {
      //const apiUrl = props.baseURL + "translateany";
      axios
        .get(props.baseURL + "user/teammembers", {
          headers,
        })
        .then((response) => {
          //vehicules.value.name = response.data.firstname + " " + response.data.lastname;

          const teamParentdata = ref({ name: "Wheels", children: "" });
          let teamCreatedData = [];
          response.data.team_members.forEach((teamData, index) => {
            let childrenCreate = [];
            if (teamData.team_members.length > 0) {
              teamData.team_members.forEach((teamDataInside, indexInside) => {
                childrenCreate.push({
                  name:
                    teamDataInside.firstname + " " + teamDataInside.lastname,
                  customID: teamDataInside.user_id,
                });
              });
            }

            console.log("childrenCreate data ", childrenCreate);
            teamCreatedData.push({
              name: teamData.firstname + " " + teamData.lastname,
              children: childrenCreate,
            });
          });
          console.log("response teamCreatedData :", teamCreatedData);
          console.log("response trnasate :", response.data);
          //teamParentdata.push({ name: "Wheels", children: teamCreatedData });

          teamParentdata.value.name =
            response.data.firstname + " " + response.data.lastname;
          teamParentdata.value.children = teamCreatedData;
          console.log("teamParentdata : ", teamParentdata.value);

          vehicules.value = teamParentdata.value;
          //vehicules.value = teamCreatedData;
          //translationText.value = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    const translationTextEn = ref({
      text: {
        downlineGenealogy: "Downline Genealogy",
        reset: "Reset",
      },
      language: "",
    });

    const translationText = ref({
      text: {
        downlineGenealogy: "Downline Genealogy",
        reset: "Reset",
      },
      language: "",
    });
    const translatText = () => {
      // console.log(props.baseURL);
      // const languageUrl = userLanguages.getLanguageCode
      //   ? "/" + userLanguages.getLanguageCode
      //   : "";
      translationText.value.language = userLanguages.getLanguageCode
        ? "/" + userLanguages.getLanguageCode
        : "";
      if(userLanguages.getLanguageCode != 'en'){

        const apiUrl = props.baseURL + "translateany";
        axios
          .post(apiUrl, translationText.value)
          .then((response) => {
            console.log("response trnasate :", response.data);
            translationText.value = response.data;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });

      }
      else{
        translationText.value = translationTextEn.value;
      }
      
    };

    watch(languageCode, () => {
      console.log("ref sidebar language change");
      translatText();
    });

    onMounted(() => {
      getGenealogy();
      translatText();
    });

    return {
      translationText,
      vehicules,
      treeConfig,
      clickNode,
    };
  },
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
