<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.inBox }}</li>
    </ul>
    <div class="single-deta-box" data-aos="fade-up">
      <div class="row">
        <div class="col-md-12">
          <div class="">
            <main role="main" class="bootdey.com">
              <div class="mb-3 bg-white rounded box-shadow">
                <template v-for="(message, index) in messages">
                  <div class="media text-muted pt-3">
                    <!-- <img
                    src="https://bootdey.com/img/Content/avatar/avatar7.png"
                    alt=""
                    class="mr-2 rounded"
                    width="32"
                    height="32"
                  /> -->
                    <p
                      class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray"
                    >
                      <strong class="d-block text-gray-dark"
                        >@{{ message.from.name }}</strong
                      >
                      {{ message.subject }}
                    </p>
                    <router-link
                      :to="{
                        name: 'MessageListDetails',
                        params: { id: message.id },
                      }"
                    >
                      {{ translationText.text.details }}
                    </router-link>
                    <a
                      href="javascript:void(0)"
                      @click="deleteMessage(message.id)"
                    >
                      {{ translationText.text.delete }}
                    </a>
                  </div>
                </template>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

const router = useRouter();
const userLanguages = userLanguage();
const userLogin = useLoginStore();
const { languageCode } = storeToRefs(userLanguages);
const messages = ref([]);
const props = defineProps(["baseURL"]);
const openOrders = ref([]);
const cancelOrders = ref([]);
const autoShipOrders = ref([]);
const refundOrders = ref([]);

const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};
const deleteMessage = (id) => {
  console.log("message id", id);
  swal("You won't be able to revert this!", {
    buttons: {
      cancel: "Cancel",
      ok: {
        value: "ok",
      },
    },
  }).then((value) => {
    if (value == "ok") {
      axios
        .get(props.baseURL + "user/delete/messages/" + id, {
          headers,
        })
        .then((response) => {
          console.log("My Team Order Listing ", response.data.message);
          swal({
            position: "top-end",
            icon: "success",
            title: response.data.message,
            button: false,
            timer: 2500,
          });

          router.push({ name: "MessageList" });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  });
};
const getGenealogy = () => {
  //const apiUrl = props.baseURL + "translateany";
  userLanguages.setLoading(true);
  axios
    .get(props.baseURL + "user/messages", {
      headers,
    })
    .then((response) => {
      console.log("My Team Order Listing ", response.data.messages);
      messages.value = response.data.messages;
      userLanguages.setLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      userLanguages.setLoading(false);
    });
};

const translationTextEn = ref({
  text: {
    inBox: "In-Box",
    details: "Details",
    delete: "Delete",
  },
  language: "",
});

const translationText = ref({
  text: {
    inBox: "In-Box",
    details: "Details",
    delete: "Delete",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";

     if(userLanguages.getLanguageCode != 'en'){
      const apiUrl = props.baseURL + "translateany";
      axios
        .post(apiUrl, translationText.value)
        .then((response) => {
          console.log("response trnasate :", response.data);
          translationText.value = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

     }
     else{
      translationText.value = translationTextEn.value;
     }
  
};

watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});

onMounted(() => {
  getGenealogy();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
