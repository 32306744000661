<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.inBox }}</li>
    </ul>
    <div class="single-deta-box" data-aos="fade-up">
      <div class="row">
        <div class="col-md-12">
          <div class="">
            <main role="main" class="container bootdey.com">
              <div class="my-3 p-3 bg-white rounded box-shadow">
                <div class="media text-muted pt-3">
                  <p
                    class="media-body pb-3 mb-0 small lh-125 border-bottom border-gray"
                  >
                    <strong class="d-block text-gray-dark"
                      >@{{ messages.message.sender.firstname }}
                      {{ messages.message.sender.lastname }}</strong
                    >
                    {{ messages.message.subject }}
                  </p>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRoute } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

const router = useRoute();
const userLanguages = userLanguage();
const userLogin = useLoginStore();
const props = defineProps(["baseURL"]);
const { languageCode } = storeToRefs(userLanguages);
const messages = ref({
  message: {
    subject: "",

    sender: {
      firstname: "",
      lastname: "",
    },
  },
});

console.log("router message details : ", router);

console.log(" message props : ", props);
const openOrders = ref([]);
const cancelOrders = ref([]);
const autoShipOrders = ref([]);
const refundOrders = ref([]);

const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};

const getGenealogy = () => {
  userLanguages.setLoading(true);
  //const apiUrl = props.baseURL + "translateany";
  axios
    .get(props.baseURL + "user/messages/" + router.params.id, {
      headers,
    })
    .then((response) => {
      console.log("My Team Order Listing ", response.data.message);
      messages.value = response.data.message;
      userLanguages.setLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      userLanguages.setLoading(false);
    });
};

const translationTextEn = ref({
  text: {
    inBox: "In-Box",
    details: "Details",
    delete: "Delete",
  },
  language: "",
});

const translationText = ref({
  text: {
    inBox: "In-Box",
    details: "Details",
    delete: "Delete",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";

    if(userLanguages.getLanguageCode != 'en'){
      const apiUrl = props.baseURL + "translateany";
      axios
        .post(apiUrl, translationText.value)
        .then((response) => {
          console.log("response trnasate :", response.data);
          translationText.value = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

    }
    else{
      translationText.value = translationTextEn.value;
    }
  
};

watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});
onMounted(() => {
  translatText();
  getGenealogy();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
