<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.my }} Ambassador</li>
    </ul>
    <div class="single-deta-box" data-aos="fade-up">
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-borderless main">
              <thead>
                <tr class="head">
                  <th scope="col">{{ translationText.text.name }}</th>
                  <th scope="col">{{ translationText.text.email }}</th>
                  <th scope="col">{{ translationText.text.phone }}</th>
                  <th scope="col">{{ translationText.text.country }}</th>
                  <th scope="col">{{ translationText.text.joinDate }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(order, index) in orders">
                  <tr class="rounded bg-white">
                    <td class="order-color">{{ order.name }}</td>
                    <td class="order-color">{{ order.email }}</td>
                    <td class="order-color">{{ order.mobile }}</td>
                    <td class="order-color">{{ order.country }}</td>
                    <td class="order-color">{{ order.join_date }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

const router = useRouter();
const userLanguages = userLanguage();
const userLogin = useLoginStore();

const orders = ref([]);
const { languageCode } = storeToRefs(userLanguages);
const openOrders = ref([]);
const cancelOrders = ref([]);
const autoShipOrders = ref([]);
const refundOrders = ref([]);
const props = defineProps(["baseURL"]);
const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};

const getGenealogy = () => {
  userLanguages.setLoading(true);
  //const apiUrl = props.baseURL + "translateany";
  axios
    .get(props.baseURL + "team-users/ambassador", {
      headers,
    })
    .then((response) => {
      console.log("My Team Order Listing ", response.data.my_ambassador);
      orders.value = response.data.my_ambassador;
      userLanguages.setLoading(false);
    })
    .catch((error) => {
      userLanguages.setLoading(false);
      console.error("Error fetching data:", error);
    });
};



const translationTextEn = ref({
  text: {
    my: "My",
    name: "Name",
    email: "Email",
    phone: "Phone",
    country: "Country",
    joinDate: "Join Date",
  },
  language: "",
});

const translationText = ref({
  text: {
    my: "My",
    name: "Name",
    email: "Email",
    phone: "Phone",
    country: "Country",
    joinDate: "Join Date",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";

    if(userLanguages.getLanguageCode != 'en'){

        const apiUrl = props.baseURL + "translateany";
        axios
          .post(apiUrl, translationText.value)
          .then((response) => {
            console.log("response trnasate :", response.data);
            translationText.value = response.data;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });

    }
    else{
      translationText.value = translationTextEn.value;
    }
  
};

watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});
onMounted(() => {
  getGenealogy();
  translatText();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
