<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.manageAddresses }}</li>
    </ul>
    <div class="single-deta-box" data-aos="fade-up">
      <div class="d-flex justify-content-center">
        <div class="card card-1">
          <Form novalidate @submit="signin">
            <div class="card-body">
              <div class="row justify-content-between mb-3">
                <div class="col-auto">
                  <h5 class="color-1 mb-0 change-color">
                    {{ translationText.text.billingAddress }}
                  </h5>
                </div>
                <div class="col-auto" v-if="editBth">
                  <button
                    @click="editButtonCLicked"
                    class="btn btn-primary btn-block mb-4"
                  >
                    Edit
                  </button>
                  <!-- <small>Order Edit</small> -->
                </div>
              </div>

              <div class="row mt-4">
                <div class="col">
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.address }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          type="text"
                          name="billing_address1"
                          rules="required"
                          class="form-control"
                          v-model="billingAddress.address1"
                        ></Field>
                        <ErrorMessage name="billing_address1" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ billingAddress.address1 }}</p>
                      </template>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.address1 }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          type="text"
                          name="billing_address2"
                          rules="required"
                          class="form-control"
                          v-model="billingAddress.address2"
                        ></Field>
                        <ErrorMessage name="billing_address2" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ billingAddress.address2 }}</p>
                      </template>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.country }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          rules="required"
                          v-model="billing_country"
                          as="select"
                          class="field-input form-control"
                          name="billing_country"
                          id="billing_country"
                        >
                          <option value="" disabled>
                            {{ translationText.text.contrySelectLabel }}
                          </option>
                          <template v-if="allCountriesForm.length > 0">
                            <template
                              v-for="(allCountry, index) in allCountriesForm"
                            >
                              <option :value="allCountry.id">
                                {{ allCountry.name }}
                              </option>
                            </template>
                          </template>
                        </Field>

                        <!-- <Field
                          type="text"
                          name="billing_country"
                          rules="required"
                          class="form-control"
                          v-model="billing_country"
                          :value="billingAddress.country"
                        ></Field> -->
                        <ErrorMessage name="billing_country" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ billingCountryName }}</p>
                      </template>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.state }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          rules="required"
                          v-model="billing_state"
                          as="select"
                          class="field-input form-control"
                          name="billing_state"
                          id="billing_state"
                        >
                          <option value="" disabled>
                            {{ translationText.text.contrySelectLabel }}
                          </option>
                          <template v-if="billStates.length > 0">
                            <template v-for="(allCountry, index) in billStates">
                              <option :value="allCountry.id">
                                {{ allCountry.name }}
                              </option>
                            </template>
                          </template>
                        </Field>

                        <ErrorMessage name="billing_state" />
                      </template>

                      <template v-else>
                        <p class="mb-1">{{ billingStateName }}</p>
                      </template>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.city }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          type="text"
                          name="billing_city"
                          rules="required"
                          class="form-control"
                          v-model="billingAddress.city"
                        ></Field>
                        <ErrorMessage name="billing_city" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ billingAddress.city }}</p>
                      </template>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.zip }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          type="text"
                          name="billing_zip_code"
                          rules="required"
                          class="form-control"
                          v-model="billingAddress.postal_code"
                        ></Field>
                        <ErrorMessage name="billing_zip_code" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ billingAddress.postal_code }}</p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-between mb-3">
                <div class="col-auto">
                  <h5 class="color-1 mb-0 change-color">
                    {{ translationText.text.shippingAddress }}
                  </h5>
                </div>
                <!-- <div class="col-auto">
                <small>Order : {{ order.order_number }}</small>
              </div> -->
              </div>

              <div class="row mt-4">
                <div class="col">
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.address }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          type="text"
                          name="shipping_address1"
                          rules="required"
                          class="form-control"
                          v-model="shippingAddress.address1"
                        ></Field>
                        <ErrorMessage name="shipping_address1" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ shippingAddress.address1 }}</p>
                      </template>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.address1 }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          type="text"
                          name="shipping_address2"
                          rules="required"
                          class="form-control"
                          v-model="shippingAddress.address2"
                        ></Field>
                        <ErrorMessage name="shipping_address2" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ shippingAddress.address2 }}</p>
                      </template>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.mobile }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          type="text"
                          name="shipping_mobile"
                          rules="required"
                          class="form-control"
                          v-model="shippingAddress.mobile"
                        ></Field>
                        <ErrorMessage name="shipping_mobile" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ shippingAddress.mobile }}</p>
                      </template>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.country }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          rules="required"
                          v-model="shippping_country"
                          as="select"
                          class="field-input form-control"
                          name="shipping_country"
                          id="shipping_country"
                        >
                          <option value="" disabled>
                            {{ translationText.text.contrySelectLabel }}
                          </option>
                          <template v-if="allCountriesForm.length > 0">
                            <template
                              v-for="(allCountry, index) in allCountriesForm"
                            >
                              <option :value="allCountry.id">
                                {{ allCountry.name }}
                              </option>
                            </template>
                          </template>
                        </Field>
                        <!--                         
                        <Field
                          type="text"
                          name="shipping_country"
                          rules="required"
                          class="form-control"
                          v-model="shippingAddress.country"
                        ></Field> -->
                        <ErrorMessage name="shipping_country" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ shippingCountryName }}</p>
                      </template>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.state }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          rules="required"
                          v-model="shippping_state"
                          as="select"
                          class="field-input form-control"
                          name="shipping_state"
                          id="shipping_state"
                        >
                          <option value="" disabled>
                            {{ translationText.text.contrySelectLabel }}
                          </option>
                          <template v-if="shipStates.length > 0">
                            <template v-for="(allCountry, index) in shipStates">
                              <option :value="allCountry.id">
                                {{ allCountry.name }}
                              </option>
                            </template>
                          </template>
                        </Field>

                        <ErrorMessage name="billing_state" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ shippingStateName }}</p>
                      </template>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.city }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          type="text"
                          name="shipping_city"
                          rules="required"
                          class="form-control"
                          v-model="shippingAddress.city"
                        ></Field>
                        <ErrorMessage name="shipping_city" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ shippingAddress.city }}</p>
                      </template>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-md-3">
                      <p class="mb-1">
                        <b>{{ translationText.text.zip }}</b>
                      </p>
                    </div>
                    <div class="col-md-6">
                      <template v-if="editField">
                        <Field
                          type="text"
                          name="shipping_zip_code"
                          rules="required"
                          class="form-control"
                          v-model="shippingAddress.zip_code"
                        ></Field>
                        <ErrorMessage name="shipping_zip_code" />
                      </template>
                      <template v-else>
                        <p class="mb-1">{{ shippingAddress.zip_code }}</p>
                      </template>
                      <div
                        class="col-auto"
                        style="padding-top: 10px"
                        v-if="editField"
                      >
                        <button
                          type="submit"
                          class="btn btn-primary btn-block mb-4"
                        >
                          Save
                        </button>
                        <!-- <small>Order Edit</small> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
// import _ from "lodash";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter, useRoute } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";
import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import * as AllRules from "@vee-validate/rules";
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});
const router = useRoute();
console.log("params ", router.params);
const userLanguages = userLanguage();

const shippingCountryName = ref();
const shippingStateName = ref();

const billingCountryName = ref();
const billingStateName = ref();

const allCountriesForm = ref([]);
const allCountrieShipping = ref([]);
const billStates = ref([]);

const shipStates = ref([]);

const billing_country = ref();
const billing_state = ref();

const shippping_country = ref();
const shippping_state = ref();

const billing_address1 = ref("");
const billing_address2 = ref("");
const billing_city = ref("");
const billing_zip_code = ref("");
const shipping_address1 = ref("");
const shipping_address2 = ref("");
const shipping_mobile = ref("");
const shipping_city = ref("");
const shipping_zip_code = ref("");

const editBth = ref(true);
const editField = ref(false);
const { languageCode } = storeToRefs(userLanguages);
const userLogin = useLoginStore();
const props = defineProps(["baseURL"]);
const shippingAddress = ref({
  address1: "",
  address2: "",
  city: "",
  country: "",
  state: "",
  zip_code: "",
  mobile: "",
});

const billingAddress = ref({
  address1: "",
  address2: "",
  city: "",
  country: "",
  state: "",
  postal_code: "",
});
const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};

const getCountry = async () => {
  axios
    .get(props.baseURL + "countries")
    .then((response) => {
      //allCountries.value = response.data.countries;
      //allCountriesBill.value = response.data.countries;
      allCountriesForm.value = response.data.countries;
      //console.log("country  data", response.data.countries);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const editButtonCLicked = () => {
  editField.value = true;
  editBth.value = false;
};

const signin = () => {
  userLanguages.setLoading(true);
  const payload = {
    bill_address: billingAddress.value.address1,
    bill_address2: billingAddress.value.address2,
    city: billingAddress.value.city,
    countryBill: billing_country.value,
    stateBill: billing_state.value,
    bil_zipcode: billingAddress.value.postal_code,

    mobile: shippingAddress.value.mobile,
    ship_address: shippingAddress.value.address1,
    ship_address2: shippingAddress.value.address2,
    ship_city: shippingAddress.value.city,
    country: shippping_country.value,
    state: shippping_state.value,
    ship_zipcode: shippingAddress.value.zip_code,
  };

  axios
    .post(props.baseURL + "user/address/update", payload, {
      headers,
    })
    .then((response) => {
      console.log("shipping_address ", response.data);
      userLanguages.setLoading(false);
      getShippingAddress();
      getBillingAddress();
      editField.value = false;
      editBth.value = true;

      swal({
        position: "top-end",
        icon: "success",
        title: response.data.message,
        button: false,
        timer: 2500,
      });
      //shippingAddress.value = response.data.shipping_address;
    })
    .catch((error) => {
      userLanguages.setLoading(false);
      swal({
        position: "top-end",
        icon: "error",
        title: err.response.data.message,
        button: false,
        timer: 2500,
      });
      console.error("Error fetching data:", error);
    });
};
const getShippingAddress = () => {
  //const apiUrl = props.baseURL + "translateany";
  axios
    .get(props.baseURL + "user/address/shipping", {
      headers,
    })
    .then((response) => {
      console.log("shipping_address ", response.data.shipping_address);
      shippingAddress.value = response.data.shipping_address;
      shippping_country.value = response.data.shipping_address.country;
      shippping_state.value = response.data.shipping_address.state;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const getBillingAddress = () => {
  //const apiUrl = props.baseURL + "translateany";
  axios
    .get(props.baseURL + "user/address/billing", {
      headers,
    })
    .then((response) => {
      console.log("billing_address ", response.data.billing_address);
      billingAddress.value = response.data.billing_address;
      billing_country.value = response.data.billing_address.country;
      billing_state.value = response.data.billing_address.state;

      //        billing_address1.value =
      //  billing_address2.value =
      //  billing_city.value =
      //  billing_zip_code.value =
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};



const translationTextEn = ref({
  text: {
    manageAddresses: "Manage Addresses",
    billingAddress: "Billing Address",
    address: "Address",
    address1: "Address 1",
    zip: "Zip/Postal code",
    shippingAddress: "Shipping Address",
    city: "City",
    mobile: "Mobile",
    country: "Country",
    state: "State",
  },
  language: "",
});

const translationText = ref({
  text: {
    manageAddresses: "Manage Addresses",
    billingAddress: "Billing Address",
    address: "Address",
    address1: "Address 1",
    zip: "Zip/Postal code",
    shippingAddress: "Shipping Address",
    city: "City",
    mobile: "Mobile",
    country: "Country",
    state: "State",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";

    if(userLanguages.getLanguageCode != 'en'){

      const apiUrl = props.baseURL + "translateany";
      axios
        .post(apiUrl, translationText.value)
        .then((response) => {
          console.log("response trnasate :", response.data);
          translationText.value = response.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

    }
    else{
      translationText.value = translationTextEn.value;
    }
  
};

watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});

watch(shippping_country, (newValue, oldValue) => {
  let index = allCountriesForm.value.findIndex(
    (product) => product.id === newValue
  );
  if (index != -1) {
    shippingCountryName.value = allCountriesForm.value[index].name;
  }

  console.log("shippingAddress.country ", newValue);
  axios
    .get(props.baseURL + "states/" + newValue)
    .then((response) => {
      shipStates.value = response.data.states;

      let index = shipStates.value.findIndex(
        (product) => product.id === shippping_state.value
      );
      if (index != -1) {
        shippingStateName.value = shipStates.value[index].name;
      }

      // if (sameAsBilling.value == true) {
      //   console.log("fffffffffffffffffff", stateBill.value);
      //   console.log("state.value ", stateBill.value);
      //   state_ship.value = stateBill.value;

      // }

      console.log("states  data", response.data.states);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
});

watch(billing_country, (newValue, oldValue) => {
  let index = allCountriesForm.value.findIndex(
    (product) => product.id === newValue
  );
  if (index != -1) {
    billingCountryName.value = allCountriesForm.value[index].name;
  }

  console.log("shippingAddress.country ", newValue);
  axios
    .get(props.baseURL + "states/" + newValue)
    .then((response) => {
      billStates.value = response.data.states;

      let index = billStates.value.findIndex(
        (product) => product.id === billing_state.value
      );
      if (index != -1) {
        billingStateName.value = billStates.value[index].name;
      }

      // if (sameAsBilling.value == true) {
      //   console.log("fffffffffffffffffff", stateBill.value);
      //   console.log("state.value ", stateBill.value);
      //   state_ship.value = stateBill.value;

      // }

      console.log("states  data", response.data.states);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
});

onMounted(() => {
  getCountry();
  translatText();
  getShippingAddress();
  getBillingAddress();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
