<template>
  <div class="banner inner-banner new-height">
    <img
      src="/assets/images/product-listing-Banner.jpg"
      alt=""
      class="w-100 zoom-out"
    />
    <div class="banner-text text-center">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 col-md-10" data-aos="fade-up">
            <h1>
              <span>{{ props.translationText.text.productBannerLabel }}</span>
            </h1>
            <p>
              {{ props.translationText.text.productBannerLabelSUbText }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import { userLanguage } from "../../stores/language";
const userLanguages = userLanguage();
const cartData = useCartStore();
const route = useRoute();
let props = defineProps(["translationText"]);
</script>

<style scoped></style>
