<template>
    <div class="social">
        <a href="javascript:; ">
            <i class="ri-facebook-circle-fill"></i>
        </a>
        <a href="javascript:; ">
            <i class="ri-twitter-x-line"></i>
        </a>
        <a href="javascript:; ">
            <i class="ri-pinterest-fill"></i>
        </a>
        <a href="javascript:; ">
            <i class="ri-mail-fill"></i>
        </a>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductSocial',
  components: {},
  data: () => ({
    // currentSlide: 0,
    // beforeAfterImage:50
  }),
  methods: {
   
  },
})
</script>