<template>
  <section class="products-related-tabs pt-0" data-aos="fade-up">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <ul class="nav nav-tabs mb-md-5 mb-3" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="overview-tab"
                data-bs-toggle="tab"
                data-bs-target="#overview"
                type="button"
                role="tab"
                aria-controls="overview"
                aria-selected="true"
              >
                {{ props.translationText.text.overView }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="direction-tab"
                data-bs-toggle="tab"
                data-bs-target="#direction"
                type="button"
                role="tab"
                aria-controls="direction"
                aria-selected="false"
              >
                {{ props.translationText.text.direction }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="faq-tab"
                data-bs-toggle="tab"
                data-bs-target="#faq"
                type="button"
                role="tab"
                aria-controls="faq"
                aria-selected="false"
              >
                {{ props.translationText.text.faq }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="doc-tab"
                data-bs-toggle="tab"
                data-bs-target="#doc"
                type="button"
                role="tab"
                aria-controls="doc"
                aria-selected="false"
              >
                {{ props.translationText.text.documentation }}
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="ecommerce-tab"
                data-bs-toggle="tab"
                data-bs-target="#ecommerce"
                type="button"
                role="tab"
                aria-controls="ecommerce"
                aria-selected="false"
              >
                {{ props.translationText.text.ecommerce }}
              </button>
            </li>

          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="overview"
              role="tabpanel"
              aria-labelledby="overview-tab"
            >
              <div v-html="props.product.overview"></div>
              <!-- <div class="row">
                            <div class="col-md-6 order-2 order-md-1">
                                <h2>IMMUNITY</h2>
                                <h3>A strong foundation is essential for healthy living</h3>
                                <p>You cannot build a stable house on quicksand. You cannot drive a car effectively with a flat tire. You cannot sustain long-term health without a strong immune system. As your first and best line of defence, your immune system protects you from illness and helps you recover quicker if you do get sick.</p>
                                <p>Health issues, physical and psychological stress, poor nutrition, advancing age, and even environmental conditions can compromise its ability to fight for you. But glutathione, a naturally occurring antioxidant in your body, is the ultimate equalizer. With over 150K medical articles published about glutathione and its restorative effects on our body, we created the optimal conditions for good health. We did not invent the wheel; we created a better vehicle.</p>
                            </div>
                            <div class="col-md-6 order-1 order-md-2 mb-4 mb-md-0">
                                <img src="/assets/images/about-us-final1.jpg" alt="">
                            </div>
                        </div> -->
            </div>
            <div
              class="tab-pane fade"
              id="direction"
              role="tabpanel"
              aria-labelledby="direction-tab"
            >
              <div v-html="props.product.instructions"></div>
              <!-- <div v-html="props.product.description"></div> -->
              <!-- <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Architecto tempore ducimus beatae accusantium accusamus repellendus iure sapiente, commodi eligendi. Nam aliquam deserunt a tenetur saepe optio dicta voluptatum velit necessitatibus.</p> -->
            </div>
            <div
              class="tab-pane fade"
              id="faq"
              role="tabpanel"
              aria-labelledby="faq-tab"
            >
              <div v-html="props.product.faq"></div>
              <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis incidunt molestiae consequuntur beatae quis doloribus labore suscipit molestias repellendus. Doloremque aut quis id. Aperiam repellat nesciunt odit doloremque eum et.</p> -->
            </div>
            <div
              class="tab-pane fade"
              id="doc"
              role="tabpanel"
              aria-labelledby="doc-tab"
            >
              <div v-html="props.product.documentation"></div>
              <!-- <h5>Product description: <a href="javascript: ;" class="line-animation">Click to download</a></h5>
                        <h5>Product label: <a href="javascript: ;" class="line-animation">Click to download</a>, <a href="javascript: ;" class="line-animation">Click to download</a></h5> -->
            </div>

            <div
              class="tab-pane fade"
              id="ecommerce"
              role="tabpanel"
              aria-labelledby="ecommerce-tab"
            >
              <div v-html="props.product.ecommerce"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, defineProps, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import { Carousel, Slide, Navigation } from "vue3-carousel";

const cartData = useCartStore();
const route = useRoute();
let props = defineProps(["baseURL", "product", "translationText"]);
//props: ["baseURL"],
console.log("id ", route.params.id);
const currentSlide = ref(0);
const purchaseType = ref();
const productSingle = ref({ name: "" });
const slideTo = (val) => {
  currentSlide.value = val;
};

onMounted(() => {
  //getProductDetails();

  console.log("mounted", props.product);
});
</script>
