<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.earningsHistory }}</li>
    </ul>
    <div class="single-deta-box" data-aos="fade-up">
      <div class="row">
        <div class="commissionFilter">
          <!-- <date-picker v-model:value="time0"></date-picker>
          <date-picker v-model:value="time1" type="datetime"></date-picker>
          <date-picker v-model:value="time2" valueType="format"></date-picker> -->
          <date-picker
            v-model:value="time3"
            value-type="format"
            format="YYYY-MM-DD"
            range
          ></date-picker>
          <button @click="searchCommission" class="btn btn-dark">
            {{ translationText.text.search }}
          </button>

          <!-- <button @click="searchCommissionReset" class="btn btn-dark">
            Reset
          </button> -->

          <!-- Start Date <Datepicker v-model="start_date" /> End Date
          <Datepicker v-model="end_date" /> -->
        </div>
        <div class="col-md-12">
          <ul class="nav nav-tabs" id="myTab" v-if="notSearch">
            <li class="nav-item">
              <a href="#complex" class="nav-link active" data-bs-toggle="tab">{{
                translationText.text.biWeekly
              }}</a>
            </li>
            <li class="nav-item">
              <a href="#prezerv" class="nav-link" data-bs-toggle="tab">{{
                translationText.text.monthly
              }}</a>
            </li>
          </ul>
          <div class="tab-content" v-if="notSearch">
            <div class="tab-pane fade show active" id="complex">
              <div class="container mt-5">
                <div class="table-responsive">
                  <table class="table table-borderless main">
                    <thead>
                      <!-- <tr class="head">
                        <th scope="col" class="ml-2">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                          </div>
                        </th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Customer</th>
                        <th scope="col">Fullfillment</th>
                        <th scope="col">Total</th>
                        <th scope="col">Profit</th>
                        <th scope="col">Status</th>
                        <th scope="col">Updated</th>
                      </tr> -->
                      <th scope="col">{{ translationText.text.fromDate }}</th>
                      <th scope="col">{{ translationText.text.toDate }}</th>
                      <th scope="col">{{ translationText.text.totalBV }}</th>
                      <th scope="col">
                        {{ translationText.text.totalCommission }} (US)
                      </th>
                      <th scope="col">
                        {{ translationText.text.totalCommission }} (CA)
                      </th>
                      <th></th>
                    </thead>
                    <tbody>
                      <template v-if="commissions.bi_weekly != null">
                        <template
                          v-for="(
                            order, keyParent, indexParent
                          ) in commissions.bi_weekly"
                          :key="keyParent"
                        >
                          <!-- {{ order }} dddd {{ keyParent }} key -->
                          <template
                            v-for="(orderSingle, keySub, sub) in order"
                            :key="keySub"
                          >
                            <tr class="rounded bg-white">
                              <td class="order-color">
                                {{ orderSingle.start_date }}
                              </td>
                              <td class="order-color">
                                {{ orderSingle.end_date }}
                              </td>
                              <td class="order-color">
                                {{ orderSingle.total_bv }}
                              </td>
                              <td class="order-color">
                                <template v-if="keyParent == 'USD'">
                                  ${{ orderSingle.total_commission.toFixed(2) }}
                                </template>
                              </td>
                              <td class="order-color">
                                <template v-if="keyParent == 'CAD'">
                                  ${{ orderSingle.total_commission.toFixed(2) }}
                                </template>
                              </td>
                              <td class="order-color">
                                <button
                                  @click="
                                    getEachCommission(
                                      orderSingle.start_date,
                                      orderSingle.end_date
                                    )
                                  "
                                  class="btn btn-dark"
                                >
                                  {{ translationText.text.details }}
                                </button>
                              </td>
                            </tr>

                            <!-- sss{{ orderSingle.start_date }} -->
                          </template>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="prezerv">
              <div class="container mt-5">
                <div class="table-responsive">
                  <table class="table table-borderless main">
                    <thead>
                      <th scope="col">{{ translationText.text.fromDate }}</th>
                      <th scope="col">{{ translationText.text.toDate }}</th>
                      <th scope="col">{{ translationText.text.totalBV }}</th>
                      <th scope="col">
                        {{ translationText.text.totalCommission }} (US)
                      </th>
                      <th scope="col">
                        {{ translationText.text.totalCommission }} (CA)
                      </th>
                      <th></th>
                    </thead>
                    <tbody>
                      <template v-if="commissions.monthly != null">
                        <template
                          v-for="(
                            order, keyParent, indexParent
                          ) in commissions.bi_weekly"
                          :key="keyParent"
                        >
                          <!-- {{ order }} dddd {{ keyParent }} key -->
                          <template
                            v-for="(orderSingle, keySub, sub) in order"
                            :key="keySub"
                          >
                            <tr class="rounded bg-white">
                              <td class="order-color">
                                {{ orderSingle.start_date }}
                              </td>
                              <td class="order-color">
                                {{ orderSingle.end_date }}
                              </td>
                              <td class="order-color">
                                {{ orderSingle.total_bv }}
                              </td>
                              <td class="order-color">
                                <template v-if="keyParent == 'USD'">
                                  ${{ orderSingle.total_commission.toFixed(2) }}
                                </template>
                              </td>
                              <td class="order-color">
                                <template v-if="keyParent == 'CAD'">
                                  ${{ orderSingle.total_commission.toFixed(2) }}
                                </template>
                              </td>
                              <td class="order-color">
                                <button
                                  @click="
                                    getEachCommission(
                                      orderSingle.start_date,
                                      orderSingle.end_date
                                    )
                                  "
                                  class="btn btn-dark"
                                >
                                  {{ translationText.text.details }}
                                </button>
                              </td>
                            </tr>

                            <!-- sss{{ orderSingle.start_date }} -->
                          </template>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <template v-if="serachResult">
            <template v-if="allCommissions.CAD">
              <div class="commsionnsDetails">
                <template
                  v-for="(allCommission, index) in allCommissions.CAD
                    .order_details"
                >
                  <div class="commissionow">
                    <p>
                      {{ translationText.text.name }}
                      {{ allCommission.user_details.name }}
                      <!-- {{ allCommissions.user_details.name }} -->
                    </p>

                    <p>
                      User Type

                      {{
                        allCommission.user_details.user_type
                          .charAt(0)
                          .toUpperCase() +
                        allCommission.user_details.user_type.slice(1)
                      }}

                      <!-- {{ allCommission.user_details.user_type }} -->
                      <!-- {{ allCommissions.user_details.name }} -->
                    </p>

                    <p>
                      {{ translationText.text.orderDate }}
                      {{ allCommission.order_date }}
                    </p>
                    <p>
                      {{ translationText.text.total }} ${{
                        allCommission.total
                      }}
                    </p>
                    <p>
                      {{ translationText.text.totalCommission }}
                      CAD {{ allCommission.commission_value }}
                      <!-- <template
                      v-for="(totalCommissionData, index) in allCommissions.CAD
                        .commission_details"
                    >
                      {{ totalCommissionData.currency }}
                      {{ totalCommissionData.total_commission }}
                    </template> -->
                    </p>
                    <p>
                      {{ translationText.text.totalBV }}
                      {{ allCommission.bv }}
                    </p>

                    <template v-if="allCommission.products.length > 0">
                      <template
                        v-for="(product, index) in allCommission.products"
                      >
                        <div class="productDetails">
                          <img :src="product.thumbnail_url" />
                          <p>{{ product.name }}</p>
                        </div>
                      </template>
                    </template>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <div style="text-align: center"><b>No data found.</b></div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import * as moment from "moment";
// Loading

const fullPage = ref(true);
const formContainer = ref(null);
const props = defineProps(["baseURL"]);
console.log("props commission listing : ", props);
// Loading Ends
const date = ref();
const router = useRouter();
const userLanguages = userLanguage();
const userLogin = useLoginStore();
const { languageCode } = storeToRefs(userLanguages);
const time0 = ref(null);
const time1 = ref(null);
const time2 = ref(null);
const time3 = ref(null);

const serachResult = ref(false);

const notSearch = ref(true);

const allCommissions = ref([]);
const start_date = ref();
const end_date = ref();
const commissions = ref({
  monthly: null,
  bi_weekly: null,
});

const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};
// const searchCommissionReset = () => {
//   notSearch.value = true;
// };
const searchCommission = () => {
  serachResult.value = true;
  console.log("time3", time3.value);
  if (time3.value != null) {
    allCommissions.value = [];
    userLanguages.setLoading(true);
    console.log("range data : ", time3.value);
    const payLoadDate = {
      start_date: time3.value[0],
      end_date: time3.value[1],
    };

    axios
      .post(props.baseURL + "user/commissionsdetails", payLoadDate, {
        headers,
      })
      .then((response) => {
        console.log("commissionsdetails  ", response.data.commissions);
        allCommissions.value = response.data.commissions;
        notSearch.value = false;
        console.log("notSearch.value", notSearch.value);
        //loader.hide();
        //commissions.value = response.data;
      })
      .catch((error) => {
        //loader.hide();
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        //loading = false;
        userLanguages.setLoading(false);
      });
  }
};
const getEachCommission = (startDate, endDate) => {
  userLanguages.setLoading(true);
  allCommissions.value = [];
  serachResult.value = true;
  console.log("moment ", moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD"));
  console.log("start Date ", startDate);
  console.log("end Date ", endDate);

  const payLoadDate = {
    start_date: moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
    end_date: moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD"),
  };

  axios
    .post(props.baseURL + "user/commissionsdetails", payLoadDate, {
      headers,
    })
    .then((response) => {
      console.log(
        "commissionsdetails  ",
        response.data.commissions.CAD.total_commission
      );
      allCommissions.value = response.data.commissions;
      //notSearch.value = false;
      // loader.hide();
      //commissions.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      //loader.hide();
    })
    .finally(() => {
      //loader.hide();
      //loading = false;
      userLanguages.setLoading(false);
    });
};
const getGenealogy = () => {
  //const apiUrl = props.baseURL + "translateany";
  userLanguages.setLoading(true);
  axios
    .get(props.baseURL + "user/commissions", {
      headers,
    })
    .then((response) => {
      console.log("Order Listing ", response.data);
      commissions.value = response.data;
      userLanguages.setLoading(false);
      //loader.hide();
    })
    .catch((error) => {
      //loader.hide();
      userLanguages.setLoading(false);
      console.error("Error fetching data:", error);
    })
    .finally(() => {
      userLanguages.setLoading(false);
      //loading = false;
    });
};


const translationTextEn = ref({
  text: {
    earningsHistory: "Earnings History",
    search: "Search",
    biWeekly: "Bi-Weekly",
    monthly: "Monthly",
    fromDate: "From Date",
    toDate: "To Date",
    totalBV: "Total BV",
    totalCommission: "Total Commission",
    details: "Details",
    name: "Name",
    orderDate: "Order Date",
    total: "Total",
  },
  language: "",
});

const translationText = ref({
  text: {
    earningsHistory: "Earnings History",
    search: "Search",
    biWeekly: "Bi-Weekly",
    monthly: "Monthly",
    fromDate: "From Date",
    toDate: "To Date",
    totalBV: "Total BV",
    totalCommission: "Total Commission",
    details: "Details",
    name: "Name",
    orderDate: "Order Date",
    total: "Total",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if(userLanguages.getLanguageCode != 'en'){
    const apiUrl = props.baseURL + "translateany";
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        console.log("response trnasate :", response.data);
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }
  else{
    translationText.value = translationTextEn.value;
  }
  
};

watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});
onMounted(() => {
  getGenealogy();
  translatText();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
