import { createPinia, defineStore } from "pinia";
export const userLanguage = defineStore("language", {
  state: () => ({
    languageCode: null,
    languageName: null,
    loading: false,
  }),
  persist: true,
  getters: {
    getLanguageCode: (state) => state.languageCode,
    getLanguageName: (state) => state.languageName,
    getLoading: (state) => state.loading,
  },
  actions: {
    setLanguage(language) {
      this.languageName = language.name;
      this.languageCode = language.code;
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
});
