<template>
  <section class="about-text gray-bg" data-aos="fade-up">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="bg-white p-md-5 p-3">
            <h2 class="mb-3">Thank You</h2>
            <p class="gray-text">
              Thank you for choosing Our Product! Your support means the world
              to us. We hope our product exceeds your expectations and brings
              you joy and satisfaction. If you have any questions or feedback,
              feel free to reach out. Here's to many happy moments with your new
              purchase!
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import $ from "jquery";
export default defineComponent({
  name: "About",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  mounted: function () {
    $(".faq-accordion-title").click(function () {
      $(this)
        .parent()
        .siblings(".faq-accordion-item")
        .find(".faq-accordion-title")
        .removeClass("active");
      $(this)
        .parent()
        .siblings(".faq-accordion-item")
        .find(".faq-accordion-content")
        .slideUp(250);
      $(this).toggleClass("active");
      $(this).next().slideToggle(250);
    });
  },
  data() {
    return {
      silder: [
        {
          name: "ROBERT GAUTHIER",
          description: "CEO, PRESIDENT",
          image: "/assets/images/ROBERT-GAUTHIER.png",
        },
        {
          name: "MARISOL LOPEZ",
          description: "VICE PRESIDENT / ADMIN & FINANCE",
          image: "/assets/images/MARISOL-LOPEZ.png",
        },
        {
          name: "WILLY TSAKU, MD",
          description: "MEDICAL ADVISOR",
          image: "/assets/images/WILLY-TSAKU.png",
        },
        //    {
        //     name:"Prezerv Bronze",
        //     description:"Prezerv Bronze is an all-natural, rejuvenating skincare treatment that transforms the way you care for your body.",
        //     image:"/assets/images/product4.png"
        //   },
      ],
      //bus: new Vue(),
    };
  },
});
</script>
