<template>
  <section class="black-area" data-aos="fade-up">
    <div class="container-fluid">
      <div class="row mb-5">
        <div class="col-md-3">
          <h2>UNE VIE</h2>
        </div>
        <div class="col-md-9">
          <p>{{ props.translationText.text.uneVieText1 }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 mb-5">
          <div class="pro-box">
            <img src="/assets/images/Univie-Image1.jpg" alt="" class="mb-3" />
            <h4>{{ props.translationText.text.uneVieText2 }}</h4>
          </div>
        </div>
        <div class="col-md-4 mb-5">
          <div class="pro-box">
            <img src="/assets/images/Univie-Image2.jpg" alt="" class="mb-3" />
            <h4>
              {{ props.translationText.text.uneVieText3 }}
            </h4>
          </div>
        </div>
        <div class="col-md-4 mb-5">
          <div class="pro-box">
            <img src="/assets/images/Univie-Image3.jpg" alt="" class="mb-3" />
            <h4>{{ props.translationText.text.uneVieText4 }}</h4>
          </div>
        </div>
        <div class="col-md-4 mb-5">
          <div class="pro-box">
            <img src="/assets/images/Univie-Image4.jpg" alt="" class="mb-3" />
            <h4>{{ props.translationText.text.uneVieText5 }}</h4>
          </div>
        </div>
        <div class="col-md-4 mb-5">
          <div class="pro-box">
            <img src="/assets/images/Univie-Image5.jpg" alt="" class="mb-3" />
            <h4>
              {{ props.translationText.text.uneVieText6 }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="green-area" data-aos="fade-up">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="">
            <p>{{ props.translationText.text.uneVieText7 }}</p>
            <p>{{ props.translationText.text.uneVieText8 }}</p>
            <p>{{ props.translationText.text.uneVieText9 }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, computed, defineProps, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import { userLanguage } from "../../stores/language";
const userLanguages = userLanguage();
const cartData = useCartStore();
const route = useRoute();
let props = defineProps(["translationText"]);
</script>
