<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>{{ translationText.text.personalInformation }}</li>
    </ul>
    <div class="single-deta-box" data-aos="fade-up">
      <div class="d-flex justify-content-center" style="display: none">
        <div class="card card-1">
          <div class="card-body">
            <div class="row justify-content-between">
              <!-- <div class="col-auto">
                <h6 class="color-1 mb-0 change-color">Order</h6>
              </div> -->
              <!-- <div class="col-auto">
                <small>Order : {{ order.order_number }}</small>
              </div> -->
            </div>

            <div class="row">
              <div class="col">
                <div class="row justify-content-between">
                  <div class="col-auto">
                    <h5 class="mb-3 text-dark">
                      {{ translationText.text.personalInformation }}
                    </h5>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1">
                      <b>{{ translationText.text.firstName }}</b>
                    </p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">{{ userDetails.firstname }}</p>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1">
                      <b>{{ translationText.text.lastName }}</b>
                    </p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">{{ userDetails.lastname }}</p>
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1">
                      <b>{{ translationText.text.email }}</b>
                    </p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">{{ userDetails.email }}</p>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1">
                      <b>{{ translationText.text.companyName }}</b>
                    </p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">{{ userDetails.company_name }}</p>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1">
                      <b>{{ translationText.text.mobile }}</b>
                    </p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">{{ userDetails.mobile }}</p>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1">
                      <b>{{ translationText.text.birthDate }}</b>
                    </p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">{{ userDetails.date_of_birth }}</p>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1">
                      <b>{{ translationText.text.rank }}</b>
                    </p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">{{ userDetails.current_rank }}</p>
                  </div>
                </div>

                <div class="row justify-content-between">
                  <div class="flex-sm-col text-right col">
                    <p class="mb-1">
                      <b>{{ translationText.text.joinDate }}</b>
                    </p>
                  </div>
                  <div class="flex-sm-col col-auto">
                    <p class="mb-1">{{ userDetails.join_date }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter, useRoute } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";

const router = useRoute();
console.log("params ", router.params);
const userLanguages = userLanguage();
const userLogin = useLoginStore();
const props = defineProps(["baseURL"]);
const { languageCode } = storeToRefs(userLanguages);
const userDetails = ref({
  firstname: "",
  lastname: "",
  email: "",
  company_name: "",
  mobile: "",
  date_of_birth: "",
  current_rank: "",
  join_date: "",
});

const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};
const getGenealogy = () => {
  //const apiUrl = props.baseURL + "translateany";
  axios
    .get(props.baseURL + "user/details", {
      headers,
    })
    .then((response) => {
      console.log("Order Listing ", response.data.userDetails);
      userDetails.value = response.data.userDetails;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};


const translationTextEn = ref({
  text: {
    personalInformation: "Personal Information",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    companyName: "Company Name",
    mobile: "Mobile",
    birthDate: "Birth Date",
    rank: "Rank",
    joinDate: "Join Date",
  },
  language: "",
});

const translationText = ref({
  text: {
    personalInformation: "Personal Information",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    companyName: "Company Name",
    mobile: "Mobile",
    birthDate: "Birth Date",
    rank: "Rank",
    joinDate: "Join Date",
  },
  language: "",
});
const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";

  if(userLanguages.getLanguageCode != 'en'){
    const apiUrl = props.baseURL + "translateany";
  axios
    .post(apiUrl, translationText.value)
    .then((response) => {
      console.log("response trnasate :", response.data);
      translationText.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

  }
  else{
    translationText.value = translationTextEn.value;
  }
  
};

watch(languageCode, () => {
  console.log("ref sidebar language change");
  translatText();
});

onMounted(() => {
  translatText();
  getGenealogy();
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rich-media-node {
  width: 80px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #f7c616;
  border-radius: 4px;
}
</style>
