<template>
  <div class="col-md-5 main-img" data-aos="fade-up">
    <div class="thumb-img">
      <Carousel
        id="gallery"
        :items-to-show="1"
        :wrap-around="false"
        v-model="currentSlide"
      >
        <!-- <Slide v-for="slide in 10" :key="slide">
                        <div class="carousel__item">
                        <img src="/assets/images/image-complex.jpg" alt="">
                        </div>
                        </Slide> -->
        <Slide :key="0">
          <div class="carousel__item" @click="slideTo(0)">
            <img :src="props.product.thumbnail_url" alt="" />
          </div>
        </Slide>

        <Slide v-for="(slide, index) in props.product.images_url" :key="slide">
          <div class="carousel__item" @click="slideTo(index)">
            <img :src="slide" alt="" />
          </div>
        </Slide>
      </Carousel>
    </div>
    <div class="thumb-list">
      <Carousel
        id="thumbnails"
        :items-to-show="3"
        :wrap-around="false"
        v-model="currentSlide"
        ref="carousel"
      >
        <Slide :key="0">
          <div class="carousel__item" @click="slideTo(0)">
            <img :src="props.product.thumbnail_url" alt="" />
          </div>
        </Slide>
        <Slide v-for="(slide, index) in props.product.images_url" :key="slide">
          <div class="carousel__item" @click="slideTo(index + 1)">
            <img :src="slide" alt="" />
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import { Carousel, Slide, Navigation } from "vue3-carousel";

const cartData = useCartStore();
const route = useRoute();
let props = defineProps(["baseURL", "product"]);
//props: ["baseURL"],
console.log("id ", route.params.id);
const currentSlide = ref(0);
const purchaseType = ref();
const productSingle = ref({ name: "" });
const slideTo = (val) => {
  currentSlide.value = val;
  console.log("val fffffffffff ", val);
};

onMounted(() => {
  //getProductDetails();

  console.log("mounted", props.product);
});
</script>

<!-- <script>
import { defineComponent } from 'vue'
import { Carousel, Slide,Navigation } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'ProductImage',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    currentSlide: 0,
     silder: [
              // "/assets/images/image-complex.jpg",
              // "/assets/images/abut-page-product.png",
              "/assets/images/gsh-image-1.jpg",
              "/assets/images/gsh-image-2.jpg",
              // "/assets/images/image-complex.jpg",
              // "/assets/images/abut-page-product.png",
              // "/assets/images/gsh-image-1.jpg",
              // "/assets/images/gsh-image-2.jpg",
              // "/assets/images/image-complex.jpg",
              // "/assets/images/abut-page-product.png",
              // "/assets/images/gsh-image-1.jpg",
              // "/assets/images/gsh-image-2.jpg",

              
        
        ],
        silderThumb: [
              // "/assets/images/image-complex-small.jpg",
              // "/assets/images/complex-thumb-img2.jpg",
              "/assets/images/gsh-image-1.jpg",
              "/assets/images/gsh-image-2.jpg",
              // "/assets/images/image-complex-small.jpg",
              // "/assets/images/complex-thumb-img2.jpg",
              // "/assets/images/gsh-image-1.jpg",
              // "/assets/images/gsh-image-2.jpg",
              // "/assets/images/image-complex-small.jpg",
              // "/assets/images/complex-thumb-img2.jpg",
              // "/assets/images/gsh-image-1.jpg",
              // "/assets/images/gsh-image-2.jpg",
        
        ],
  }),
  methods: {
    slideTo(val) {
      this.currentSlide = val
    },
  },
})
</script> -->
