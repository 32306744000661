<template>
    <div class="col-md-9 col-sm-8 col-xs-12">
      <ul class="breadcrumb mb-4" data-aos="fade-up">
        <li><a href="#">Home</a></li>
        <li>></li>
        <li>{{ translationText.text.changePassword }}</li>
      </ul>
      <div class="single-deta-box" data-aos="fade-up">
        <div class="d-flex justify-content-center">
          <div class="card card-1">
            <Form novalidate @submit="changePassword">
              <div class="card-body">
                <div class="row mt-4">
                  <div class="col">
                    <div class="row mb-3">
                      <div class="col-md-3">
                        <p class="mb-1"><b>{{ translationText.text.oldPasswordLabel }}</b></p>
                      </div>
                      <div class="col-md-6">
                        <Field type="password" name="oldPassword" rules="required" class="form-control" v-model="oldPassword"></Field>
                        <ErrorMessage name="oldPassword" />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-3">
                        <p class="mb-1"><b>{{ translationText.text.newPasswordLabel }}</b></p>
                      </div>
                      <div class="col-md-6">
                        <Field type="password" name="newPassword" rules="required" class="form-control" v-model="newPassword"></Field>
                        <ErrorMessage name="newPassword" />
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-9 offset-md-3">
                        <button type="submit" class="btn btn-primary">{{ translationText.text.changePassword }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script setup>
  import { ref, onMounted, watch } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import axios from 'axios';
  import { useLoginStore } from '../../stores/login';
  import { userLanguage } from '../../stores/language';
  import { Form, Field, ErrorMessage, defineRule } from 'vee-validate';
  import * as AllRules from '@vee-validate/rules';
  import { storeToRefs } from 'pinia';
  import swal from 'sweetalert';
  
  Object.keys(AllRules).forEach((rule) => {
    defineRule(rule, AllRules[rule]);
  });
  
  const router = useRoute();
  const userLogin = useLoginStore();
  const userLanguages = userLanguage();
  const { languageCode } = storeToRefs(userLanguages);
  const props = defineProps(['baseURL']);
  
  const oldPassword = ref('');
  const newPassword = ref('');
  
  const headers = {
    Authorization: 'Bearer ' + userLogin.loggedToken,
  };
  
  const changePassword = () => {
    userLanguages.setLoading(true);
    const payload = {
      old_password: oldPassword.value,
      new_password: newPassword.value,
    };
  
    axios
      .post(props.baseURL + 'user/change_password', payload, { headers })
      .then((response) => {
        userLanguages.setLoading(false);
        swal({
          position: 'top-end',
          icon: 'success',
          title: response.data.message,
          button: false,
          timer: 2500,
        });
      })
      .catch((error) => {
        userLanguages.setLoading(false);
        swal({
          position: 'top-end',
          icon: 'error',
          title: error.response.data.message,
          button: false,
          timer: 2500,
        });
        console.error('Error fetching data:', error);
      });
  };
  
  const translationTextEn = ref({
    text: {
      changePassword: 'Change Password',
      oldPasswordLabel: 'Enter your old password',
      newPasswordLabel: 'Enter your new password',
    },
    language: '',
  });
  
  const translationText = ref({
    text: {
      changePassword: 'Change Password',
      oldPasswordLabel: 'Enter your old password',
      newPasswordLabel: 'Enter your new password',
    },
    language: '',
  });
  
  const translatText = () => {
    translationText.value.language = userLanguages.getLanguageCode ? '/' + userLanguages.getLanguageCode : '';
  
    if (userLanguages.getLanguageCode != 'en') {
      const apiUrl = props.baseURL + 'translateany';
      axios
        .post(apiUrl, translationText.value)
        .then((response) => {
          console.log('response trnasate :', response.data);
          translationText.value = response.data;
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      translationText.value = translationTextEn.value;
    }
  };
  
  watch(languageCode, () => {
    console.log('ref sidebar language change');
    translatText();
  });
  
  onMounted(() => {
    translatText();
  });
  </script>
  <style scoped>
  /* Add your custom styles here */
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .rich-media-node {
    width: 80px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    background-color: #f7c616;
    border-radius: 4px;
  }
  </style>
  