<template>
  <template v-for="(product, index) in products.products">
    <div
      class="col-lg-4 col-md-4 col-6"
      data-aos="fade-up"
      v-if="product.display_shop != 'no'"
    >
      <div class="single-product-box">
        <div class="product-image">
          <img :src="product.thumbnail_url" alt="" class="img-responsive" />
          <img
            :src="product.images_url[0]"
            alt=""
            class="w-100 hover-gallery-image"
          />
          <!-- <a class="quick-view">QUICK VIEW</a> -->
          <router-link
            class="quick-view"
            :to="{ name: 'ShowDetails', params: { id: product.id } }"
            >{{ props.translationText.text.quickview }}</router-link
          >
        </div>
        <div class="product-dtls">
          <h3>
            <router-link
              :to="{ name: 'ShowDetails', params: { id: product.id } }"
              >{{ product.name }}</router-link
            >
          </h3>
          <div class="price">
                  <template
                    v-if="
                      product.sale_price !=
                      product.suggested_retail_price
                    "
                  >
                    <del>${{ product.sale_price }}</del>
                  </template>
                  ${{ product.suggested_retail_price }}                   
                </div>
          <!-- <p class="discount-price">For valued Scholar: $43.60</p> -->
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import {
  defineComponent,
  ref,
  onMounted,
  defineProps,
  defineEmits,
  watch,
} from "vue";
import { storeToRefs } from "pinia";
import axios from "axios";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";

const emit = defineEmits(["updateCount"]);
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const userLogin = useLoginStore();

const props = defineProps(["baseURL", "translationText"]);

const products = ref({
  products: [],
});
const allProducts = ref([
  {
    id: 1,
    name: "GSH Complex",
    price: "54.50",
    description: "Glutathione is the most powerful antioxidant of th",
    image: "/assets/images/product3.png",
    hoverImage: "/assets/images/hover-image3.jpg",
  },
  {
    id: 2,
    name: "Prezerv Derma",
    price: "54.50",
    description: "Glutathione is the most powerful antioxidant of th",
    image: "/assets/images/product1.png",
    hoverImage: "/assets/images/hover-image1.jpg",
  },
  {
    id: 3,
    name: "Triozyme Chewables",
    price: "59.95",
    description: "Glutathione is the most powerful antioxidant of th",
    image: "/assets/images/product7.png",
    hoverImage: "/assets/images/hover-image2.jpg",
  },
  {
    id: 4,
    name: "Une Vie",
    price: "29.95",
    description: "Glutathione is the most powerful antioxidant of th",
    image: "/assets/images/product8.png",
    hoverImage: "/assets/images/hover-image4.jpg",
  },
  {
    id: 5,
    name: "Prezerv Sliver",
    price: "157.40",
    description: "Glutathione is the most powerful antioxidant of th",
    image: "/assets/images/product5.png",
    hoverImage: "/assets/images/hover-image5.jpg",
  },
  {
    id: 6,
    name: "Prezerv Gold",
    price: "290.30",
    description: "Glutathione is the most powerful antioxidant of th",
    image: "/assets/images/product6.png",
    hoverImage: "/assets/images/hover-image6.jpg",
  },
  {
    id: 7,
    name: "Viprox",
    price: "235.80",
    description: "Glutathione is the most powerful antioxidant of th",
    image: "/assets/images/Viprox.png",
    hoverImage: "/assets/images/hover-image7.jpg",
  },
  {
    id: 8,
    name: "Viprox Junior",
    price: "162.95",
    description: "Glutathione is the most powerful antioxidant of th",
    image: "/assets/images/Viprox-Junior.png",
    hoverImage: "/assets/images/hover-image8.jpg",
  },
  {
    id: 9,
    name: "Prezerv Bronze",
    price: "102.90",
    description: "Glutathione is the most powerful antioxidant of th",
    image: "/assets/images/Prezerv-Bronze.png",
    hoverImage: "/assets/images/hover-image9.jpg",
  },
  {
    id: 10,
    name: "GSH Complex Special Offer",
    price: "72.95",
    description: "Glutathione is the most powerful antioxidant of th",
    image: "/assets/images/GSH-COMPLEX-Special-Offer.png",
    hoverImage: "/assets/images/hover-image3.jpg",
  },
]);

const fetchDataFromAPI = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const reqUserType = userLogin.checkUserType
    ? "/" + userLogin.checkUserType
    : "/" + "customer";
  const apiUrl = props.baseURL + "products" + reqUserType + languageUrl;
  //
  console.log("Product List api url apiUrl", apiUrl);
  axios
    .get(apiUrl)
    .then((response) => {
      products.value = response.data;
      console.log(response.data);
      console.log("count product", response.data.products.length);
      emit("updateCount", response.data.products.length);
      //console.log("product data",this.products.products.length);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
const onReady = async () => {
  this.$refs.youtube.playVideo();
};
watch(languageCode, () => {
  console.log("isLoggedIn ref changed, do something!");
  fetchDataFromAPI();
});
onMounted(() => {
  fetchDataFromAPI();
});
</script>

<style scoped></style>
