<template>
  <div class="banner inner-banner">
    <img :src="pageData.page_banner_url" alt="" class="w-100 zoom-out" />
    <div class="banner-text" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 text-start">
            <h1 v-html="pageData.name"></h1>
            <p v-html="pageData.page_subheading"></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-html="pageData.page_content"></div>

  <section class="team-sec" v-if="slugCms == 'about-us'">
    <div class="container">
        <div class="row justify-content-center mb-4">
            <div class="title col-lg-7 text-center mb-4" data-aos="fade-up">
                <h2>{{ translationText.text.meetTeam }}</h2>
            </div>
        </div>
        <div class="row white-areas">
                <div class="col-md-12 mb-4">
                    <h3>Leadership Team</h3>
                </div>
                <div class="col-md-4 col-sm-6 mb-4" v-for="leader in leadershipTeam" :key="leader.name">
                <div class="items">
                  <img :src="leader.image" :alt="leader.name">
                  <h5>{{ leader.name }}</h5>
                  <h6>{{ leader.position }}</h6>
                  <div class="social">
                    <a href="javascript:;">
                      <i class="ri-mail-fill"></i>
                    </a>
                    <a href="javascript:;" @click="openModal(leader)">
                      <i class="ri-profile-line"></i>
                    </a>
                  </div>
                </div>
              </div>
        </div>
        <div class="row white-areas">
            <div class="col-md-12 mb-4">
                <h3>Web Masters</h3>
            </div>
            <div class="col-md-4 col-sm-6 mb-4" v-for="webmaster in webMasters" :key="webmaster.name">
                <div class="items">
                  <img :src="webmaster.image" :alt="webmaster.name">
                  <h5>{{ webmaster.name }}</h5>
                  <h6>{{ webmaster.position }}</h6>
                  <div class="social">
                    <a href="javascript:;">
                      <i class="ri-mail-fill"></i>
                    </a>
                    <a href="javascript:;" @click="openModal(webmaster)">
                      <i class="ri-profile-line"></i>
                    </a>
                  </div>
                </div>
              </div>
        </div>
        <div class="row white-areas">
            <div class="col-md-12 mb-4">
                <h3>Sales and Marketing</h3>
            </div>
            <div class="col-md-4 col-sm-6 mb-4" v-for="salesMarketing in salesMarketings" :key="salesMarketing.name">
                <div class="items">
                  <img :src="salesMarketing.image" :alt="salesMarketing.name">
                  <h5>{{ salesMarketing.name }}</h5>
                  <h6>{{ salesMarketing.position }}</h6>
                  <div class="social">
                    <a href="javascript:;">
                      <i class="ri-mail-fill"></i>
                    </a>
                    <a href="javascript:;" @click="openModal(salesMarketing)">
                      <i class="ri-profile-line"></i>
                    </a>
                  </div>
                </div>
              </div>
        </div>
    </div>  
  </section>

  <div
    class="modal fade"
    id="myModalBio"
    tabindex="-1"
    aria-labelledby="cartModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog about-pop">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <img :src="selectedMember.image" :alt="selectedMember.name" />
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="ri-close-line"></i>
          </button>

          <h4 class="modal-title">{{ selectedMember.name }}</h4>
        </div>
        <div class="modal-body">
          <p v-html="selectedMember.bio"></p>
        </div>
        <div class="modal-footer">          
        </div>
      </div>
    </div>
  </div>

  <ProductListQuiz></ProductListQuiz>
</template>

<script setup>
import { defineComponent, ref, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import $ from "jquery";
import ProductListQuiz from "../components/Product/ProductListQuiz";
import { userLanguage } from "../stores/language";
import { useRoute } from "vue-router";

const route = useRoute();

let slugCms = route.params.slug;
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const props = defineProps(["baseURL"]);
const bioData = ref({
  name: "",
  description: "",
  image: "",
});
const settings = ref({
  itemsToShow: 1,
  //snapAlign: "center",
});
const silderBreakOptions = ref({
  // 700px and up
  767: {
    itemsToShow: 1,
    //snapAlign: "center",
  },
  // 1024 and up
  1024: {
    itemsToShow: 3,
    //snapAlign: "start",
  },
});
const pageData = ref({
  name: "",
  page_subheading: "",
  page_banner_url: "",
  page_content: "",
});


const selectedMember = ref({});

const openModal = (member) => {
  selectedMember.value = member;
  window.$("#myModalBio").modal("show");
};

const getLanguage = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl = props.baseURL + "page/" + slugCms + languageUrl;

  await axios
    .get(apiUrl)
    //.get(apiUrl)
    .then((res) => {
      console.log("response ", res.data);
      if (res.data.page_details.length > 0) {
        pageData.value = res.data.page_details[0];
      }

      //siteLanguage.value = res.data.languages
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {});
};

const leadershipTeam = ref([
  {
    name: 'ROBERT GAUTHIER',
    position: 'CEO, PRESIDENT',
    image: '/assets/images/teams/robert.png',
    bio: "My journey into health care began in 2000, when I learned the sobering statistic that 15M children were orphans of AIDS in Africa. It was unfathomable. In the age of modern medical, scientific and technological advancements, how is this even possible? I knew the sad truth that developing countries can’t afford the exorbitant costs associated with pharmaceutical drugs and don’t always have adequate access to health care professionals.",
    email: "r.gauthier@cymcorp.ca"
  },
  {
    name: 'MARISOL LOPEZ',
    position: 'VICE PRESIDENT / ADMIN & FINANCE',
    image: '/assets/images/teams/marisol.png',
    bio: "My journey into health care began in 2000, when I learned the sobering statistic that 15M children were orphans of AIDS in Africa. It was unfathomable. In the age of modern medical, scientific and technological advancements, how is this even possible? I knew the sad truth that developing countries can’t afford the exorbitant costs associated with pharmaceutical drugs and don’t always have adequate access to health care professionals.",
    email: "m.lopez@cymcorp.ca"
  },
  {
    name: 'WILLY TSAKU, MD',
    position: 'MEDICAL ADVISOR',
    image: '/assets/images/teams/willyt.png',
    bio: "My journey into health care began in 2000, when I learned the sobering statistic that 15M children were orphans of AIDS in Africa. It was unfathomable. In the age of modern medical, scientific and technological advancements, how is this even possible? I knew the sad truth that developing countries can’t afford the exorbitant costs associated with pharmaceutical drugs and don’t always have adequate access to health care professionals.",
    email: "#"
  }
]);

const webMasters = ref([
  {
    name: 'Chandradip Ghosh',
    position: 'Web Developer',
    image: '/assets/images/teams/cg.png',
    bio: "Coming Soon.",
    email: "#"
  },
  {
    name: 'Santosh Mahato',
    position: 'Web Developer',
    image: '/assets/images/teams/santosh.png',
    bio: "Coming Soon.",
    email: "#"
  }
]);

const salesMarketings = ref([
  {
    name: 'Koushik Karmakar',
    position: 'Digital Marketing Manager',
    image: '/assets/images/teams/koushik.png',
    bio: "Coming Soon.",
    email: "#"
  },
  {
    name: 'Bijoy Sengupta',
    position: 'Graphic & Video Editor',
    image: '/assets/images/teams/bijay.png',
    bio: "Coming Soon.",
    email: "#"
  },
  {
    name: 'Jaanvi Mukherjee',
    position: 'Social Media Manager',
    image: '/assets/images/teams/jaanvi.png',
    bio: "Coming Soon.",
    email: "#"
  },
  {
    name: 'Reema Ghosh',
    position: 'Graphic Designer',
    image: '/assets/images/teams/reema.png',
    bio: "Coming Soon.",
    email: "#"
  },
  {
    name: 'Jyoti Rai',
    position: 'Customer Support',
    image: '/assets/images/teams/jyoti.png',
    bio: "Coming Soon.",
    email: "#"
  }
]);



const translationTextEn = ref({
  text: {
    meetTeam: "Meet Our Team",
    description1: "VICE PRESIDENT / ADMIN & FINANCE",
    description2: "MEDICAL ADVISOR",
    description3: "CEO, PRESIDENT",
  },
  language: "",
});

const translationText = ref({
  text: {
    meetTeam: "Meet Our Team",
    description1: "VICE PRESIDENT / ADMIN & FINANCE",
    description2: "MEDICAL ADVISOR",
    description3: "CEO, PRESIDENT",
  },
  language: "",
});

const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if(userLanguages.getLanguageCode != 'en'){
    const apiUrl = props.baseURL + "translateany";
    axios
    .post(apiUrl, translationText.value)
    .then((response) => {
      console.log("response trnasate :", response.data);
      translationText.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

  }else{

    translationText.value = translationTextEn.value;
  }
  
};

watch(languageCode, () => {
  console.log("isLoggedIn ref changed, do something!");
  translatText();
  getLanguage();
});

onMounted(() => {
  translatText();
  getLanguage();

  //const container = document.querySelector('.faq-accordion-title');
  // document
  //   .querySelector(".faq-accordion-title")
  //   .addEventListener("click", (e) => {
  //     alert("ddd");
  //     // container.style.setProperty('--position', `${e.target.value}%`);
  //   });
});

// route.beforeRouteEnter(to, from, next) {
//     console.log(to.params.slug);
// }

watch(route, (to, from) => {
  slugCms = to.params.slug;
  getLanguage();
  console.log("new slug", to.params.slug);
});
</script>
