<template>
  <section class="team-body" style="background: #fff">
    <div class="container-fluid">
      <div class="row mb-5" data-aos="fade-up">
        <div class="col-md-12">
          <ul class="breadcrumb">
            <li><router-link to="/">Home</router-link></li>
            <li>></li>
            <li>Scholar {{ translationText.text.enrollment }}</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mb-3">
          <!-- <h2>Your Enrolling Sponsor will be: CYMCORP International Company Name: Cymcorp Scholar/Ambassador ID: 100000</h2> -->
          <div class="team-box">
            <div class="team-image">
              <img
                src="/assets/images/team-image2.jpg"
                alt=""
                class="img-responsive"
              />
            </div>
            <div class="team-dtls">
              <div v-html="translationText.text.asScholartext"></div>
            </div>
          </div>
        </div>

        <div class="col-lg-8">
          <Form novalidate @submit="signin" class="pt-4 pl-4 pr-4">
            <div class="content-box">
              <div class="label-heading">
                <p style="text-align: center">
                  <b
                    >Your Enrolling Sponsor will be:
                    {{ userInfo.enrolling_sponsor_name }} ({{
                      userInfo.user_type
                    }}
                    ID: {{ userInfo.id }})</b
                  >
                </p>
                <h4>
                  <img src="/assets/images/edit-icon.png" /> Scholar
                  {{ translationText.text.information }}
                </h4>
                <span class="green-icon"
                  ><img src="/assets/images/check-full.png"
                /></span>
              </div>
              <div class="content-inputs">
                <div class="row">
                  <div class="form-group col-md-6">
                    <Field
                      type="text"
                      v-model="email"
                      name="email"
                      class="form-control"
                      rules="email|required"
                      :placeholder="translationText.text.emailLabel"
                    />
                    <ErrorMessage name="email" />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="field">
                      <span class="field-label" for="country"
                        >{{ translationText.text.countryLabel }} *</span
                      >
                      <Field
                        rules="required"
                        v-model="countryForm"
                        as="select"
                        class="field-input form-control"
                        name="countryForm"
                        id="countryForm"
                      >
                        <option value="" disabled>
                          {{ translationText.text.contrySelectLabel }}
                        </option>
                        <template v-if="allCountriesForm.length > 0">
                          <template
                            v-for="(allCountry, index) in allCountriesForm"
                          >
                            <option :value="allCountry.id">
                              {{ allCountry.name }}
                            </option>
                          </template>
                        </template>
                      </Field>
                      <ErrorMessage name="countryForm" />
                    </label>
                  </div>
                  <div class="form-group col-md-12">
                    <Field
                      type="text"
                      v-model="userName"
                      name="userName"
                      class="form-control"
                      rules="required"
                      :placeholder="translationText.text.ambassadorLabel"
                    />
                    <p v-if="userName != ''" style="color: blue">
                      cymcorp.us/{{ userName }}
                    </p>
                    <ErrorMessage name="userName" />

                    <p>
                      {{ translationText.text.formSubText }}
                    </p>
                  </div>
                  <div class="form-group col-md-6">
                    <div class="password-field">
                      <Field
                        v-if="showPassword"
                        v-model="password"
                        type="text"
                        name="password"
                        rules="required"
                        class="form-control"
                        :placeholder="translationText.text.passwordLabel"
                      ></Field>
                      <Field
                        v-else
                        v-model="password"
                        type="password"
                        name="password"
                        rules="required"
                        class="form-control"
                        :placeholder="translationText.text.passwordLabel"
                      ></Field>
                      <span @click="toggleShow">
                        <!-- <img src="/assets/images/eye.png"> -->

                        <i
                          class="ri-eye-line"
                          :class="{
                            'ri-eye-line': showPassword,
                            'ri-eye-off-line': !showPassword,
                          }"
                        ></i>
                      </span>
                      <ErrorMessage name="password" />
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <div class="password-field">
                      <Field
                        rules="confirmed:@password"
                        name="confirmed"
                        type="password"
                        class="form-control"
                        :placeholder="translationText.text.confirmPasswordLabel"
                      ></Field>
                      <!-- <span><img src="/assets/images/eye.png"></span> -->
                      <ErrorMessage name="confirmed" />
                    </div>
                  </div>
                  <div class="form-group col-md-12">
                    <h5>{{ translationText.text.strengthLabel }}</h5>
                    <password-meter @score="onScore" :password="password" />
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      rules="required"
                      as="select"
                      class="field-input form-control"
                      name="security_question"
                      id="security_question"
                      v-model="security_question"
                    >
                      <option value="" disabled>
                        {{ translationText.text.securityLabel }}
                      </option>
                      <template v-if="securityQuestions.length > 0">
                        <template
                          v-for="(securityQuestion, index) in securityQuestions"
                        >
                          <option :value="securityQuestion">
                            {{ securityQuestion }}
                          </option>
                        </template>
                      </template>
                    </Field>
                    <ErrorMessage name="security_question" />
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      v-model="answer"
                      type="text"
                      name="answer"
                      class="form-control"
                      rules="required"
                      :placeholder="translationText.text.answerLabel"
                    />
                    <ErrorMessage name="answer" />
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      rules="required"
                      as="select"
                      v-model="personal_tax_id"
                      class="field-input form-control"
                      name="personal_tax_id"
                      id="personal_tax_id"
                    >
                      <option value="" disabled>
                        {{ translationText.text.selectLabel }}
                      </option>
                      <template v-if="personalTaxForm.length > 0">
                        <template
                          v-for="(personalTaxData, index) in personalTaxForm"
                        >
                          <option :value="personalTaxData">
                            {{ personalTaxData }}
                          </option>
                        </template>
                      </template>
                    </Field>
                    <!-- <Field  type="text" name="personal_tax_id"  class="form-control" rules="required" placeholder="Personal Tax ID/SSN" /> -->

                    <ErrorMessage name="personal_tax_id" />
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      v-model="ssn"
                      type="text"
                      name="SSN"
                      class="form-control"
                      rules="required|numeric"
                      :placeholder="translationText.text.SSNLabel"
                    />
                    <ErrorMessage name="SSN" />
                  </div>
                  <div class="form-group col-md-6">
                    <h5>{{ translationText.text.languageLabel }}</h5>
                    <Field
                      rules="required"
                      as="select"
                      class="field-input form-control"
                      name="language_form"
                      id="language_form"
                      v-model="language_form"
                    >
                      <option value="" disabled>
                        {{ translationText.text.languageLabel }}
                      </option>
                      <template v-if="languageDate.length > 0">
                        <template v-for="(languageData, index) in languageDate">
                          <option :value="languageData">
                            {{ languageData }}
                          </option>
                        </template>
                      </template>
                    </Field>
                    <ErrorMessage name="language_form" />
                  </div>
                  <div class="form-group col-md-6">
                    <h5>{{ translationText.text.currencyLabel }}</h5>
                    <Field
                      rules="required"
                      as="select"
                      class="field-input form-control"
                      name="currency_form"
                      id="currency_form"
                      v-model="currency_form"
                    >
                      <option value="" disabled>
                        {{ translationText.text.currencyLabel }}
                      </option>
                      <template v-if="currencyData.length > 0">
                        <template v-for="(currency, index) in currencyData">
                          <option :value="currency.code">
                            {{ currency.name }}
                          </option>
                        </template>
                      </template>
                    </Field>
                    <ErrorMessage name="currency_form" />
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      v-model="firstName"
                      rules="required"
                      type="text"
                      name="first_name"
                      class="form-control"
                      :placeholder="translationText.text.firstNameLabel"
                    />
                    <ErrorMessage name="first_name" />
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      v-model="lastName"
                      rules="required"
                      type="text"
                      name="last_name"
                      class="form-control"
                      :placeholder="translationText.text.lastNameLabel"
                    />
                    <ErrorMessage name="last_name" />
                  </div>
                  <div class="form-group col-md-12">
                    <Field
                      v-model="phoneNumber"
                      type="number"
                      name="phone"
                      rules="required"
                      class="form-control"
                      :placeholder="translationText.text.phoneLabel"
                    ></Field>
                    <ErrorMessage name="phone" />
                  </div>
                  <div class="form-group col-md-5">
                    <h5>{{ translationText.text.birthLabel }}</h5>
                    <Field
                      rules="required"
                      as="select"
                      class="field-input form-control"
                      name="date_month"
                      id="date_month"
                      v-model="dateMonthForm"
                    >
                      <option value="" disabled>
                        {{ translationText.text.monthSelectLabel }}
                      </option>
                      <template v-if="dateMonth.length > 0">
                        <template v-for="(dateMonthData, index) in dateMonth">
                          <option :value="dateMonthData.id">
                            {{ dateMonthData.name }}
                          </option>
                        </template>
                      </template>
                    </Field>
                    <ErrorMessage name="date_month" />
                  </div>
                  <div class="form-group col-md-4">
                    <h5>&nbsp;</h5>
                    <Field
                      rules="required"
                      as="select"
                      class="field-input form-control"
                      name="date_day"
                      id="date_day"
                      v-model="dateDay"
                    >
                      <option value="" disabled>
                        {{ translationText.text.dateSelectLabel }}
                      </option>
                      <template v-for="dateDayData in dayDataLoop">
                        <option :value="dateDayData">{{ dateDayData }}</option>
                      </template>
                    </Field>
                    <ErrorMessage name="date_day" />
                  </div>
                  <div class="form-group col-md-3">
                    <h5>&nbsp;</h5>
                    <Field
                      rules="required"
                      as="select"
                      class="field-input form-control"
                      name="date_year"
                      id="date_year"
                      v-model="dateYear"
                    >
                      <option value="" disabled>
                        {{ translationText.text.yearSelectLabel }}
                      </option>
                      <template v-for="dateDayData in 2024">
                        <template v-if="dateDayData > 1950">
                          <option :value="dateDayData">
                            {{ dateDayData }}
                          </option>
                        </template>
                      </template>
                    </Field>
                    <ErrorMessage name="date_year" />
                  </div>
                  <div class="form-group col-12">
                    <div class="custome_checkbox">
                      <input type="checkbox" id="ambasador-info" rows="1" />
                      <label for="ambasador-info">{{
                        translationText.text.yesSendLabel
                      }}</label>
                    </div>
                  </div>
                </div>
                <!-- <a href="javascript: ;" class="btn btn-dark rc">CONTINUE</a> -->
              </div>
            </div>

            <div class="content-box">
              <div class="label-heading">
                <h4>
                  <img src="/assets/images/edit-icon.png" />
                  {{ translationText.text.enrollmentSummaryLabel }}
                </h4>
                <span class="green-icon"
                  ><img src="/assets/images/check-full.png"
                /></span>
              </div>
              <div class="content-inputs">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <h5>
                      {{ translationText.text.countryBillillingAddressLabel }}
                    </h5>
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      type="text"
                      name="bill_address"
                      rules="required"
                      class="form-control"
                      v-model="bill_address"
                      :placeholder="translationText.text.address1Label"
                    ></Field>
                    <ErrorMessage name="bill_address" />
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      type="text"
                      name="bill_address2"
                      class="form-control"
                      v-model="bill_address2"
                      :placeholder="translationText.text.address2Label"
                    ></Field>
                    <ErrorMessage name="bill_address2" />
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      type="text"
                      name="city"
                      rules="required"
                      class="form-control"
                      v-model="bill_city"
                      :placeholder="translationText.text.cityLabel"
                    ></Field>
                    <ErrorMessage name="city" />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="field">
                      <span class="field-label" for="country"
                        >{{ translationText.text.countryLabel }} *</span
                      >
                      <Field
                        rules="required"
                        v-model="countryBill"
                        as="select"
                        class="field-input form-control"
                        name="countryBill"
                        id="countryBill"
                      >
                        <template v-if="allCountriesBill.length > 0">
                          <template
                            v-for="(allCountry, index) in allCountriesBill"
                          >
                            <option :value="allCountry.id">
                              {{ allCountry.name }}
                            </option>
                          </template>
                        </template>
                      </Field>
                      <ErrorMessage name="countryBill" />
                    </label>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="field">
                      <span class="field-label" for="country">{{
                        translationText.text.stateLabel
                      }}</span>
                      <Field
                        as="select"
                        rules="required"
                        v-model="stateBill"
                        class="field-input form-control"
                        name="stateBill"
                        id="stateBill"
                      >
                        <template v-if="allStatesBill.length > 0">
                          <template v-for="(allState, index) in allStatesBill">
                            <option :value="allState.id">
                              {{ allState.name }}
                            </option>
                          </template>
                        </template>
                      </Field>
                      <ErrorMessage name="stateBill" />
                    </label>
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      type="text"
                      name="bil_zipcode"
                      rules="required"
                      class="form-control"
                      v-model="bil_zipcode"
                      :placeholder="translationText.text.zipcode"
                    ></Field>
                    <ErrorMessage name="bil_zipcode" />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-12">
                    <h5>{{ translationText.text.shippingAddressLabel }}</h5>
                  </div>
                  <div class="form-group col-12">
                    <div class="custome_checkbox">
                      <input
                        v-model="sameAsBilling"
                        type="checkbox"
                        id="billing-address"
                        rows="1"
                        value="true"
                      />
                      <label for="billing-address">{{
                        translationText.text.sameAsBillingAddress
                      }}</label>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      type="text"
                      name="ship_address"
                      rules="required"
                      class="form-control"
                      v-model="ship_address"
                      :placeholder="translationText.text.address1Label"
                    ></Field>
                    <ErrorMessage name="ship_address" />
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      type="text"
                      name="ship_address2"
                      class="form-control"
                      v-model="ship_address2"
                      :placeholder="translationText.text.address2Label"
                    ></Field>
                    <ErrorMessage name="ship_address2" />
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      type="text"
                      name="ship_city"
                      rules="required"
                      class="form-control"
                      v-model="ship_city"
                      :placeholder="translationText.text.cityLabel"
                    ></Field>
                    <ErrorMessage name="ship_city" />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="field">
                      <span class="field-label" for="country"
                        >{{ translationText.text.countryLabel }} *</span
                      >
                      <Field
                        rules="required"
                        v-model="country"
                        as="select"
                        class="field-input form-control"
                        name="country"
                        id="country"
                      >
                        <template v-if="allCountries.length > 0">
                          <template v-for="(allCountry, index) in allCountries">
                            <option :value="allCountry.id">
                              {{ allCountry.name }}
                            </option>
                          </template>
                        </template>
                      </Field>
                      <ErrorMessage name="country" />
                    </label>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="field">
                      <span class="field-label" for="country">{{
                        translationText.text.stateLabel1
                      }}</span>
                      <Field
                        as="select"
                        rules="required"
                        v-model="state_ship"
                        class="field-input form-control"
                        name="state"
                        id="state"
                      >
                        <template v-if="allStates.length > 0">
                          <template v-for="(allState, index) in allStates">
                            <option :value="allState.id">
                              {{ allState.name }}
                            </option>
                          </template>
                        </template>
                      </Field>
                      <ErrorMessage name="state" />
                    </label>
                  </div>
                  <div class="form-group col-md-6">
                    <Field
                      type="text"
                      name="ship_zipcode"
                      rules="required"
                      class="form-control"
                      v-model="ship_zipcode"
                      :placeholder="translationText.text.zipcode"
                    ></Field>
                    <ErrorMessage name="ship_zipcode" />
                  </div>
                </div>
                <div class="row payments mb-3">
                  <div class="col-md-12">
                    <h5>Payment Method</h5>

                    <StripeElements
                      v-if="stripeLoaded"
                      v-slot="{ elements, instance }"
                      ref="elms"
                      :stripe-key="stripeKey"
                      :instance-options="instanceOptions"
                      :elements-options="elementsOptions"
                    >
                      <div class="row">
                        <div class="col-12 form-group">
                          <label>{{ translationText.text.cardNumber }}</label>
                          <div class="input-icon">
                            <StripeElement
                              ref="card"
                              type="cardNumber"
                              :elements="elements"
                              v-model="cardNumber"
                            />
                            <img
                              src="/assets/images/cards.png"
                              alt=""
                              class="icon"
                            />
                          </div>
                        </div>

                        <div class="col-md-8">
                          <div class="form-group">
                            <label for="">{{
                              translationText.text.expiration
                            }}</label>
                            <StripeElement
                              ref="card"
                              type="cardExpiry"
                              :elements="elements"
                              v-model="cardExpiry"
                            />
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for=""
                              >{{ translationText.text.cvv }}
                              {{ paymentParentNextBtn }}</label
                            >
                            <div class="input-icon">
                              <StripeElement
                                ref="card"
                                type="cardCvc"
                                :elements="elements"
                                v-model="cardCvc"
                              />

                              <img src="img/cvv.png" alt="" class="icon" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </StripeElements>
                  </div>
                </div>

                <div class="row pro-selection mb-3">
                  <div class="col-md-12">
                    <h5>PRODUCT(S) SELECTED</h5>
                    <table>
                      <tr>
                        <th>Product(s) Selected</th>
                        <!-- <th>Qty.</th> -->
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="radio"
                            id="monthly"
                            name="Fee"
                            v-model="subscriptionType"
                            value="monthly"
                          />
                          Monthly Website Fee
                        </td>
                        <!-- <td v-if="subscriptionTypeMonthly">
                          <div class="wrapper_qty">
                            <button
                              type="button"
                              @click="decreaseQty"
                              class="cclwminus"
                            >
                              -
                            </button>
                            {{ subscriptionQty }}
                            <button
                              type="button"
                              @click="increaseQty"
                              class="cclwplus"
                            >
                              +
                            </button>
                          </div>
                        </td> -->
                        <!-- <td v-else></td> -->
                        <td>$10.00</td>
                        <td v-if="subscriptionTypeMonthly">${{ total }}</td>
                        <td v-else></td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="radio"
                            id="Annual"
                            name="Fee"
                            v-model="subscriptionType"
                            value="Annual"
                          />
                          Annual Website Fee(20% Off)
                        </td>
                        <!-- <td v-if="subscriptionTypeAnnual">
                          <div class="wrapper_qty">
                            <button
                              type="button"
                              @click="decreaseQty"
                              class="cclwminus"
                            >
                              -
                            </button>
                            {{ subscriptionQty }}
                            <button
                              type="button"
                              @click="increaseQty"
                              class="cclwplus"
                            >
                              +
                            </button>
                          </div>
                        </td> -->
                        <!-- <td v-else></td> -->
                        <td>$99.00</td>
                        <td v-if="subscriptionTypeAnnual">${{ total }}</td>
                        <td v-else></td>
                      </tr>
                    </table>
                    <div class="totals-budget">
                      <p>
                        <span class="left"> <b>Total:</b> </span>
                        <span class="right">
                          <b> ${{ total }} </b>
                        </span>
                      </p>
                      <!--    <p>
                        <span class="left"> Subtotal: </span>
                        <span class="right"> ${{ total }} </span>
                      </p>
                      <p>
                        <span class="left"> Tax: </span>
                        <span class="right"> $0.00 </span>
                      </p>
                      <p>
                        <span class="left"> Coupon Saving: </span>
                        <span class="right"> $0.00 </span>
                      </p>
                      <p>
                        <span class="left"> Shipping: </span>
                        <span class="right"> $0.00 </span>
                      </p>
                      <p>
                        <span class="left"> <b>Total:</b> </span>
                        <span class="right">
                          <b> ${{ total }} </b>
                        </span>
                      </p> -->
                    </div>
                  </div>
                </div>

                <!-- <div class="row pro-selection mb-3">
                  <div class="col-md-12">
                    <h5>AUTOSHIP(S) SELECTED</h5>
                    <table>
                      <tr>
                        <th>AutoShip(S) Selected</th>
                        <th>Qty.</th>
                        <th>Next Order</th>
                        <th>Total</th>
                      </tr>
                      <tr>
                        <td>Monthly Website Fee</td>

                        <td>$10.00</td>
                        <td>$10.00</td>
                        <td>$10.00</td>
                      </tr>
                      <tr>
                        <td>Annual Website Fee</td>

                        <td>$99.00</td>
                        <td>$99.00</td>
                        <td>$10.00</td>
                      </tr>
                    </table>
                    
                  </div>
                </div> -->

                <!-- <a href="javascript: ;" class="btn btn-dark rc">CONTINUE</a> -->
              </div>
            </div>

            <div class="content-box">
              <div class="label-heading">
                <h4>
                  <img src="/assets/images/edit-icon.png" />
                  {{ translationText.text.agreements }}
                </h4>
                <span class="green-icon"
                  ><img src="/assets/images/check-full.png"
                /></span>
              </div>
              <div class="content-inputs">
                <div class="row mb-3">
                  <div class="form-group flex-from-group col-12">
                    <Field
                      name="terms"
                      type="checkbox"
                      :value="true"
                      rules="required"
                    />
                    <div class="custome_checkbox no-checks-box">
                      <label for="terms"
                        ><router-link to="/terms" target="_blank"
                          >{{ translationText.text.termsText }} </router-link
                        >:
                        {{ translationText.text.termsTextContent }}
                        +1888-968-3592</label
                      >
                      <ErrorMessage name="terms" />
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-dark rc">
                  {{ translationText.text.signUp }}
                </button>
                <!-- <a href="javascript: ;" class="btn btn-dark rc">CONTINUE</a> -->
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </section>
  <ProductListQuiz></ProductListQuiz>
</template>

<script>
import {
  defineComponent,
  ref,
  onBeforeMount,
  onMounted,
  computed,
  defineProps,
  watch,
  onUpdated,
} from "vue";
import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";
import { mapState, storeToRefs } from "pinia";
import { useLoginStore } from "../stores/login";
import { userLanguage } from "../stores/language";
import { useCartStore } from "../stores/cart";
import { useRouter } from "vue-router";
import PasswordMeter from "vue-simple-password-meter";
import * as AllRules from "@vee-validate/rules";
import ProductListQuiz from "../components/Product/ProductListQuiz";
import { localize } from "@vee-validate/i18n";
import { StripeElements, StripeElement, createElement } from "vue-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import swal from "sweetalert";
export default defineComponent({
  name: "CardOnly",
  //props: ["paymentParentNextBtn", "translationText"],
  components: {
    StripeElements,
    StripeElement,
    createElement,
    Form,
    Field,
    ErrorMessage,
    defineRule,
    configure,
    PasswordMeter,
  },
  //const router = useRouter();

  props: ["paymentParentNextBtn", "translationText", "baseURL"],
  data() {
    return {
      paymentBtn: false,
      //bar: { name: "hi" }
    };
  },

  setup(props) {
    const userInfo = ref({
      enrolling_sponsor_name: "",
      id: "",
      user_type: "",
    });
    //const props = defineProps(["translationText"]);
    const paymentBtn = ref(false);
    const stripeKey = ref(`${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`); // test key
    const instanceOptions = ref({
      // https://stripe.com/docs/js/initializing#init_stripe_js-options
    });
    const elementsOptions = ref({
      // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
    });
    const cardOptions = ref({
      // https://stripe.com/docs/stripe.js#element-options
      value: {
        postalCode: "12345",
      },
    });

    const firstName = ref("");
    const lastName = ref("");
    const security_question = ref("");
    const answer = ref("");
    const ssn = ref("");
    const dateMonthForm = ref("");
    const dateDay = ref("");
    const dateYear = ref("");

    const subscriptionType = ref("monthly");
    const subscriptionTypeMonthly = ref(true);
    const subscriptionTypeAnnual = ref(false);
    const subscriptionQty = ref(1);
    const total = ref(10);
    const stripeLoaded = ref(false);
    const card = ref();
    const elms = ref();
    const cardNumber = ref();
    const cardExpiry = ref();
    const cardCvc = ref();
    const cartData = useCartStore();
    const language_form = ref("");

    Object.keys(AllRules).forEach((rule) => {
      defineRule(rule, AllRules[rule]);
    });

    const userLanguages = userLanguage();
    const { languageCode } = storeToRefs(userLanguages);
    const router = useRouter();
    const userLogin = useLoginStore();
    let loading = ref(0);
    const password = ref("");
    const email = ref("");
    //const props1 = defineProps(["baseURL"]);
    // const props = ref({
    //   baseURL: process.env.VUE_APP_APP_API_URL,
    // });
    //console.log("props fffffffffff ", props);
    //process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    const allCountries = ref([]);
    const country = ref();
    const state = ref();
    const allStates = ref([]);
    const phoneNumber = ref("");
    const userName = ref("");
    const state_ship = ref("");

    const countryForm = ref("");
    const allCountriesForm = ref([]);
    const showPassword = ref(false);
    const personal_tax_id = ref("Personal Tax ID/SSN");
    const personalTaxForm = ref(["Personal Tax ID/SSN", "Business Tax ID"]);

    const languageDate = ref(["English", "French"]);

    const currencyData = ref([
      { name: "Canadian Dollar", code: "CAD" },
      { name: "US Dollar", code: "USD" },
    ]);
    const dateMonth = ref([
      { id: "01", name: "January" },
      { id: "02", name: "February" },
      { id: "03", name: "March" },
      { id: "04", name: "April" },
      { id: "05", name: "May" },
      { id: "06", name: "June" },
      { id: "07", name: "July" },
      { id: "08", name: "August" },
      { id: "09", name: "September" },
      { id: "10", name: "October" },
      { id: "11", name: "November" },
      { id: "12", name: "December" },
    ]);

    const dayDataLoop = ref([
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ]);
    // New Country
    configure({
      generateMessage: localize({
        en: {
          messages: {
            required: "This field is required",
          },
          fields: {
            email: {
              required: "Email address is required",
              email: "Please enter a valid email address.",
            },
            password: {
              required: "Password is required",
            },
            country: {
              required: "Select country",
            },
            state: {
              required: "Select state",
            },
          },
        },
      }),
    });
    const allCountriesBill = ref([]);
    const countryBill = ref();
    const stateBill = ref();
    const allStatesBill = ref([]);
    const passwordStrength = ref();
    // Ends here
    const isPasswordStrong = ref(false);
    const sameAsBilling = ref(false);

    const ship_address = ref();
    const ship_address2 = ref();
    const ship_city = ref();
    const ship_zipcode = ref();

    const bill_address = ref();
    const bill_address2 = ref();
    const bill_city = ref();
    const bil_zipcode = ref();
    const currency_form = ref("");

    const securityQuestions = ref([
      "In what city was your mother born?",
      "What was the name of your favorite teacher?",
      "What was the name of your childhood best friend?",
      "What is the name of the company of your first job?",
      "What is the last four digits of the credit card you used?",
      "What was the brand of your first car?",
      "What is your father's first name?",
      "What is your favorite hobby?",
      "What is your Facebook username?",
      "What is your mother's middle name?",
      "What year did you graduate from High School?",
      "What is your favorite holiday?",
      "What is your favorite color?",
      "What street did you grow up on?",
      "What is your preferred musical genre?",
      "What is your favorite sports team?",
    ]);

    const getUerInfo = async () => {
      //if (userLogin.getUserUrlName != null) {

      const userUrl = userLogin.getUserUrlName
        ? "/" + userLogin.getUserUrlName
        : "";
      const apiUrl = props.baseURL + "get-user-info" + userUrl;
      axios
        .get(apiUrl)
        .then((response) => {
          userInfo.value = response.data;
          //userLogin.restUserUrlName();
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      // } else {
      //   router.push({ name: "Home" });
      // }
    };

    const score = ref(null);
    const onScore = (payload) => {
      console.log(payload.score); // from 0 to 4
      console.log(payload.strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure'
      passwordStrength.value = payload.strength;
      score.value = payload.score;
    };

    //console.log("prpos ", props.baseURL.value);
    const toggleShow = () => {
      showPassword.value = !showPassword.value;
      console.log("showPassword.value", showPassword.value);
    };

    const decreaseQty = () => {
      if (subscriptionQty.value == 1) {
        if (subscriptionType.value == "monthly") {
          total.value = subscriptionQty.value * 10;
        } else {
          total.value = subscriptionQty.value * 99;
        }
        swal({
          position: "top-end",
          icon: "error",
          title: "Quantity can not be zero.",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        subscriptionQty.value -= 1;
        if (subscriptionType.value == "monthly") {
          total.value = subscriptionQty.value * 10;
        } else {
          total.value = subscriptionQty.value * 99;
        }
      }
    };

    const increaseQty = () => {
      subscriptionQty.value += 1;
      if (subscriptionType.value == "monthly") {
        total.value = subscriptionQty.value * 10;
      } else {
        total.value = subscriptionQty.value * 99;
      }
    };

    const validateEmail = (value) => {
      // if the field is empty
      if (!value) {
        return translationText.text.reuiredMsg;
      }
      // if the field is not a valid email
      // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      // if (!regex.test(value)) {
      //   return 'This field must be a valid email';
      // }
      // All is good
      return true;
    };

    const validatePassword = (value) => {
      // if the field is empty
      if (!value) {
        return "This field is required";
      }

      // All is good
      return true;
    };

    const signin = async () => {
      const cardElement = card.value.stripeElement;

      elms.value.instance
        .createPaymentMethod("card", cardElement)
        .then((result) => {
          // Handle result.error or result.token
          console.log(result);
          if (result.error) {
            swal({
              position: "top-end",
              icon: "error",
              title: result.error.message,
              button: false,
              timer: 1500,
            });
          } else {
            let tokenData = result.paymentMethod.id;

            const userCountry = ref("");
            if (userLogin.getUserCountry == "Canada") {
              userCountry.value = "ca";
            } else {
              userCountry.value = "usa";
            }
            let totalMonths = 0;
            if (subscriptionType.value == "monthly") {
              totalMonths = 1;
            } else {
              totalMonths = 12;
            }
            const payloadData = ref({
              referal_id: userLogin.getReferalId,
              firstname: firstName.value,
              lastname: lastName.value,
              email: email.value,
              password: password.value,
              username: userName.value,
              usertype: "scholar",
              mobile: phoneNumber.value,
              shopping_country_code: userCountry.value,
              secret_question: security_question.value,
              answer: answer.value,
              personal_tax_id: personal_tax_id.value,
              ssn: ssn.value,
              language: language_form.value,
              currency: currency_form.value,
              date_month: dateMonthForm.value,
              date_day: dateDay.value,
              date_year: dateYear.value,
              bill_address: bill_address.value,
              bill_address2: bill_address2.value,
              city: bill_city.value,
              countryBill: countryBill.value,
              stateBill: stateBill.value,
              bil_zipcode: bil_zipcode.value,
              same_billing: sameAsBilling.value,
              ship_address: ship_address.value,
              ship_address2: ship_address2.value,
              ship_city: ship_city.value,
              country: country.value,
              state: state_ship.value,
              ship_zipcode: ship_zipcode.value,
              authorization_key: tokenData,
              plan: totalMonths, // Moths 1 or 12 for
            });

            const apiUrl = props.baseURL + "register";
            axios
              .post(apiUrl, payloadData.value)
              .then((response) => {
                console.log("response trnasate :", response.data);
                if (response.data.access_token) {
                  swal({
                    position: "top-end",
                    icon: "success",
                    title: response.data.message,
                    button: false,
                    timer: 2500,
                  });
                  userLogin.userLoggedIn(response.data);
                  if (userLogin.getUserUrlName != null) {
                    router.push({
                      name: "DashboardUser",
                      params: { sluguser: userLogin.getUserUrlName },
                    });
                  } else {
                    router.push({ name: "Dashboard" });
                  }
                } else {
                  swal({
                    position: "top-end",
                    icon: "error",
                    title: response.data.error,
                    button: false,
                    timer: 2500,
                  });
                  //userLogin.addUserUrlName(userName.value);
                  //router.push({ name: "Dashboard" });
                }

                //translationText.value = response.data;
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
              });

            console.log("payloadData", payloadData);
          }
        });

      //router.push({ name: "ThankYou" });
    };
    const acceptNumber = (value) => {
      var x = phoneNumber.value
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      phoneNumber.value = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    };

    const getCountry = async () => {
      axios
        .get(props.baseURL + "countries")
        .then((response) => {
          allCountries.value = response.data.countries;
          allCountriesBill.value = response.data.countries;
          allCountriesForm.value = response.data.countries;
          //console.log("country  data", response.data.countries);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    // watch(state, (newValue, oldValue) => {
    //   alert("d");
    // });
    watch(subscriptionType, (newValue, oldValue) => {
      //alert("subscriptionType");
      if (newValue == "monthly") {
        console.log("monthly");
        subscriptionTypeMonthly.value = true;
        subscriptionTypeAnnual.value = false;
        if (subscriptionType.value == "monthly") {
          total.value = subscriptionQty.value * 10;
        } else {
          total.value = subscriptionQty.value * 99;
        }
      } else {
        console.log("annual");
        subscriptionTypeMonthly.value = false;
        subscriptionTypeAnnual.value = true;
        if (subscriptionType.value == "monthly") {
          total.value = subscriptionQty.value * 10;
        } else {
          total.value = subscriptionQty.value * 99;
        }
      }
    });
    watch(country, (newValue, oldValue) => {
      axios
        .get(props.baseURL + "states/" + newValue)
        .then((response) => {
          allStates.value = response.data.states;
          console.log("states  data ggg ", response.data.states);

          if (sameAsBilling.value == true) {
            console.log("fffffffffffffffffff", stateBill.value);
            console.log("state.value ", stateBill.value);
            state_ship.value = stateBill.value;
            // setTimeout(() => {
            //   state_ship.value = stateBill.value;
            // }, 2000);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    });
    watch(countryBill, (newValue, oldValue) => {
      axios
        .get(props.baseURL + "states/" + newValue)
        .then((response) => {
          allStatesBill.value = response.data.states;
          console.log("states  data", response.data.states);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    });

    watch(sameAsBilling, (newValue, oldValue) => {
      if (sameAsBilling.value == true) {
        console.log("sameAsBilling", sameAsBilling.value);
        ship_address.value = bill_address.value;
        ship_address2.value = bill_address2.value;
        ship_city.value = bill_city.value;
        ship_zipcode.value = bil_zipcode.value;
        country.value = countryBill.value;
        state.value = stateBill.value;
      } else {
        ship_address.value = "";
        ship_address2.value = "";
        ship_city.value = "";
        ship_zipcode.value = "";
        console.log("sameAsBilling", sameAsBilling.value);
      }
    });



const translationTextEn = ref({
      text: {
        enrollment: "Enrollment",
        asScholartext:
          "<p><b>Scholar</b> or Distributors as they are better known in the industry are part of CYMCORP's growing phase of the program.</p><h3>Building a Scholar Team</h3><p>As an active CYMCORP Scholar (or higher rank):</p><ul><li>You can sponsor other Scholars and earn overrides on their sales.</li><li>Although CYMCORP provides online training for new Scholars,it’s your job as their Sponsor to support, assist and encourage the Scholars on your team.</li><li>Your “team” consists of the Ambassadors and Scholars you personally sponsor (your “Level 1s”), those they sponsor (your “Level 2s”) and so on.</li></ul>",
        information: "Information",
        formSubText:
          "We recommend selecting a Replicated Scholar website name that reflects your business vs. a personal nickname or the like. Once selected you won’t be able to change it.",
        reuiredMsg: "This field is required",
        countryLabel: "Country",
        emailLabel: "Email Address",
        ambassadorLabel: "As a Scholar: Create your own website",
        passwordLabel: "Password",
        confirmPasswordLabel: "Confirm Password",
        strengthLabel: "Strength",
        securityLabel: "Select Security Question",
        answerLabel: "Answer",
        selectLabel: "Select an option",
        SSNLabel: "Numbers Only, No Dashes",
        languageLabel: "Select Language",
        currencyLabel: "Default Currency",
        firstNameLabel: "First Name",
        lastNameLabel: "Last Name",
        phoneLabel: "Phone : (Ex: X-Xxx-Xxx-Xxxx)",
        birthLabel: "Birth Date",
        monthSelectLabel: "Select Month",
        dateSelectLabel: "Select Date",
        yearSelectLabel: "Select Year",
        yesSendLabel: "YES! SEND ME E-MAIL NOTICES & UPDATES",
        enrollmentSummaryLabel: "Enrollment Summary",
        countryBillillingAddressLabel: "Billing Address",
        address1Label: "Address 1",
        address2Label: "Address 2",
        cityLabel: "City",
        countryLabel: "Country",
        stateLabel: "Select state",
        contrySelectLabel: "Select country",
        agreements: "Agreements",
        termsText: "Terms & Conditions",
        termsTextContent:
          'I have read and agree to the Terms and Conditions of this website. Clicking the "Place Order Now" button will submit my order for processing. I may cancel this purchase within 30 days for a full refund by eMailing support@cymcorp.ca or contacting Customer Service at',
        stateLabel1: "State",
        shippingAddressLabel: "Shipping Address",
        zipcode: "Zipcode/Postal Code",
        sameAsBillingAddress: "Same As Billing Address",
        signUp: "Sign Up",
      },
      language: "",
    });

    const translationText = ref({
      text: {
        enrollment: "Enrollment",
        asScholartext:
          "<p><b>Scholar</b> or Distributors as they are better known in the industry are part of CYMCORP's growing phase of the program.</p><h3>Building a Scholar Team</h3><p>As an active CYMCORP Scholar (or higher rank):</p><ul><li>You can sponsor other Scholars and earn overrides on their sales.</li><li>Although CYMCORP provides online training for new Scholars,it’s your job as their Sponsor to support, assist and encourage the Scholars on your team.</li><li>Your “team” consists of the Ambassadors and Scholars you personally sponsor (your “Level 1s”), those they sponsor (your “Level 2s”) and so on.</li></ul>",
        information: "Information",
        formSubText:
          "We recommend selecting a Replicated Scholar website name that reflects your business vs. a personal nickname or the like. Once selected you won’t be able to change it.",
        reuiredMsg: "This field is required",
        countryLabel: "Country",
        emailLabel: "Email Address",
        ambassadorLabel: "As a Scholar: Create your own website",
        passwordLabel: "Password",
        confirmPasswordLabel: "Confirm Password",
        strengthLabel: "Strength",
        securityLabel: "Select Security Question",
        answerLabel: "Answer",
        selectLabel: "Select an option",
        SSNLabel: "Numbers Only, No Dashes",
        languageLabel: "Select Language",
        currencyLabel: "Default Currency",
        firstNameLabel: "First Name",
        lastNameLabel: "Last Name",
        phoneLabel: "Phone : (Ex: X-Xxx-Xxx-Xxxx)",
        birthLabel: "Birth Date",
        monthSelectLabel: "Select Month",
        dateSelectLabel: "Select Date",
        yearSelectLabel: "Select Year",
        yesSendLabel: "YES! SEND ME E-MAIL NOTICES & UPDATES",
        enrollmentSummaryLabel: "Enrollment Summary",
        countryBillillingAddressLabel: "Billing Address",
        address1Label: "Address 1",
        address2Label: "Address 2",
        cityLabel: "City",
        countryLabel: "Country",
        stateLabel: "Select state",
        contrySelectLabel: "Select country",
        agreements: "Agreements",
        termsText: "Terms & Conditions",
        termsTextContent:
          'I have read and agree to the Terms and Conditions of this website. Clicking the "Place Order Now" button will submit my order for processing. I may cancel this purchase within 30 days for a full refund by eMailing support@cymcorp.ca or contacting Customer Service at',
        stateLabel1: "State",
        shippingAddressLabel: "Shipping Address",
        zipcode: "Zipcode/Postal Code",
        sameAsBillingAddress: "Same As Billing Address",
        signUp: "Sign Up",
      },
      language: "",
    });

    const translatText = () => {
      translationText.value.language = userLanguages.getLanguageCode
        ? "/" + userLanguages.getLanguageCode
        : "";
      if (userLanguages.getLanguageCode != "en") {
        const apiUrl = props.baseURL + "translateany";
        axios
          .post(apiUrl, translationText.value)
          .then((response) => {
            console.log("response trnasate :", response.data);
            translationText.value = response.data;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
      else{

        translationText.value = translationTextEn.value;
      }
    };

    onBeforeMount(() => {
      const stripePromise = loadStripe(stripeKey.value);
      stripePromise.then(() => {
        stripeLoaded.value = true;
      });
    });

    // onUpdated(() => {
    //   alert("updated");
    // });

    watch(languageCode, () => {
      translatText();
    });

    onMounted(() => {
      language_form.value = userLanguages.languageName;
      if (userLogin.getUserCountry == "Canada") {
        currency_form.value = "CAD";
      } else {
        currency_form.value = "USD";
      }
      getUerInfo();
      getCountry();
      translatText();

      const appearance = {
        /* appearance */
      };
      const options = {
        /* options */
      };
      //elements.value = stripe.value.elements({clientSecret, appearance})
      //   const paymentElement = elements.value.create('payment', options)

      //   paymentElement.mount("#payment-element")
    });
    computed(() => {});

    const pay = () => {
      // Get stripe element
      const cardElement = card.value.stripeElement;

      // console.log("cardElement ",cardElement);

      // Access instance methods, e.g. createToken()
      elms.value.instance.createToken(cardElement).then((result) => {
        // Handle result.error or result.token
        console.log("result ", result.token.id);

        const user = {
          token: result.token.id,
        };
        cartData.$reset();
        router.push({ name: "ThankYou" });

        //   axios
        //     .post("https://devenv.marketingplatform.ca/stripe-cymcrop/charge.php", user)
        //     .then((res) => {

        //       console.log("response ",res);
        //       //userLogin.userLoggedIn(res.data.token);
        //       //localStorage.setItem("token", res.data.token);
        //       //this.$emit("fetchData");
        //       //router.push({ name: "Product" });
        //     })
        //     .catch((err) => {
        //     //   swal({
        //     //     text: "Unable to Log you in!",
        //     //     icon: "error",
        //     //     closeOnClickOutside: false,
        //     //   });
        //       console.log(err);
        //     })
        //     .finally(() => {
        //       //loading = false;
        //     });

        // axios.post(apiUrl)
        //   .then((response) => {
        //     this.products = response.data;
        //     console.log("product data",this.products.products.length);
        //   })
        //   .catch((error) => {
        //     console.error('Error fetching data:', error);
        //   });
      });
    };

    return {
      userInfo,
      dateMonth,
      dayDataLoop,
      firstName,
      lastName,
      security_question,
      answer,
      ssn,
      dateMonthForm,
      dateDay,
      dateYear,
      phoneNumber,
      password,
      personal_tax_id,
      email,
      countryForm,
      cardNumber,
      cardExpiry,
      cardCvc,
      currency_form,
      language_form,
      userName,
      state_ship,
      total,
      subscriptionTypeMonthly,
      subscriptionTypeAnnual,
      subscriptionQty,
      subscriptionType,
      stripeKey,
      stripeLoaded,
      instanceOptions,
      elementsOptions,
      cardOptions,
      card,
      elms,
      pay,
      country,
      translationText,
      allCountries,
      allCountriesForm,
      securityQuestions,
      personalTaxForm,
      languageDate,
      currencyData,
      dateMonth,
      allCountriesBill,
      countryBill,
      stateBill,
      allStatesBill,
      passwordStrength,
      isPasswordStrong,
      score,
      allStates,
      signin,
      toggleShow,
      decreaseQty,
      increaseQty,
      sameAsBilling,
      ship_address,
      ship_address2,
      ship_city,
      ship_zipcode,
      bill_address,
      bill_address2,
      bill_city,
      bil_zipcode,
      showPassword,
      onScore,
    };
  },
});
</script>

<style scoped>
.wraptext {
  word-wrap: normal;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.btn-dark {
  background-color: #e7e9ec;
  color: #000;
  font-size: smaller;
  border-radius: 0;
  border-color: #adb1b8 #a2a6ac #a2a6ac;
}

.btn-primary {
  background-color: #f0c14b;
  color: black;
  border-color: #a88734 #9c7e31 #846a29;
  border-radius: 0;
}

#logo {
  width: 150px;
}

@media only screen and (min-width: 992px) {
  #signup-div {
    width: 40%;
  }
}
</style>
