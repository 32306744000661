<template>
  <section class="pro-related-vdo" data-aos="fade-up">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="title text-center">
            <h2>{{ props.translationText.text.whatAreYou }}</h2>
            <p>{{ props.translationText.text.whatAreYouText }}</p>
          </div>

          <div class="vdo-sec">
            <img src="/assets/images/vdo-review.jpg" alt="" class="w-100" />
            <a href="javascript: ;"><img src="images/you-ply.png" alt="" /></a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="features-sec p-0">
    <div class="container">
      <div class="feature-pro-img" data-aos="fade-up">
        <img src="/assets/images/features-img.png" alt="" />
      </div>
      <div class="row justify-content-between top" data-aos="fade-up">
        <div class="col-md-4 col-6">
          <div class="features-box">
            <figure>
              <img src="/assets/images/features-icon1.png" alt="" />
            </figure>
            <p>{{ props.translationText.text.improveText }}</p>
          </div>
        </div>
        <div class="col-md-4 col-6">
          <div class="features-box">
            <figure>
              <img src="/assets/images/features-icon2.png" alt="" />
            </figure>
            <p>{{ props.translationText.text.protectText }}</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-between middle" data-aos="fade-up">
        <div class="col-md-4 col-6">
          <div class="features-box">
            <figure>
              <img src="/assets/images/features-icon3.png" alt="" />
            </figure>
            <p>{{ props.translationText.text.reduceSign }}</p>
          </div>
        </div>
        <div class="col-md-4 col-6">
          <div class="features-box">
            <figure>
              <img src="/assets/images/features-icon4.png" alt="" />
            </figure>
            <p>{{ props.translationText.text.containsnatural }}</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center bottom" data-aos="fade-up">
        <div class="col-md-4 col-6">
          <div class="features-box">
            <figure>
              <img src="/assets/images/features-icon5.png" alt="" />
            </figure>
            <p>{{ props.translationText.text.freeHarmful }}</p>
          </div>
        </div>
      </div>
      <div class="border"></div>
    </div>
  </section>
</template>
<script setup>
import { ref, computed, defineProps, onUpdated, onMounted } from "vue";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import { userLanguage } from "../../stores/language";
import { onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import $ from "jquery";
const userLogin = useLoginStore();
const cartData = useCartStore();
const userLanguages = userLanguage();
const props = defineProps(["translationText"]);
</script>
