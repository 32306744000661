<template>
  <section class="products-area related-products">
    <div class="container-fluid">
      <div class="row justify-content-center" data-aos="fade-up">
        <div class="title col-lg-8 text-center">
          <h2>{{ translationText.text.relatedProducts }}</h2>
        </div>
      </div>
      <div class="row" data-aos="fade-up">
        <Carousel :itemsToShow="3" :wrapAround="true" :transition="500">
          <Slide v-for="slide in products" :key="slide">
            <div class="carousel__item">
              <div class="single-product-box">
                <div class="product-image">
                  <img
                    :src="slide.thumbnail_url"
                    alt=""
                    class="img-responsive"
                  />

                  <router-link
                    class="quick-view"
                    :to="{ name: 'ShowDetails', params: { id: slide.id } }"
                    >{{ translationText.text.quickView }}</router-link
                  >

                  <!-- <a class="quick-view">QUICK VIEW</a> -->
                </div>
                <div class="product-dtls">
                  <h3>
                    <router-link
                      :to="{ name: 'ShowDetails', params: { id: slide.id } }"
                      >{{ slide.name }}</router-link
                    >
                  </h3>

                  <p>{{ slide.description }}</p>
                  <p class="actual-price">${{ slide.sale_price }}</p>
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineComponent, ref, onMounted, defineProps, defineEmits } from "vue";
import axios from "axios";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { Carousel, Pagination, Slide } from "vue3-carousel";

const userLogin = useLoginStore();
const userLanguages = userLanguage();
const props = defineProps(["baseURL", "translationText"]);

const products = ref([]);

const fetchDataFromAPI = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const reqUserType = userLogin.checkUserType
    ? "/" + userLogin.checkUserType
    : "/" + "customer";
  const apiUrl = props.baseURL + "products" + reqUserType + languageUrl;
  //

  axios
    .get(apiUrl)
    .then((response) => {
      const filterValue = "yes";
      const filteredBooks = response.data.products.filter((val) =>
        val.display_shop.includes(filterValue)
      );
      products.value = filteredBooks;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

onMounted(() => {
  fetchDataFromAPI();
});
</script>

<style scoped>
/* .carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
} */
</style>
