<template>
  <section class="thanku-page">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-md-5 mb-4">
          <div class="title text-center">
            <span class="heart-icons"><i class="ri-heart-line"></i></span>
            <h1 class="text-white">Thank You !</h1>
            <h4 class="text-white">Your order was completly successful !</h4>
            <p class="text-white">
              We want to empower you to take back control of your health. For
              far too long, people have been passengers on a runaway bus of a
              costly and often strained health-care system. We want to change
              that. It is time for you to grab hold of the wheel and blaze a
              new, healthier path to your overall well-being.
            </p>
            <router-link
              class="btn btn-dark mt-3"
              v-if="userLogin.getUserUrlName != null"
              :to="'/' + userLogin.getUserUrlName + '/' + 'product'"
              >Continue Shopping</router-link
            >
            <router-link v-else class="btn btn-dark mt-3" to="/product"
              >Continue Shopping</router-link
            >
            <!-- <a href="/product" class="btn btn-dark mt-3"> Continue Shopping </a> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, computed, defineProps, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";

const props = defineProps(["translationText", "baseURL"]);
const userLogin = useLoginStore();
const cartData = useCartStore();
//const cartItemData = ref([])
const route = useRoute();
</script>
