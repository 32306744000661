<template>
  <div class="banner inner-banner faq-banner">
    <img src="/assets/images/faq-banner.png" alt="" class="w-100 zoom-out" />
    <div class="banner-text" data-aos="fade-up">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-8 col-sm-8 col-xs-8 text-start">
            <h1>{{ translationText.text.customerService }}</h1>
            <p>
              {{ translationText.text.customerServiceSubText }} 1-888-968-3592
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="faq-page">
    <div class="container">
      <div
        class="row justify-content-center aos-init aos-animate"
        data-aos="fade-up"
      >
        <div class="title col-lg-8 text-center">
          <h2>{{ translationText.text.questionAbout }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="faq-accordion">
            <div class="faq-accordion-item">
              <div class="faq-accordion-title">
                <h4>{{ translationText.text.contactCymcorp }}</h4>
                <i class="ri-add-line"></i>
              </div>
              <div class="faq-accordion-content">
                <p>{{ translationText.text.emailUs }}</p>
                <p>
                  {{ translationText.text.generalQuestions }}
                  <a href="mailto:support@Cymcorp.ca">support@Cymcorp.ca</a>.
                </p>
                <p>
                  {{ translationText.text.bloggers }}
                  <a href="mailto:jyoti@cymcorp.ca">jyoti@cymcorp.ca</a>.
                </p>
                <!-- <p>For retailers and distributors, please contact <a href="#">m.lopez@cymcorp.ca</a>.</p> -->
              </div>
            </div>
            <div class="faq-accordion-item">
              <div class="faq-accordion-title">
                <h4>{{ translationText.text.package }}</h4>
                <i class="ri-add-line"></i>
              </div>
              <div class="faq-accordion-content">
                <div v-html="translationText.text.journeyBegins"></div>
                <!-- <p>{{ translationText.text.journeyBegins }}</p> -->
              </div>
            </div>
            <div class="faq-accordion-item">
              <div class="faq-accordion-title">
                <h4>{{ translationText.text.whatIf }}</h4>
                <i class="ri-add-line"></i>
              </div>
              <div class="faq-accordion-content">
                <div v-html="translationText.text.journeyBegins1"></div>
              </div>
            </div>
            <div class="faq-accordion-item">
              <div class="faq-accordion-title">
                <h4>{{ translationText.text.howDoes }}</h4>
                <i class="ri-add-line"></i>
              </div>
              <div class="faq-accordion-content">
                <p>{{ translationText.text.journeyBegins2 }}</p>
              </div>
            </div>
            <div class="faq-accordion-item">
              <div class="faq-accordion-title">
                <h4>{{ translationText.text.journeyBegins3 }}</h4>
                <i class="ri-add-line"></i>
              </div>
              <div class="faq-accordion-content">
                <p>
                  {{ translationText.text.journeyBegins4 }}
                </p>
              </div>
            </div>
            <div class="faq-accordion-item">
              <div class="faq-accordion-title">
                <h4>{{ translationText.text.journeyBegins5 }}</h4>
                <i class="ri-add-line"></i>
              </div>
              <div class="faq-accordion-content">
                <p>{{ translationText.text.journeyBegins6 }}</p>
              </div>
            </div>
            <div class="faq-accordion-item">
              <div class="faq-accordion-title">
                <h4>{{ translationText.text.journeyBegins7 }}</h4>
                <i class="ri-add-line"></i>
              </div>
              <div class="faq-accordion-content">
                <p>{{ translationText.text.journeyBegins8 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ProductListQuiz></ProductListQuiz>
</template>
<script setup>
import { defineComponent, ref, onMounted, watch } from "vue";
import { mapState, storeToRefs } from "pinia";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import $ from "jquery";
import ProductListQuiz from "../components/Product/ProductListQuiz";
import { userLanguage } from "../stores/language";
import { useRoute } from "vue-router";

const route = useRoute();
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const props = defineProps(["baseURL"]);


const translationTextEn = ref({
  text: {
    customerService: "Customer Service",
    customerServiceSubText:
      "Find answers you're looking for. If your question is not answered here, kindly give us a call at",
    questionAbout: "Questions About Cymcorp",
    contactCymcorp: "How can I Contact Cymcorp?",
    emailUs: "Give us a call! 1-888-968-3592 or email us!",
    generalQuestions:
      "For general questions and customer service, please contact",
    bloggers: "For bloggers, athletes, and press inquiries, please contact",
    package: "When Will I Receive My Package?",
    journeyBegins:
      "<p> All our packages are shipped through Canada Post\’s “Expedited Parcel” service. Estimated delivery times depend on your location:</p><ul><li>Canada – 3 to 5 business days</li><li> USA – 5 to 10 business days</li><li> International – 10 to 20 business days</li></ul> <p>Rush options are available for your convenience during checkout. Please keep in mind that Canada Post makes every effort to deliver packages on time. Although rare, we are not responsible for any delays or issues affecting their delivery schedules.I am watching avijit</p>",
    whatIf: "What If I Change My Mind Or Want To Return The Product?",
    journeyBegins1:
      "<p>If you want to cancel your order, you can call our head office at 1-888-968-3592 within eight business hours from the time of your purchase and a full refund will be applied.</p><p>If the order has already shipped, you can return unopened products with a copy of the original invoice (address listed below). When we receive it, we will issue you a refund in your original method of payment (or a cheque upon request).</p><p>Refunds are only applicable to the products. Shipping and handling fees will not be refunded. A 15% restocking fee will be applied. For sanitary reasons, we will not accept any opened or damaged products.</p><p>Refunds are issued within 15 business days of approval.</p><p>Return Address:</br>Cymcorp International inc.</br>Att: Quality Control Department</br>19381 County Road</br>Dunvegan, Ontario,</br>K0C 1J0, Canada</p>",
    howDoes: "How Does The Subscription Service Work?",
    journeyBegins2:
      "You can enjoy significant monthly discounts — from 10% to 20% depending on the product — without having to worry about running out of supplies. Visit our SUBSCRIPTION page for more information.",
    journeyBegins3: "What Are The Subscription Rules?",
    journeyBegins4:
      "Our subscription service is a privilege granted to customers who are committed to their quality of life. But this privilege should not be abused for the sake of a one-time saving. As such, you cannot suspend your subscription during your initial six months. During this period, you are free to change or upgrade your subscription as you see fit. After six months, you can cancel your subscription. Thank you for your understanding.",
    journeyBegins5: "How Do I Know This Is Good Quality? Is There A Guarantee?",
    journeyBegins6:
      "Our commitment to quality is the highest in the industry. Every product undergoes rigorous analysis for purity, potency, safety and freshness. And we guarantee it with our “results-or-your-money-back” guarantee. We promise to refund or exchange any product that fails to show results within 30 days of use.",
    journeyBegins7:
      "I Have A Pre-Existing Medical Condition, Can I Still Use Cymcorp Products?",
    journeyBegins8:
      "If you have any pre-existing medical conditions, we strongly advise you to consult your doctor before taking our products. Also, you should always leave a one hour period, before or after taking your prescription medication, to use our products.",
  },
  language: "",
});


const translationText = ref({
  text: {
    customerService: "Customer Service",
    customerServiceSubText:
      "Find answers you're looking for. If your question is not answered here, kindly give us a call at",
    questionAbout: "Questions About Cymcorp",
    contactCymcorp: "How can I Contact Cymcorp?",
    emailUs: "Give us a call! 1-888-968-3592 or email us!",
    generalQuestions:
      "For general questions and customer service, please contact",
    bloggers: "For bloggers, athletes, and press inquiries, please contact",
    package: "When Will I Receive My Package?",
    journeyBegins:
      "<p> All our packages are shipped through Canada Post\’s “Expedited Parcel” service. Estimated delivery times depend on your location:</p><ul><li>Canada – 3 to 5 business days</li><li> USA – 5 to 10 business days</li><li> International – 10 to 20 business days</li></ul> <p>Rush options are available for your convenience during checkout. Please keep in mind that Canada Post makes every effort to deliver packages on time. Although rare, we are not responsible for any delays or issues affecting their delivery schedules.I am watching avijit</p>",
    whatIf: "What If I Change My Mind Or Want To Return The Product?",
    journeyBegins1:
      "<p>If you want to cancel your order, you can call our head office at 1-888-968-3592 within eight business hours from the time of your purchase and a full refund will be applied.</p><p>If the order has already shipped, you can return unopened products with a copy of the original invoice (address listed below). When we receive it, we will issue you a refund in your original method of payment (or a cheque upon request).</p><p>Refunds are only applicable to the products. Shipping and handling fees will not be refunded. A 15% restocking fee will be applied. For sanitary reasons, we will not accept any opened or damaged products.</p><p>Refunds are issued within 15 business days of approval.</p><p>Return Address:</br>Cymcorp International inc.</br>Att: Quality Control Department</br>19381 County Road</br>Dunvegan, Ontario,</br>K0C 1J0, Canada</p>",
    howDoes: "How Does The Subscription Service Work?",
    journeyBegins2:
      "You can enjoy significant monthly discounts — from 10% to 20% depending on the product — without having to worry about running out of supplies. Visit our SUBSCRIPTION page for more information.",
    journeyBegins3: "What Are The Subscription Rules?",
    journeyBegins4:
      "Our subscription service is a privilege granted to customers who are committed to their quality of life. But this privilege should not be abused for the sake of a one-time saving. As such, you cannot suspend your subscription during your initial six months. During this period, you are free to change or upgrade your subscription as you see fit. After six months, you can cancel your subscription. Thank you for your understanding.",
    journeyBegins5: "How Do I Know This Is Good Quality? Is There A Guarantee?",
    journeyBegins6:
      "Our commitment to quality is the highest in the industry. Every product undergoes rigorous analysis for purity, potency, safety and freshness. And we guarantee it with our “results-or-your-money-back” guarantee. We promise to refund or exchange any product that fails to show results within 30 days of use.",
    journeyBegins7:
      "I Have A Pre-Existing Medical Condition, Can I Still Use Cymcorp Products?",
    journeyBegins8:
      "If you have any pre-existing medical conditions, we strongly advise you to consult your doctor before taking our products. Also, you should always leave a one hour period, before or after taking your prescription medication, to use our products.",
  },
  language: "",
});

const translatText = () => {
  // console.log(props.baseURL);
  // const languageUrl = userLanguages.getLanguageCode
  //   ? "/" + userLanguages.getLanguageCode
  //   : "";
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if(userLanguages.getLanguageCode != 'en'){

    const apiUrl = props.baseURL + "translateany";
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        console.log("response trnasate :", response.data);
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }
  else{
    translationText.value = translationTextEn.value;
  }
 
};
watch(languageCode, () => {
  translatText();
});
onMounted(() => {
  translatText();
  // $(".faq-accordion-title").click(function () {
  //   $(this)
  //     .parent()
  //     .siblings(".faq-accordion-item")
  //     .find(".faq-accordion-title")
  //     .removeClass("active");
  //   $(this)
  //     .parent()
  //     .siblings(".faq-accordion-item")
  //     .find(".faq-accordion-content")
  //     .slideUp(250);
  //   $(this).toggleClass("active");
  //   $(this).next().slideToggle(250);
  // });
});
</script>
