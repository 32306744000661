<template>
  <section class="about-text gray-bg" data-aos="fade-up">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="bg-white p-md-5 p-3">
            <h2 class="mb-3">Thank You</h2>
            <p class="gray-text">
              Your Enrolling Sponsor will be:
              <b>{{ userInfo.enrolling_sponsor_name }}</b> 
              ID: <b>{{ userInfo.id }}</b> We recommend selecting a Replicated
              Ambassador link name that reflects your business vs. a personal
              nickname or the like. Once selected you won't be able to change
              it.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { defineComponent, ref, onMounted, defineProps, defineEmits } from "vue";
import axios from "axios";
import { userLanguage } from "../stores/language";
import { useLoginStore } from "../stores/login";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import { useRouter } from "vue-router";

const userLogin = useLoginStore();
const userLanguages = userLanguage();
const props = defineProps(["baseURL"]);
const router = useRouter();
const userInfo = ref({
  enrolling_sponsor_name: "",
  id: "",
});

const fetchDataFromAPI = async () => {
  if (userLogin.getUserUrlName != null) {
    const apiUrl = props.baseURL + "get-user-info/" + userLogin.getUserUrlName;
    axios
      .get(apiUrl)
      .then((response) => {
        userInfo.value = response.data;
        userLogin.restUserUrlName();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  } else {
    router.push({ name: "Home" });
  }
};

onMounted(() => {
  fetchDataFromAPI();
});
</script>
