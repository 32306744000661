<template>
  <section class="complex-product" data-aos="fade-up">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="title text-center">
            <h2>{{ props.translationText.text.healthyLiving }}</h2>
          </div>
          <div class="complex-img">
            <img src="/assets/images/image1complex.jpg" alt="" class="w-100" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import { userLanguage } from "../../stores/language";
const userLanguages = userLanguage();
const cartData = useCartStore();
const route = useRoute();
let props = defineProps(["translationText"]);
</script>
