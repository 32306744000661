<template>
  <section class="after-story">
    <div class="container-fluid">
      <div class="row" data-aos="fade-up">
        <div class="col-12">
          <div class="title text-center">
            <h2>{{ props.translationText.text.seeResults }}</h2>
          </div>
          <div class="rgcontainer">
            <div class="image-container">
              <h5 class="bf">{{ props.translationText.text.before }}</h5>
              <img
                class="image-before slider-image"
                src="/assets/images/before-img.jpg"
                alt="color photo"
              />
              <img
                class="image-after slider-image"
                src="/assets/images/after-img.jpg"
                alt="black and white"
              />
              <h5 class="af">{{ props.translationText.text.after }}</h5>
            </div>
            <!-- step="10" -->

            <input
              type="range"
              min="0"
              max="100"
              value="50"
              aria-label="Percentage of before photo shown"
              class="slider"
            />
            <div class="slider-line" aria-hidden="true"></div>
            <div class="slider-button" aria-hidden="true">
              <span></span>
            </div>
          </div>
          <!-- <input
                    type="range"
                    min="0"
                    max="100"
                    v-model="beforeAfterImage"
                    aria-label="Percentage of before photo shown"
                    class="slider"
                    /> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, defineProps, onUpdated, onMounted } from "vue";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import { userLanguage } from "../../stores/language";
import { onBeforeRouteLeave, onBeforeRouteUpdate } from "vue-router";
import $ from "jquery";
const userLogin = useLoginStore();
const cartData = useCartStore();
const userLanguages = userLanguage();
const token = ref("");
const props = defineProps(["baseURL", "translationText"]);
const siteFooterLegal = ref([]);
const defaultLanguage = ref("");

onMounted(() => {
  //getLanguage();
  const container = document.querySelector(".rgcontainer");
  document.querySelector(".slider").addEventListener("input", (e) => {
    container.style.setProperty("--position", `${e.target.value}%`);
  });
});

onUpdated(() => {});
</script>

<!-- <script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductBeforeAfterImage',
  components: {},
  data: () => ({
    currentSlide: 0,
    beforeAfterImage:50
  }),
  methods: {
   
  },
})
</script> -->
