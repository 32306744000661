<template>
  <section class="faq-page" style="background: #eae7e7;">
    <div class="container">
        <div class="row justify-content-center aos-init aos-animate" data-aos="fade-up">
            <div class="title col-lg-8 text-center">
                <h2>Create your own Business card</h2>
            </div>
        </div>
        <Form novalidate @submit="signin">
        <div class="row mb-3 visiting-card">
            <div class="form-group col-md-6">
            <Field
                type="text"
                name="name"
                rules="required"
                class="form-control"
                v-model="form.name"
                placeholder="Your Name"
            ></Field>
            <ErrorMessage name="name" />

                <!-- <input type="text" name="" class="form-control" placeholder="Your Name" > -->
            </div>
            <div class="form-group col-md-6">
                <Field
                type="text"
                name="designation"
                rules="required"
                class="form-control"
                v-model="form.designation"
                placeholder="Your Designation"
            ></Field>
            <ErrorMessage name="designation" />
                <!-- <input type="text" name="" class="form-control" placeholder="Your Designation" > -->
            </div>
            <div class="form-group col-md-6">
                <Field
                    type="text"
                    name="phone"
                    rules="required"
                    class="form-control"
                    v-model="form.phone"
                    placeholder="Phone Number"
                ></Field>
                <ErrorMessage name="designation" />
            
                <!-- <input type="text" name="" class="form-control" placeholder="Phone Number" > -->
            </div>
            <div class="form-group col-md-6">

                <Field
                    type="text"
                    name="email"
                    rules="required|email"
                    class="form-control"
                    v-model="form.email"
                    placeholder="Email"
                ></Field>
                <ErrorMessage name="email" />

                <!-- <input type="text" name="" class="form-control" placeholder="Email" > -->
            </div>
            <div class="form-group col-md-6">
                <Field
                    type="text"
                    name="website"
                    rules="required"
                    class="form-control"
                    v-model="form.website"
                    placeholder="http://www.yoursitename.com"
                ></Field>
                <ErrorMessage name="website" />
                <!-- <input type="text" name="" class="form-control" placeholder="Website" > -->
            </div>          

        </div>
        
        <div class="row visiting-card" ref="componentRef" id="printMe">
            <div class="col-lg-6 col-md-12 mb-4">
                <h4 class="mb-3">Front View</h4>
                <div class="total-card" style="background:url(assets/images/Visiting-card-Front.jpg) no-repeat center center">
                    <div class="card-contents">
                        <h3>{{ form.name }}</h3>
                        <p>Your Health and Wellness {{ form.designation }}</p>
                        <ul>
                            <li><img src="/assets/images/call.png" alt=""> {{ form.phone }}</li>
                            <li><img src="/assets/images/mail.png" alt="">{{ form.email }}</li>
                            <li><img src="/assets/images/website.png" alt="">{{form.website }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 mb-4">
                <h4 class="mb-3">Back View</h4>
                <div class="total-card" style="background:url(/assets/images/Visiting-cardback.jpg) no-repeat center center">
                </div>
            </div>
            <div class="col-md-12 text-center">
                <button class="btn btn-primary">Download Your Card</button>
                <!-- <a href="#" class="btn btn-primary">Download Your Card</a> -->
            </div>
        </div>
        </Form>
    </div>
</section>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, watch,reactive } from "vue";
import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";
import { mapState, storeToRefs } from "pinia";
import { useLoginStore } from "../stores/login";
import { userLanguage } from "../stores/language";
import { useRouter } from "vue-router";
import PasswordMeter from "vue-simple-password-meter";
import * as AllRules from "@vee-validate/rules";
import ProductListQuiz from "../components/Product/ProductListQuiz";
import { localize } from "@vee-validate/i18n";
import { useLoading } from "vue3-loading-overlay";
import { VueToPrint,useVueToPrint  } from "vue-to-print";


const componentRef = ref();
// Import stylesheet
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});


const { handlePrint } = useVueToPrint({
  content: () => componentRef.value,
  documentTitle: "AwesomeFileName",
});


const userInfo = ref({
  enrolling_sponsor_name: "",
  id: "",
  user_type: "",
});

const form = ref({
    name:null,
    designation:null,
    phone:null,
    email:null,
    website:null
});
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const router = useRouter();
const userLogin = useLoginStore();
let loading = ref(0);
const password = ref("");
const email = ref("");
const props = defineProps(["baseURL"]);
const allCountries = ref([]);
const country = ref();
const state = ref();
const allStates = ref([]);
const phoneNumber = ref("");
const userName = ref("");

const firstName = ref("");
const lastName = ref("");
const security_question = ref("");
const answer = ref("");
const ssn = ref("");
const dateMonthForm = ref("");
const dateDay = ref("");
const dateYear = ref("");
const language_form = ref("");
const sameAsBilling = ref(false);
const ship_address = ref();
const ship_address2 = ref();
const ship_city = ref();
const ship_zipcode = ref();

const bill_address = ref();
const bill_address2 = ref();
const bill_city = ref();
const bil_zipcode = ref();
const currency_form = ref("");
const state_ship = ref("");

const countryForm = ref("");
const allCountriesForm = ref([]);
const showPassword = ref(false);
const personal_tax_id = ref("Personal Tax ID/SSN");
const personalTaxForm = ref(["Personal Tax ID/SSN", "Business Tax ID"]);

const languageDate = ref(["English", "French"]);

const currencyData = ref([
  { name: "Canadian Dollar", code: "CAD" },
  { name: "US Dollar", code: "USD" },
]);
const dateMonth = ref([
  { id: "01", name: "January" },
  { id: "02", name: "February" },
  { id: "03", name: "March" },
  { id: "04", name: "April" },
  { id: "05", name: "May" },
  { id: "06", name: "June" },
  { id: "07", name: "July" },
  { id: "08", name: "August" },
  { id: "09", name: "September" },
  { id: "10", name: "October" },
  { id: "11", name: "November" },
  { id: "12", name: "December" },
]);
const dayDataLoop = ref([
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
]);
// New Country
configure({
  generateMessage: localize({
    en: {
      messages: {
        required: "This field is required",
      },
      fields: {
        email: {
          required: "Email address is required",
          email: "Please enter a valid email address.",
        },
        password: {
          required: "Password is required",
        },
        country: {
          required: "Select country",
        },
        state: {
          required: "Select state",
        },
      },
    },
  }),
});
const allCountriesBill = ref([]);
const countryBill = ref();
const stateBill = ref();
const allStatesBill = ref([]);
const passwordStrength = ref();

const fullPage = ref(true);
const formContainer = ref(null);

// Ends here
const isPasswordStrong = ref(false);

const securityQuestions = ref([
  "In what city was your mother born?",
  "What was the name of your favorite teacher?",
  "What was the name of your childhood best friend?",
  "What is the name of the company of your first job?",
  "What is the last four digits of the credit card you used?",
  "What was the brand of your first car?",
  "What is your father's first name?",
  "What is your favorite hobby?",
  "What is your Facebook username?",
  "What is your mother's middle name?",
  "What year did you graduate from High School?",
  "What is your favorite holiday?",
  "What is your favorite color?",
  "What street did you grow up on?",
  "What is your preferred musical genre?",
  "What is your favorite sports team?",
]);

const score = ref(null);
const onScore = (payload) => {
  console.log(payload.score); // from 0 to 4
  console.log(payload.strength); // one of : 'risky', 'guessable', 'weak', 'safe' , 'secure'
  passwordStrength.value = payload.strength;
  score.value = payload.score;
};

console.log("prpos ", props.baseURL);
const toggleShow = () => {
  showPassword.value = !showPassword.value;
};

const validateEmail = (value) => {
  // if the field is empty
  if (!value) {
    return translationText.text.reuiredMsg;
  }
  // if the field is not a valid email
  // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  // if (!regex.test(value)) {
  //   return 'This field must be a valid email';
  // }
  // All is good
  return true;
};

const validatePassword = (value) => {
  // if the field is empty
  if (!value) {
    return "This field is required";
  }

  // All is good
  return true;
};

const signin = async () => {
    handlePrint();
  //const loader = useLoading();

  // loader.show({
  //   // Optional parameters
  //   container: fullPage.value ? null : formContainer.value,
  //   canCancel: true,
  //   //onCancel: onCancel,
  // });

//   userLanguages.setLoading(true);
//   const userCountry = ref("");
//   if (userLogin.getUserCountry == "Canada") {
//     userCountry.value = "ca";
//   } else {
//     userCountry.value = "usa";
//   }
//   const payloadData = ref({
//     referal_id: userLogin.getReferalId,
//     firstname: firstName.value,
//     lastname: lastName.value,
//     email: email.value,
//     password: password.value,
//     username: email.value,
//     usertype: "customer",
//     mobile: phoneNumber.value,
//     shopping_country_code: userCountry.value,
//     secret_question: security_question.value,
//     answer: answer.value,
//     personal_tax_id: "",
//     ssn: "",
//     language: language_form.value,
//     currency: currency_form.value,
//     date_month: dateMonthForm.value,
//     date_day: dateDay.value,
//     date_year: dateYear.value,
//     bill_address: bill_address.value,
//     bill_address2: bill_address2.value,
//     city: bill_city.value,
//     countryBill: countryBill.value,
//     stateBill: stateBill.value,
//     bil_zipcode: bil_zipcode.value,
//     same_billing: sameAsBilling.value,
//     ship_address: ship_address.value,
//     ship_address2: ship_address2.value,
//     ship_city: ship_city.value,
//     country: country.value,
//     state: state_ship.value,
//     ship_zipcode: ship_zipcode.value,
//   });
//   console.log("payloadData", payloadData.value);
//   const apiUrl = props.baseURL + "register";
//   axios
//     .post(apiUrl, payloadData.value)
//     .then((response) => {
//       console.log("response trnasate :", response.data);
//       userLanguages.setLoading(false);
//       //userLogin.userLoggedIn(response.data);
//       if (response.data.access_token) {
//         swal({
//           position: "top-end",
//           icon: "success",
//           title: response.data.message,
//           button: false,
//           timer: 2500,
//         });
//         //loader.hide();
//         userLogin.userLoggedIn(response.data);
//         router.push({ name: "Dashboard" });
//       } else {
//         swal({
//           position: "top-end",
//           icon: "error",
//           title: response.data.error,
//           button: false,
//           timer: 2500,
//         });
//         //loader.hide();
//         //userLogin.addUserUrlName(userName.value);
//         //router.push({ name: "Dashboard" });
//       }

//       //translationText.value = response.data;
//     })
//     .catch((error) => {
//       userLanguages.setLoading(false);
//       //loader.hide();
//       console.error("Error fetching data:", error);
//     });
};
const acceptNumber = (value) => {
  var x = phoneNumber.value
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  phoneNumber.value = !x[2]
    ? x[1]
    : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
};

const getCountry = async () => {
  axios
    .get(props.baseURL + "countries")
    .then((response) => {
      allCountries.value = response.data.countries;
      allCountriesBill.value = response.data.countries;
      allCountriesForm.value = response.data.countries;
      //console.log("country  data", response.data.countries);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};

const getUerInfo = async () => {
  //if (userLogin.getUserUrlName != null) {

  const userUrl = userLogin.getUserUrlName
    ? "/" + userLogin.getUserUrlName
    : "";
  const apiUrl = props.baseURL + "get-user-info" + userUrl;
  axios
    .get(apiUrl)
    .then((response) => {
      userInfo.value = response.data;
      //userLogin.restUserUrlName();
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  // } else {
  //   router.push({ name: "Home" });
  // }
};

watch(sameAsBilling, (newValue, oldValue) => {
  if (sameAsBilling.value == true) {
    console.log("sameAsBilling", sameAsBilling.value);
    ship_address.value = bill_address.value;
    ship_address2.value = bill_address2.value;
    ship_city.value = bill_city.value;
    ship_zipcode.value = bil_zipcode.value;
    country.value = countryBill.value;
    state.value = stateBill.value;
  } else {
    ship_address.value = "";
    ship_address2.value = "";
    ship_city.value = "";
    ship_zipcode.value = "";
    console.log("sameAsBilling", sameAsBilling.value);
  }
});

watch(country, (newValue, oldValue) => {
  axios
    .get(props.baseURL + "states/" + newValue)
    .then((response) => {
      allStates.value = response.data.states;
      if (sameAsBilling.value == true) {
        console.log("fffffffffffffffffff", stateBill.value);
        console.log("state.value ", stateBill.value);
        state_ship.value = stateBill.value;
        // setTimeout(() => {
        //   state_ship.value = stateBill.value;
        // }, 2000);
      }

      console.log("states  data", response.data.states);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
});
watch(countryBill, (newValue, oldValue) => {
  axios
    .get(props.baseURL + "states/" + newValue)
    .then((response) => {
      allStatesBill.value = response.data.states;
      console.log("states  data", response.data.states);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
});

const translationTextEn = ref({
  text: {
    asScholartext:
      '<p><b>Client</b> or Customer as they are better known! They are part of CYMCORP\'s growing community of people who are benefiting from the many properties of our products.</p><p>CYMCORP prides itself in having scientifically proven products that answers the needs of our clientele.</p><p>Viprox® is our nontoxic, non-invasive therapy to re-balance your whole organism and take care of you holistically. It is clinically proven to work at 98.9% efficacy.</p><p>GSH Complex® is backed by over 160,000 medical articles you can review on PubMed.<a href="https://pubmed.ncbi.nlm.nih.gov/">(pubmed.ncbi.nlm.nih.gov/)</a></p><p>But as they say the proof is in the pudding. Hear the Success Stories from people like you who have tried our line of products.<a href="https://admin.truequalityoflife.com/Success-Stories/">(https://admin.truequalityoflife.com/Success-Stories/)</a></p><p>Try it and if you are not satisfied, we will refund your money.You have nothing to lose.</p>',
    asScholartext1:
      "<p>As an Ambassador, when you share your experience of CYMCORP’s life-changing products with others, you’ll earn 20% on their purchases (your “Level 1”); and 10% on the purchases of people they refer (your “Level 2”).</p><p>NOTE: You are not required to be a CYMCORP customer to become an Ambassador but having a personal experience to share, being “a product of the product”, always makes for a better story.</p><p>All purchases of products are placed directly with CYMCORP through the link of a referring Ambassador (the “Seller”), thereby tracking that transaction for the benefit of the Seller’s commission. Even on recurring purchases.</p>",
    enrollment: "Enrollment",
    information: "Information",
    formSubText:
      "We recommend selecting a Replicated Ambassador link name that reflects your business vs. a personal nickname or the like. Once selected you won’t be able to change it.",
    reuiredMsg: "This field is required",
    countryLabel: "Country",
    emailLabel: "Email Address",
    ambassadorLabel: "As an Ambassador: Create your own...",
    passwordLabel: "Password",
    confirmPasswordLabel: "Confirm Password",
    strengthLabel: "Strength",
    securityLabel: "Select Security Question",
    answerLabel: "Answer",
    selectLabel: "Select an option",
    SSNLabel: "Numbers Only, No Dashes",
    languageLabel: "Select Language",
    currencyLabel: "Default Currency",
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    phoneLabel: "Phone : (Ex: X-Xxx-Xxx-Xxxx)",
    birthLabel: "Birth Date",
    monthSelectLabel: "Select Month",
    dateSelectLabel: "Select Date",
    yearSelectLabel: "Select Year",
    yesSendLabel: "YES! SEND ME E-MAIL NOTICES & UPDATES",
    enrollmentSummaryLabel: "Enrollment Summary",
    countryBillillingAddressLabel: "Billing Address",
    address1Label: "Address 1",
    address2Label: "Address 2",
    cityLabel: "City",
    countryLabel: "Country",
    stateLabel: "Select state",
    contrySelectLabel: "Select country",
    agreements: "Agreements",
    termsText: "Terms & Conditions",
    termsTextContent:
      'I have read and agree to the Terms and Conditions of this website. Clicking the "Place Order Now" button will submit my order for processing. I may cancel this purchase within 30 days for a full refund by eMailing support@cymcorp.ca or contacting Customer Service at',
    stateLabel1: "State",
    shippingAddressLabel: "Shipping Address",
    zipcode: "Zipcode/Postal Code",
    sameAsBillingAddress: "Same As Billing Address",
    signUp: "Sign Up",
  },
  language: "",
});


const translationText = ref({
  text: {
    asScholartext:
      '<p><b>Client</b> or Customer as they are better known! They are part of CYMCORP\'s growing community of people who are benefiting from the many properties of our products.</p><p>CYMCORP prides itself in having scientifically proven products that answers the needs of our clientele.</p><p>Viprox® is our nontoxic, non-invasive therapy to re-balance your whole organism and take care of you holistically. It is clinically proven to work at 98.9% efficacy.</p><p>GSH Complex® is backed by over 160,000 medical articles you can review on PubMed.<a href="https://pubmed.ncbi.nlm.nih.gov/">(pubmed.ncbi.nlm.nih.gov/)</a></p><p>But as they say the proof is in the pudding. Hear the Success Stories from people like you who have tried our line of products.<a href="https://admin.truequalityoflife.com/Success-Stories/">(https://admin.truequalityoflife.com/Success-Stories/)</a></p><p>Try it and if you are not satisfied, we will refund your money.You have nothing to lose.</p>',
    asScholartext1:
      "<p>As an Ambassador, when you share your experience of CYMCORP’s life-changing products with others, you’ll earn 20% on their purchases (your “Level 1”); and 10% on the purchases of people they refer (your “Level 2”).</p><p>NOTE: You are not required to be a CYMCORP customer to become an Ambassador but having a personal experience to share, being “a product of the product”, always makes for a better story.</p><p>All purchases of products are placed directly with CYMCORP through the link of a referring Ambassador (the “Seller”), thereby tracking that transaction for the benefit of the Seller’s commission. Even on recurring purchases.</p>",
    enrollment: "Enrollment",
    information: "Information",
    formSubText:
      "We recommend selecting a Replicated Ambassador link name that reflects your business vs. a personal nickname or the like. Once selected you won’t be able to change it.",
    reuiredMsg: "This field is required",
    countryLabel: "Country",
    emailLabel: "Email Address",
    ambassadorLabel: "As an Ambassador: Create your own...",
    passwordLabel: "Password",
    confirmPasswordLabel: "Confirm Password",
    strengthLabel: "Strength",
    securityLabel: "Select Security Question",
    answerLabel: "Answer",
    selectLabel: "Select an option",
    SSNLabel: "Numbers Only, No Dashes",
    languageLabel: "Select Language",
    currencyLabel: "Default Currency",
    firstNameLabel: "First Name",
    lastNameLabel: "Last Name",
    phoneLabel: "Phone : (Ex: X-Xxx-Xxx-Xxxx)",
    birthLabel: "Birth Date",
    monthSelectLabel: "Select Month",
    dateSelectLabel: "Select Date",
    yearSelectLabel: "Select Year",
    yesSendLabel: "YES! SEND ME E-MAIL NOTICES & UPDATES",
    enrollmentSummaryLabel: "Enrollment Summary",
    countryBillillingAddressLabel: "Billing Address",
    address1Label: "Address 1",
    address2Label: "Address 2",
    cityLabel: "City",
    countryLabel: "Country",
    stateLabel: "Select state",
    contrySelectLabel: "Select country",
    agreements: "Agreements",
    termsText: "Terms & Conditions",
    termsTextContent:
      'I have read and agree to the Terms and Conditions of this website. Clicking the "Place Order Now" button will submit my order for processing. I may cancel this purchase within 30 days for a full refund by eMailing support@cymcorp.ca or contacting Customer Service at',
    stateLabel1: "State",
    shippingAddressLabel: "Shipping Address",
    zipcode: "Zipcode/Postal Code",
    sameAsBillingAddress: "Same As Billing Address",
    signUp: "Sign Up",
  },
  language: "",
});

const translatText = () => {
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if(userLanguages.getLanguageCode != 'en'){

    const apiUrl = props.baseURL + "translateany";
    axios
      .post(apiUrl, translationText.value)
      .then((response) => {
        console.log("response trnasate :", response.data);
        translationText.value = response.data;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

  }
  else{
    translationText.value = translationTextEn.value;
  }
  
};

watch(languageCode, () => {
  translatText();
});

onMounted(() => {
  language_form.value = userLanguages.languageName;
  if (userLogin.getUserCountry == "Canada") {
    currency_form.value = "CAD";
  } else {
    currency_form.value = "USD";
  }
  getUerInfo();
  getCountry();
  translatText();
});
</script>

<style scoped>
.wraptext {
  word-wrap: normal;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.btn-dark {
  background-color: #e7e9ec;
  color: #000;
  font-size: smaller;
  border-radius: 0;
  border-color: #adb1b8 #a2a6ac #a2a6ac;
}

.btn-primary {
  background-color: #f0c14b;
  color: black;
  border-color: #a88734 #9c7e31 #846a29;
  border-radius: 0;
}

#logo {
  width: 150px;
}

@media only screen and (min-width: 992px) {
  #signup-div {
    width: 40%;
  }
}
@media print {
  @page {
        margin: 25mm 25mm 25mm 25mm;  

  }
}
</style>
