<template>
  <section class="team-body" style="background: #fff">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 col-sm-4 col-xs-12 mb-4">
          <UserSideBar
            :baseURL="baseURL"
            @logout-clicked="logOut"
          ></UserSideBar>
          <!-- <AbmassadorSideBar
            @logout-clicked="logOut"
            v-if="userLogin.checkUserType == 'ambassador'"
          ></AbmassadorSideBar>
          <ScholarSideBar
            @logout-clicked="logOut"
            v-if="userLogin.checkUserType == 'scholar'"
          ></ScholarSideBar>
          <CustomerSideBar
            v-if="userLogin.checkUserType == 'customer'"
            @logout-clicked="logOut"
          ></CustomerSideBar> -->
        </div>

        <router-view :baseURL="baseURL"></router-view>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, watch } from "vue";
import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";
import { mapState } from "pinia";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";
import PasswordMeter from "vue-simple-password-meter";
import * as AllRules from "@vee-validate/rules";
import ProductListQuiz from "../../components/Product/ProductListQuiz";
import AbmassadorSideBar from "../../components/AbmassadorSideBar";
import ScholarSideBar from "../../components/ScholarSideBar";
import CustomerSideBar from "../../components/CustomerSideBar";
import UserSideBar from "../../components/UserSideBar";

import { localize } from "@vee-validate/i18n";
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

const router = useRouter();
const userLogin = useLoginStore();
console.log("userLogin : ", userLogin);
let loading = ref(0);
const password = ref("");
const email = ref("");
const props = defineProps(["baseURL"]);
const allCountries = ref([]);
const country = ref();
const state = ref();
const allStates = ref([]);
const phoneNumber = ref("");
const countryForm = ref("");
const allCountriesForm = ref([]);
const showPassword = ref(false);

onMounted(() => {
  if (!userLogin.checkIsLoggedIn) {
    router.push({ name: "Signin" });
  }
  //getCountry();
});
</script>

<!-- <script>
export default {
  mounted() {
    if (!localStorage.getItem('token')) {
      this.$router.push({name : 'Signin'});
    }
  }
}
</script> -->

<style>
.adminPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.adminPanel button {
  font-size: 30px;
  width: fit-content;
  margin: 55px 0;
}
</style>
