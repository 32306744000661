<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exitModal"
    tabindex="-1"
    aria-labelledby="exitModal"
    aria-hidden="true"
  >
    <div class="modal-dialog origin-pop">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h4 class="modal-title mb-3" id="exampleModalLabel">Wait Before you leave!</h4>
        <p class="mb-3">You're going to love our products! We're so sure, we want to give you a 20% discount on any product! No strings attached.</p>
        <h4 class="mb-4" style="color:#f8a95c;">Use coupon code <span style="text-decoration:underline;">20OFF</span> to get 20% off</h4>
        <div class="exit-form">
            <p>Health tips & exclusive offers delivered to your inbox. Plus, 20% off your first order.</p>
            <Form novalidate @submit="exitModal">
              <!-- <input type="text" class="form-control" placeholder="Full Name"> -->
              <Field
                    rules="required"
                    type="text"
                    name="full_name"
                    class="form-control"
                    v-model="full_name"
                    placeholder="Full Name"
                  />
                  <ErrorMessage name="full_name" />
              <Field
                    rules="required|email"
                    type="text"
                    name="email"
                    class="form-control"
                    v-model="email"
                    placeholder="Email"
                  />
                   <ErrorMessage name="email" />
            <!-- <input type="text" class="form-control" placeholder="Email"> -->
            <button type="submit" class="btn btn-primary">Unlock 20% Off</button>
            </Form>
            
            <p class="small-text">By completing this form you are signing up to receive our emails and can unsubscribe at any time.</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../stores/login";
import { useCartStore } from "../stores/cart";
// Validation
import { Form, Field, ErrorMessage, defineRule, configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import * as AllRules from "@vee-validate/rules";
Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

const props = defineProps(["translationText", "baseURL"]);
const cartData = useCartStore();
//const cartItemData = ref([])
const router = useRouter();
const userLogin = useLoginStore();
const quantity = ref(1);
const coupon_code = ref();
const isCoupnApply = ref(false);
const full_name = ref();
const email = ref();

const navigate = () => {
  $("#cartModal").modal("hide");
  router.push({ name: "Checkout" });
};
const navigateProduct = () => {
  $("#cartModal").modal("hide");
  router.push({ name: "Product" });
};

const exitModal = () => {
  
    const payloadData = ref({
      name:full_name.value,
      email:email.value,
    });

   const apiUrl = props.baseURL + "save/exitpopup";
   // const apiUrl = "https://cymcorp.us/management/api/v1/save/exitpopup"
    axios
      .post(apiUrl, payloadData.value)
      .then((response) => {
        
        if (response.data.message) {
          swal({
            position: "top-end",
            icon: "success",
            title: response.data.message,
            button: false,
            timer: 2500,
          });
          window.$("#exitModal").modal("hide");
          //  router.push({
          //   name: "Checkout",
          //   //params: { sluguser: userLogin.getUserUrlName },
          // });
          
        } else {
          swal({
            position: "top-end",
            icon: "error",
            title: response.data.error,
            button: false,
            timer: 2500,
          });
          
        }

       
      })
      .catch((error) => {
        swal({
            position: "top-end",
            icon: "error",
            title: "There is an error. Please try again.",
            button: false,
            timer: 2500,
          });
        console.error("Error fetching data:", error);
      });

  
};
const removeApplyCode = () => {
  cartData.resetApplyCoupon();
  coupon_code.value = "";
  isCoupnApply.value = false;
};
const decreaseQty = () => {
  if (quantity.value > 1) {
    quantity.value--;
  }
};

const increaseQty = () => {
  quantity.value++;
};
const cartItem = {
  id: 1,
};
const openCartModal = () => {
  cartData.addToCart(cartItem);
  $("#cartModal").modal("show");
};
onMounted(() => {
  console.log("cartItemData on mounted ");
});
</script>
