<template>
  <section class="products-related-tabs complex-sec2 pt-0">
    <div class="container-fluid">
      <div class="row" data-aos="fade-up">
        <div class="col-md-6 mb-4 mb-md-0">
          <img src="/assets/images/blog-img2.jpg" alt="" />
        </div>
        <div class="col-md-6">
          <h2>{{ props.translationText.text.gshComplex }}</h2>
          <h3 class="mb-md-4 mb-3">
            {{ props.translationText.text.gshComplexSubtext }}
          </h3>
          <p>
            {{ props.translationText.text.gshComplexDescription }}
          </p>
        </div>
      </div>
      <div class="row icon-sec text-center" data-aos="fade-up">
        <div class="col">
          <figure>
            <img src="/assets/images/complex-icon1.png" alt="" />
          </figure>
          <p>{{ props.translationText.text.boost1 }}</p>
        </div>
        <div class="col">
          <figure>
            <img src="/assets/images/complex-icon2.png" alt="" />
          </figure>
          <p>{{ props.translationText.text.boost2 }}</p>
        </div>
        <div class="col">
          <figure>
            <img src="/assets/images/complex-icon3.png" alt="" />
          </figure>
          <p>{{ props.translationText.text.boost3 }}</p>
        </div>
        <div class="col">
          <figure>
            <img src="/assets/images/complex-icon4.png" alt="" />
          </figure>
          <p>{{ props.translationText.text.boost4 }}</p>
        </div>
        <div class="col">
          <figure>
            <img src="/assets/images/complex-icon5.png" alt="" />
          </figure>
          <p>{{ props.translationText.text.boost5 }}</p>
        </div>
      </div>
    </div>
  </section>

  <section class="complex-product pt-0" data-aos="fade-up">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="title text-center">
            <h2>{{ props.translationText.text.gshComplexBehind }}</h2>
          </div>
          <div class="complex-img">
            <img src="/assets/images/image-2complex.jpg" alt="" class="w-100" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="complex-product p-0" data-aos="fade-up">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3 mb-4 mb-md-0">
          <img src="/assets/images/immunity01-complex.jpg" alt="" />
        </div>
        <div class="col-md-9">
          <h3 class="mb-md-4 mb-3">
            {{ props.translationText.text.gshComplexGroundBreaking }}
          </h3>
          <p>{{ props.translationText.text.gshComplexGroundBreakingText1 }}</p>
          <p>
            {{ props.translationText.text.gshComplexGroundBreakingText2 }}
            <a href="https://pubmed.ncbi.nlm.nih.gov/"
              >(https://pubmed.ncbi.nlm.nih.gov/)</a
            >
          </p>
          <p>{{ props.translationText.text.gshComplexGroundBreakingText3 }}</p>
          <h4>Dr. Robert Bender, MD.,FAAFP</h4>
        </div>
        <div class="col-12 mt-md-5 mt-3">
          <h4>
            {{ props.translationText.text.gshComplexGroundBreakingText4 }}
          </h4>
          <h4>
            {{ props.translationText.text.gshComplexGroundBreakingText5 }}
          </h4>
          <h4>
            {{ props.translationText.text.gshComplexGroundBreakingText6 }}
          </h4>
        </div>
      </div>
      <div class="border"></div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useLoginStore } from "../../stores/login";
import { useCartStore } from "../../stores/cart";
import { userLanguage } from "../../stores/language";
const userLanguages = userLanguage();
const cartData = useCartStore();
const route = useRoute();
let props = defineProps(["translationText"]);
</script>
