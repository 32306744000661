<template>
  <section class="team-body">
    <div class="container-fluid">
      <div class="row mb-5" data-aos="fade-up">
        <div class="col-md-12">
          <ul class="breadcrumb">
            <li><a href="#">Home</a></li>
            <li>></li>
            <li>{{ translationText.text.enrollPage }}</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 mb-3" data-aos="fade-up">
          <div class="team-box">
            <div class="team-image">
              <img
                src="/assets/images/team-image1.jpg"
                alt=""
                class="img-responsive"
              />
            </div>
            <div class="team-dtls">
              <router-link
                :to="{
                  name: 'SignupAmbassador',
                  params: { sluguser: userLogin.getUserUrlName },
                }"
                class="btn btn-dark team-btn"
                >{{ translationText.text.asAmbassador }} Ambassador</router-link
              >
              <!-- <a href="" class="btn btn-dark team-btn">Enroll as an Ambassador</a> -->
              <div v-html="translationText.text.asAmbassadortext"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-3" data-aos="fade-up">
          <div class="team-box">
            <div class="team-image">
              <img
                src="/assets/images/team-image2.jpg"
                alt=""
                class="img-responsive"
              />
            </div>
            <div class="team-dtls">
              <router-link
                :to="{
                  name: 'SignupScholar',
                  params: { sluguser: userLogin.getUserUrlName },
                }"
                class="btn btn-dark team-btn"
                >{{ translationText.text.asScholar }}</router-link
              >
              <div v-html="translationText.text.asScholartext"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-4" data-aos="fade-up">
          <div class="team-box">
            <div class="team-image">
              <img
                src="/assets/images/team-image3.jpg"
                alt=""
                class="img-responsive"
              />
            </div>
            <div class="team-dtls">
              <router-link
                :to="{
                  name: 'Product',
                  params: { sluguser: userLogin.getUserUrlName },
                }"
                class="btn btn-dark team-btn"
                >{{ translationText.text.asClient }}</router-link
              >

              <div v-html="translationText.text.asClienttext"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <ProductListQuiz></ProductListQuiz>
</template>

<script setup>
import { ref, computed, defineProps, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { Form, Field, ErrorMessage } from "vee-validate";
import { mapState } from "pinia";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";
import ProductListQuiz from "../../components/Product/ProductListQuiz";
import { userLanguage } from "../../stores/language";

const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
const router = useRouter();
const userLogin = useLoginStore();
let loading = ref(0);
const password = ref("");
const email = ref("");
const props = defineProps(["baseURL"]);
console.log("prpos ", props.baseURL);
const validateEmail = (value) => {
  // if the field is empty
  if (!value) {
    return "This field is required";
  }
  // if the field is not a valid email
  // const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  // if (!regex.test(value)) {
  //   return 'This field must be a valid email';
  // }
  // All is good
  return true;
};

const validatePassword = (value) => {
  // if the field is empty
  if (!value) {
    return "This field is required";
  }

  // All is good
  return true;
};

const signin = async () => {
  //console.log(values, null, 2);
  //e.preventDefault();
  loading = true;

  const user = {
    username: email.value,
    password: password.value,
  };

  await axios
    .post(`${props.baseURL}auth/login`, user)
    .then((res) => {
      console.log("response ", res.data.token);
      userLogin.userLoggedIn(res.data.token);
      //localStorage.setItem("token", res.data.token);
      //this.$emit("fetchData");
      router.push({ name: "Product" });
    })
    .catch((err) => {
      swal({
        text: "Unable to Log you in!",
        icon: "error",
        closeOnClickOutside: false,
      });
      console.log(err);
    })
    .finally(() => {
      loading = false;
    });
};

const translationText = ref({
  text: {
    enrollPage: "Enroll Now Page",
    asAmbassador: "Enroll as an ",
    asAmbassadortext:
      "<p><b>Ambassador</b> or Affiliate / Influencer as they are better known! You are part of CYMCORP's sharing phase of the program.</p><p>As an Ambassador, when you share your experience of CYMCORP’s life-changing products with others, you’ll earn 20% on their purchases (your “Level 1”); and 10% on the purchases of people they refer (your “Level 2”).</p><p>NOTE: You are not required to be a CYMCORP customer to become an Ambassador but having a personal experience to share, being “a product of the product”, always makes for a better story.</p><p>All purchases of products are placed directly with CYMCORP through the link of a referring Ambassador (the “Seller”),thereby tracking that transaction for the benefit of the Seller’s commission. Even on recurring purchases.</p>",
    asScholar: "Enroll as a Scholar",
    asScholartext:
      "<p><b>Scholar</b> or Distributors as they are better known in the industry are part of CYMCORP's growing phase of the program.</p><h3>Building a Scholar Team</h3><p>As an active CYMCORP Scholar (or higher rank):</p><ul><li>You can sponsor other Scholars and earn overrides on their sales.</li><li>Although CYMCORP provides online training for new Scholars,it’s your job as their Sponsor to support, assist and encourage the Scholars on your team.</li><li>Your “team” consists of the Ambassadors and Scholars you personally sponsor (your “Level 1s”), those they sponsor (your “Level 2s”) and so on.</li></ul>",
    asClient: "Shop as a Client",
    asClienttext:
      '<p><b>Client</b> or Customer as they are better known! They are part of CYMCORP\'s growing community of people who are benefiting from the many properties of our products.</p><p>CYMCORP prides itself in having scientifically proven products that answers the needs of our clientele.</p><p>Viprox® is our nontoxic, non-invasive therapy to re-balance your whole organism and take care of you holistically. It is clinically proven to work at 98.9% efficacy.</p><p>GSH Complex® is backed by over 160,000 medical articles you can review on PubMed.<a href="https://pubmed.ncbi.nlm.nih.gov/">(pubmed.ncbi.nlm.nih.gov/)</a></p><p>But as they say the proof is in the pudding. Hear the Success Stories from people like you who have tried our line of products.<a href="https://admin.truequalityoflife.com/Success-Stories/">(https://admin.truequalityoflife.com/Success-Stories/)</a></p><p>Try it and if you are not satisfied, we will refund your money.You have nothing to lose.</p>',
  },
  language: "",
});


const translationTextEn = ref({
  text: {
    enrollPage: "Enroll Now Page",
    asAmbassador: "Enroll as an ",
    asAmbassadortext:
      "<p><b>Ambassador</b> or Affiliate / Influencer as they are better known! You are part of CYMCORP's sharing phase of the program.</p><p>As an Ambassador, when you share your experience of CYMCORP’s life-changing products with others, you’ll earn 20% on their purchases (your “Level 1”); and 10% on the purchases of people they refer (your “Level 2”).</p><p>NOTE: You are not required to be a CYMCORP customer to become an Ambassador but having a personal experience to share, being “a product of the product”, always makes for a better story.</p><p>All purchases of products are placed directly with CYMCORP through the link of a referring Ambassador (the “Seller”),thereby tracking that transaction for the benefit of the Seller’s commission. Even on recurring purchases.</p>",
    asScholar: "Enroll as a Scholar",
    asScholartext:
      "<p><b>Scholar</b> or Distributors as they are better known in the industry are part of CYMCORP's growing phase of the program.</p><h3>Building a Scholar Team</h3><p>As an active CYMCORP Scholar (or higher rank):</p><ul><li>You can sponsor other Scholars and earn overrides on their sales.</li><li>Although CYMCORP provides online training for new Scholars,it’s your job as their Sponsor to support, assist and encourage the Scholars on your team.</li><li>Your “team” consists of the Ambassadors and Scholars you personally sponsor (your “Level 1s”), those they sponsor (your “Level 2s”) and so on.</li></ul>",
    asClient: "Shop as a Client",
    asClienttext:
      '<p><b>Client</b> or Customer as they are better known! They are part of CYMCORP\'s growing community of people who are benefiting from the many properties of our products.</p><p>CYMCORP prides itself in having scientifically proven products that answers the needs of our clientele.</p><p>Viprox® is our nontoxic, non-invasive therapy to re-balance your whole organism and take care of you holistically. It is clinically proven to work at 98.9% efficacy.</p><p>GSH Complex® is backed by over 160,000 medical articles you can review on PubMed.<a href="https://pubmed.ncbi.nlm.nih.gov/">(pubmed.ncbi.nlm.nih.gov/)</a></p><p>But as they say the proof is in the pudding. Hear the Success Stories from people like you who have tried our line of products.<a href="https://admin.truequalityoflife.com/Success-Stories/">(https://admin.truequalityoflife.com/Success-Stories/)</a></p><p>Try it and if you are not satisfied, we will refund your money.You have nothing to lose.</p>',
  },
  language: "",
});

const translatText = () => {
  translationText.value.language = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  if(userLanguages.getLanguageCode != 'en'){
    const apiUrl = props.baseURL + "translateany";
  axios
    .post(apiUrl, translationText.value)
    .then((response) => {
      console.log("response trnasate :", response.data);
      translationText.value = response.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }
  else{
    translationText.value = translationTextEn.value;
  }
  
};
watch(languageCode, () => {
  console.log("isLoggedIn ref changed, do something!");
  translatText();
});
onMounted(() => {
  translatText();
});
</script>
