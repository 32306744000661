<template>
  <div class="col-md-9 col-sm-8 col-xs-12">
    <ul class="breadcrumb mb-4" data-aos="fade-up">
      <li><a href="#">Home</a></li>
      <li>></li>
      <li>Compose</li>
    </ul>
    <div class="single-deta-box compose-mail" data-aos="fade-up">
      <div class="row">
        <div class="col-md-12">
          <div class="">
            <Form novalidate @submit="sendMessage" class="pl-4 pr-4">
              <!-- Name input -->
              <div data-mdb-input-init class="form-outline mb-4">
                <label class="form-label" for="form4Example1">Customer</label>

                <v-select
                  required
                  v-model="userId"
                  :options="books"
                  :reduce="(option) => option.id"
                  multiple
                  label="title"
                ></v-select>
                <!-- <span>{{ errorMessage }}</span> -->
              </div>

              <div data-mdb-input-init class="form-outline mb-4">
                <label class="form-label" for="form4Example1">Ambassador</label>

                <v-select
                  required
                  v-model="ambassadorId"
                  :options="ambassadors"
                  :reduce="(option) => option.id"
                  multiple
                  label="title"
                ></v-select>
                <!-- <span>{{ errorMessage }}</span> -->
              </div>

              <div data-mdb-input-init class="form-outline mb-4">
                <label class="form-label" for="form4Example1">Scholar</label>

                <v-select
                  required
                  v-model="scholarId"
                  :options="scholars"
                  :reduce="(option) => option.id"
                  multiple
                  label="title"
                ></v-select>
                <!-- <span>{{ errorMessage }}</span> -->
              </div>

              <!-- Email input -->
              <div data-mdb-input-init class="form-outline mb-4">
                <label class="form-label" for="form4Example2">Subject</label>
                <Field
                  v-model="subject"
                  type="text"
                  name="subject"
                  class="form-control"
                  rules="required"
                  placeholder="Subject"
                />
                <ErrorMessage name="subject" />
              </div>

              <!-- Message input -->
              <div data-mdb-input-init class="form-outline mb-4">
                <label class="form-label" for="form4Example3">Message</label>
                <!-- <ckeditor v-model="message"></ckeditor> -->
                <ckeditor
                  :editor="editor"
                  v-model="message"
                  :config="editorConfig"
                ></ckeditor>
                <!-- <Field
                  v-model="message"
                  type="text"
                  name="message"
                  class="form-control"
                  rules="required"
                  placeholder="message"
                />
                <ErrorMessage name="message" /> -->
              </div>

              <!-- Submit button -->
              <button
                data-mdb-ripple-init
                type="submit"
                class="btn btn-primary btn-block mb-4"
              >
                Send
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineProps, onUpdated, onMounted } from "vue";
import { userLanguage } from "../../stores/language";
import { useLoginStore } from "../../stores/login";
import { useRouter } from "vue-router";
import VueTree from "@ssthouse/vue3-tree-chart";
import "@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css";
import vSelect from "vue-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Form,
  Field,
  ErrorMessage,
  defineRule,
  configure,
  useField,
} from "vee-validate";
const props = defineProps(["baseURL"]);
const userId = ref([]);
const ambassadorId = ref([]);
const scholarId = ref([]);
const message = ref("");
const subject = ref("");
const editor = ClassicEditor;

const editorConfig = {
  height: "500px",
  // The configuration of the editor.
};
// const { userId, errorMessage } = useField(
//   "userId",
//   (inputValue) => !!inputValue
// );
const books = ref([]);
const ambassadors = ref([]);
const scholars = ref([]);

const myOptions = ref([]);
// or [{id: key, text: value}, {id: key, text: value}]
const router = useRouter();
const userLanguages = userLanguage();
const userLogin = useLoginStore();

const messages = ref([]);

const openOrders = ref([]);
const cancelOrders = ref([]);
const autoShipOrders = ref([]);
const refundOrders = ref([]);

const treeConfig = ref({ nodeWidth: 120, nodeHeight: 80, levelHeight: 200 });
const headers = {
  Authorization: "Bearer " + userLogin.loggedToken,
};

const sendMessage = () => {
  console.log("send message", userId.value);
  if (
    userId.value.length == 0 &&
    ambassadorId.value.length == 0 &&
    scholarId.value.length == 0
  ) {
    swal({
      position: "top-end",
      icon: "error",
      title: "Please select",
      button: false,
      timer: 2500,
    });
  } else if (message.value == "") {
    swal({
      position: "top-end",
      icon: "error",
      title: "Please enter message",
      button: false,
      timer: 2500,
    });
  } else {
    let recipientIds = [];
    console.log("userId.value  ", userId.value);
    if (userId.value.length > 0) {
      if (userId.value.includes("All")) {
        books.value.forEach((element) => {
          if (element.id != "All") {
            recipientIds.push(element.id);
          }
        });
      } else {
        userId.value.forEach((element) => {
          recipientIds.push(element);
        });
      }
    }

    if (ambassadorId.value.length > 0) {
      if (ambassadorId.value.includes("All")) {
        ambassadors.value.forEach((element) => {
          if (element.id != "All") {
            recipientIds.push(element.id);
          }
        });
      } else {
        ambassadorId.value.forEach((element) => {
          recipientIds.push(element);
        });
      }
    }

    if (scholarId.value.length > 0) {
      if (scholarId.value.includes("All")) {
        scholars.value.forEach((element) => {
          if (element.id != "All") {
            recipientIds.push(element.id);
          }
        });
      } else {
        scholarId.value.forEach((element) => {
          recipientIds.push(element);
        });
      }
    }

    console.log("recipientIds ", recipientIds);

    const payLoad = {
      subject: subject.value,
      body: message.value,
      recipient_ids: recipientIds,
    };

    userLanguages.setLoading(true);

    axios
      .post(props.baseURL + "user/messages", payLoad, {
        headers,
      })
      .then((response) => {
        console.log("Sent Message ", response.data);
        swal({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          button: false,
          timer: 2500,
        });
        userLanguages.setLoading(false);
        router.push({ name: "MessageList" });
      })
      .catch((error) => {
        userLanguages.setLoading(false);
        console.error("Error fetching data:", error);
      });
  }
};

const getGenealogy = () => {
  //const apiUrl = props.baseURL + "translateany";
  axios
    .get(props.baseURL + "team-users/scholar", {
      headers,
    })
    .then((response) => {
      //console.log("My Team Order Listing ", response.data.my_scholar);
      if (response.data.my_scholar.length > 0) {
        let optinCreate = [];
        scholars.value.push({ id: "All", title: "Select All" });
        response.data.my_scholar.forEach((element) => {
          scholars.value.push({ id: element.id, title: element.name });
          // = [{ id: element.id, title: "Old Man's War" }];

          //console.log("element element", element);
          //   optinCreate.push({
          //     id: element.id,
          //     title: element.name,
          //     label: element.name,
          //   });
        });
      }
      //messages.value = response.data.messages;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });

  ////////////
  axios
    .get(props.baseURL + "team-users/ambassador", {
      headers,
    })
    .then((response) => {
      //console.log("My Team Order Listing ", response.data.my_scholar);
      if (response.data.my_ambassador.length > 0) {
        let optinCreate = [];
        ambassadors.value.push({ id: "All", title: "Select All" });
        response.data.my_ambassador.forEach((element) => {
          ambassadors.value.push({ id: element.id, title: element.name });
          // = [{ id: element.id, title: "Old Man's War" }];

          //console.log("element element", element);
          //   optinCreate.push({
          //     id: element.id,
          //     title: element.name,
          //     label: element.name,
          //   });
        });
      }
      //messages.value = response.data.messages;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  ///////////////////////

  ////////////
  axios
    .get(props.baseURL + "team-users/customer", {
      headers,
    })
    .then((response) => {
      //console.log("My Team Order Listing ", response.data.my_scholar);
      if (response.data.my_customer.length > 0) {
        let optinCreate = [];
        books.value.push({ id: "All", title: "Select All" });
        response.data.my_customer.forEach((element) => {
          books.value.push({ id: element.id, title: element.name });
          // = [{ id: element.id, title: "Old Man's War" }];

          //console.log("element element", element);
          //   optinCreate.push({
          //     id: element.id,
          //     title: element.name,
          //     label: element.name,
          //   });
        });
      }
      //messages.value = response.data.messages;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  ///////////////////////

  console.log("books.value", books.value);
};
const myChangeEvent = (val) => {
  console.log("myChangeEvent", val);
};
const mySelectEvent = (id, text) => {
  console.log("myChangeEvent", { id, text });
};

onMounted(() => {
  getGenealogy();
});
</script>
<style scoped>
.select2-container {
  width: 200px !important;
}
.ck-editor__editable {
  min-height: 500px;
}
</style>
