<template>
  <section
    class="guiding-sec bg-img"
    style="background-image: url('/assets/images/guiding-bg.jpg')"
  >
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center" data-aos="fade-up">
          <div class="title wh-text">
            <h2 v-html="helpYou.name"></h2>
            <p v-html="helpYou.page_content"></p>
          </div>
        </div>
        <div class="col-lg-10" data-aos="fade-up">
          <div class="improve-box">
            <!-- <div class="text-center">
                        <h3>What would you like to improve about your health?</h3>
                        <h5>Check all that applies</h5>
                    </div> -->
            <iframe
              id="JotFormIFrame-210483531004038"
              style="
                min-width: 100%;
                border: none;
                max-height: 100%;
                height: 640px;
              "
              title="Cymcorp Website Form "
              src="https://form.jotform.com/210483531004038?isIframeEmbed=1"
              frameborder="0"
              scrolling="no"
              allowfullscreen="allowfullscreen"
              ><br
            /></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import {
  defineComponent,
  ref,
  onMounted,
  defineProps,
  defineEmits,
  watch,
} from "vue";
import { storeToRefs } from "pinia";
import axios from "axios";
import { userLanguage } from "../../stores/language";

const emit = defineEmits(["updateCount"]);
const userLanguages = userLanguage();
const { languageCode } = storeToRefs(userLanguages);
//const props = defineProps(["labelTextdata"]);
const helpYou = ref("");
const getQuizeText = async () => {
  const languageUrl = userLanguages.getLanguageCode
    ? "/" + userLanguages.getLanguageCode
    : "";
  const apiUrl =
    process.env.VUE_APP_APP_API_URL +
    "page/we-can-help-you-by-guiding-you-to-a-good-solid-health" +
    languageUrl;

  axios
    .get(apiUrl)
    .then((response) => {
      if (response.data.page_details.length > 0) {
        helpYou.value = response.data.page_details[0];
      } else {
        helpYou.value = "";
      }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
};
watch(languageCode, () => {
  console.log("isLoggedIn ref changed, do something!");
  getQuizeText();
});
onMounted(() => {
  getQuizeText();
});
</script>
